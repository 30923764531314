import React, { useEffect, useMemo, useState, useRef, useContext } from 'react';
import { toast } from 'react-toastify';
import AppTable from 'components/new_components/app-table';
import AppTag from 'components/new_components/AppTags';
//compliance standards
import { ReactComponent as CompletedIcon } from 'assets/img/audit/CompletedStatusIcon.svg';
import { ReactComponent as AuditingIcon } from 'assets/img/audit/Auditing.svg';
import { ReactComponent as DraftIcon } from 'assets/img/audit/Draft.svg';
import { ReactComponent as AttentionIcon } from 'assets/img/audit/Attention.svg';
import { ReactComponent as ReviewIcon } from 'assets/img/audit/Review.svg';
import AppLinearProgress from 'components/new_components/AppLinearProgress';
import { IoMdDownload } from 'react-icons/io';
import AuditDownloadModal from './modals/AuditDownloadModal';
import { Avatar, Box, Button, IconButton, Stack, useTheme } from '@mui/material';
import AppInput from 'components/new_components/AppInput';

// redux
import { connect, useDispatch, useSelector } from 'react-redux';
import { GetAllAuditRequests, CreateAuditRequest } from 'store/actions/auditActions';

// import LoadingState from 'components/new_components/LoadingState';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ViewCertificate from './AuditCertificate/CertificateView';
import moment from 'moment';
import { getDaysFromDates } from 'utils';
import { useHistory } from 'react-router-dom';
import AuditTableAction from './AuditTableAction';
import useSearch from 'hooks/useSearch';
import AppButton from 'components/new_components/AppButton';

//userguide
import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import { GetRegisteredClients } from 'store/actions/ImplementerAction';
import { GetAllAuditRequestsImplementer } from 'store/actions/auditActions';

const NO_PER_PAGE = 8;

const AuditTableRecord = (props) => {
    // props

    // hooks
    const theme = useTheme();
    const history = useHistory();

    // props
    const { GetAllAuditRequests, all_audit_requests, merchant_info, userguide_info, all_article_header } = props;

    // redux
    const dispatch = useDispatch();
    const user_info = useSelector((state) => state?.generalReducers?.user_info);
    const registered_clients = useSelector((state) => state?.implementerReducer?.registered_clients);
    const client_id = useSelector((state) => state?.implementerReducer?.client_id);

    // states
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [downloadModalOpen, setDownloadModalOpen] = useState(false);
    const [view, setView] = useState('table');
    const [data_, setData] = useState({});
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    //userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const steps = [
        {
            title: 'View Certificate',
            description: 'Add users to your account for an efficient compliance process.',
            target: () => ref1.current,
        },
        {
            title: 'Download',
            description: 'Download your compliance certificate once the audit process is done',
            target: () => ref2.current,
        },
    ];

    const merchant_standards = merchant_info?.standards?.split(',')?.map((standard) => {
        return {
            compliance: standard,
            status: standard === 'pcidss4.0' ? 'need_attention' : 'auditing',
        };
    });

    const mapped_audit_requests = new Map(all_audit_requests?.map(({ compliance, ...rest }) => [compliance, rest]));
    const merchant_audit_requests = useMemo(() => {
        return merchant_standards?.map((o) => ({
            ...o,
            ...mapped_audit_requests.get(o.compliance),
        }));
    }, [all_audit_requests]);

    const { data, handleSearch } = useSearch(merchant_audit_requests, ['compliance', 'status']);

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article.name === 'Audit'),
        [all_article_header]
    );

    const openDownloadModal = (data_ = {}) => {
        setDownloadModalOpen(true);
        setData(data_);
    };
    const closeDownloadModal = () => {
        setDownloadModalOpen(false);
    };
    const handlePageChange = (page) => {
        setPage(page);
    };
    const gotoPlanSettings = (standard) => {
        history.push(`/merchant/audits/${standard?.toLowerCase()}`);
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ audits: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, audits: false });
        setOpenArticle(false);
    };

    const getAllAuditRequests = async () => {
        setLoading(true);
        const res = await GetAllAuditRequests();
        setLoading(false);
        if (!res?.success) {
            toast.error("Something went wrong, couldn't get audits.");
        }
    };

    useEffect(() => {
        const getRegisteredClients = async () => {
            const res = await dispatch(GetRegisteredClients());
            if (!res?.status) {
                toast.error(res?.message);
            }
        };

        getRegisteredClients();
    }, []);
    // useEffect
    useEffect(() => {
        getAllAuditRequests();
    }, []);

    useEffect(() => {
        const getRegisteredClients = async () => {
            const res = await dispatch(GetRegisteredClients());
            if (!res?.status) {
                toast.error(res?.message);
            }
        };

        getRegisteredClients();
    }, []);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.audits ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.audits]);

    const columns = [
        {
            title: 'Compliance Standard',
            render: (row) => (
                <div className="flex items-center">
                    <div className="flex items-center" style={{ position: 'relative' }}>
                        <Avatar
                            sx={{
                                backgroundColor:
                                    row?.status === 'completed'
                                        ? '#06B87720'
                                        : row?.status === 'inreview'
                                        ? '#E2B20820'
                                        : row?.status === 'auditing'
                                        ? '#B1C5FF80'
                                        : row?.status === 'draft'
                                        ? '#9ea9bd30'
                                        : '#EB414120',
                                width: 50,
                                height: 50,
                            }}
                        >
                            {row?.status === 'completed' ? (
                                <CompletedIcon />
                            ) : row?.status === 'auditing' ? (
                                <AuditingIcon />
                            ) : row?.status === 'inreview' ? (
                                <ReviewIcon />
                            ) : row?.status === 'draft' ? (
                                <DraftIcon />
                            ) : (
                                <AttentionIcon />
                            )}
                        </Avatar>
                        {row?.status === 'need_attention' && (
                            <FiberManualRecordIcon
                                sx={{
                                    position: 'absolute',
                                    color: 'red',
                                    width: '10px',
                                    height: '10px',
                                    top: 0,
                                    right: 0,
                                }}
                            />
                        )}
                    </div>
                    <span className="pl-3 uppercase">{row.compliance}</span>
                </div>
            ),
        },
        {
            title: 'Status',
            render: (row) => (
                <AppTag
                    text={
                        row.status === 'completed'
                            ? 'Completed'
                            : row.status === 'inreview'
                            ? 'In Review'
                            : row.status === 'auditing'
                            ? 'Auditing...'
                            : row.status === 'need_attention'
                            ? 'Need Attention'
                            : 'Draft'
                    }
                    type={
                        row.status === 'completed'
                            ? 'success'
                            : row.status === 'inreview'
                            ? 'pending'
                            : row.status === 'auditing'
                            ? 'auditing'
                            : row.status === 'need_attention'
                            ? 'failed'
                            : 'draft'
                    }
                />
            ),
        },
        {
            title: 'Date Added',
            render: (row) => (
                <>
                    {row?.date_created ? (
                        <Stack>
                            <Box>{moment(row?.date_created).format('MMMM Do, YYYY')}</Box>
                            <Box>({moment(row?.date_created).format('hh:mm a')})</Box>
                        </Stack>
                    ) : (
                        <Box sx={{ width: '30%', ml: 1 }}>
                            <AppLinearProgress value={0} />
                        </Box>
                    )}
                </>
            ),
        },
        {
            title: 'TimeFrame',
            render: (row) => {
                const effectiveFrom = row?.effective_from?.split(',')?.filter((item) => item !== '');
                const modified_effectiveFrom = effectiveFrom?.map((el) => el?.trim().replace(/^,/, ''));

                const no_daysLeft = getDaysFromDates(
                    moment(new Date()).format('YYYY-MM-DD'),
                    modified_effectiveFrom && moment(modified_effectiveFrom[1]).format('YYYY-MM-DD')
                );

                const daysBetween = getDaysFromDates(
                    modified_effectiveFrom && modified_effectiveFrom[0],
                    modified_effectiveFrom && modified_effectiveFrom[1]
                );
                const percentage = Math.floor(((daysBetween - no_daysLeft) / daysBetween) * 100);
                return (
                    <>
                        {modified_effectiveFrom ? (
                            <Stack>
                                <Box>
                                    {moment(modified_effectiveFrom && modified_effectiveFrom[0]).format('MMM DD, YYYY')}{' '}
                                    -{''}{' '}
                                    {moment(modified_effectiveFrom && modified_effectiveFrom[1]).format('MMM DD, YYYY')}
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                    <Box sx={{ width: '20%', mr: 1 }}>
                                        <AppLinearProgress value={percentage} />
                                    </Box>
                                    {no_daysLeft} days left
                                </Box>
                            </Stack>
                        ) : (
                            <Box sx={{ width: '20%', ml: 1 }}>
                                <AppLinearProgress value={0} />
                            </Box>
                        )}
                    </>
                );
            },
        },
        {
            title: '',
            align: 'center',
            key: 'action',
            render: (row) => {
                const uploadedDocs = Array.isArray(row?.uploaded_docs) ? row?.uploaded_docs : [];
                return (
                    <div className="flex items-center">
                        {row?.status === 'need_attention' ? (
                            <Button
                                variant={'outlined'}
                                onClick={() => gotoPlanSettings(row?.compliance)}
                                sx={{
                                    textTransform: 'none',
                                    color: '#303034',
                                    fontSize: '16px',
                                    border: '2px solid #1D438F',
                                }}
                                ref={ref1}
                            >
                                Generate Report
                            </Button>
                        ) : row?.status === 'draft' ? (
                            <Button
                                variant={'outlined'}
                                onClick={() => gotoPlanSettings(row?.compliance)}
                                sx={{
                                    textTransform: 'none',
                                    color: '#303034',
                                    fontSize: '16px',
                                    border: '2px solid #1D438F',
                                }}
                            >
                                Resume report generating
                            </Button>
                        ) : (row?.status === 'auditing' || row?.status === 'completed') &&
                          row?.compliance === 'pcidss4.0' &&
                          row?.auditor &&
                          row?.qa_reviewer ? (
                            <AuditTableAction record={row} openDownloadModal={openDownloadModal} />
                        ) : (
                            <IconButton
                                disabled={!uploadedDocs?.length}
                                onClick={() => openDownloadModal(row)}
                                ref={ref2}
                            >
                                <IoMdDownload
                                    size="25px"
                                    color={`${row.status === 'completed' ? '#0E2C66' : '#E4E2E6'}`}
                                />
                            </IconButton>
                        )}
                    </div>
                );
            },
        },
    ];
    const current_client = registered_clients?.find((client) => client?.id === client_id);
    return (
        <>
            {view === 'table' ? (
                <>
                    <AppTable
                        columns={columns}
                        data={data}
                        title={
                            <AppInput
                                placeholder="Search file..."
                                sx={{
                                    maxWidth: 200,
                                    borderColor: theme.palette.gray[30],
                                    '& input::placeholder': {
                                        color: theme.palette.gray[40],
                                        fontWeight: 600,
                                    },
                                }}
                                controlStyle={{ mt: 0, width: 'fit-content' }}
                            />
                        }
                        actions={[
                            (user_info?.user_type === 'implementer'
                                ? current_client?.generate_cert
                                : merchant_info?.generate_cert) && (
                                <AppButton
                                    name={'View Certificate'}
                                    color="primary"
                                    variant="contained"
                                    onClick={() => setView('certificate')}
                                    key={1}
                                />
                            ),
                        ]}
                        noPerPage={NO_PER_PAGE}
                        sorter={(a, b) => b?.id - a?.id}
                        dataLength={merchant_audit_requests?.length}
                        page={page}
                        loading={loading}
                        onPageChange={handlePageChange}
                        rowSpacing={true}
                        onSearch={handleSearch}
                        search={true}
                    />
                    <AuditDownloadModal open={downloadModalOpen} handleClose={closeDownloadModal} data={data_} />
                </>
            ) : (
                <ViewCertificate setView={setView} current_client={current_client} />
            )}
            <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Audits"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};
const mapStateToProps = (state) => ({
    all_audit_requests: state?.auditReducers?.all_audit_requests,
    merchant_info: state?.merchantReducers?.merchant_info,
    userguide_info: state?.guideReducers?.userguide_info?.[0]?.audits,
    all_article_header: state?.articlesReducers?.all_article_header,
});
export default connect(mapStateToProps, { GetAllAuditRequests, GetAllAuditRequestsImplementer, CreateAuditRequest })(
    AuditTableRecord
);
