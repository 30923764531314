import { Box, Typography } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppForm, AppFormInput, AppFormSelect, AppSubmitButton } from 'components/new_components/forms';
import { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { AssignAutomatedScan, CreateScanException } from 'store/actions/automatedScansActions';
import { assignmentModalValidation } from '../../utils/validation';

const AssignTaskModal = (props) => {
    const {
        open,
        onClose,
        category,
        type,
        AssignAutomatedScan,
        sub_category,
        scans,
        all_employees,
        organization,
        all_assigned_task,
        user_id,
        modalTitle,
    } = props;

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (values, action) => {
        const category_payload = {
            assigned_to:
                organization?.merchant_plan?.name === 'Start Up'
                    ? [JSON.parse(values?.assigned_to)]
                    : values?.assigned_to?.map((assignee) => JSON.parse(assignee)),
            comments: values.comment,
            tag: 'automated_scan',
            category: category?.id,
            mark_as_completed: false,
        };
        const sub_category_payload = {
            assigned_to:
                organization?.merchant_plan?.name === 'Start Up'
                    ? [JSON.parse(values?.assigned_to)]
                    : values?.assigned_to?.map((assignee) => JSON.parse(assignee)),
            comments: values.comment,
            tag: 'automated_scan',
            subcategory: sub_category?.id,
            mark_as_completed: false,
        };
        const scan_payload = {
            assigned_to:
                organization?.merchant_plan?.name === 'Start Up'
                    ? [JSON.parse(values?.assigned_to)]
                    : values?.assigned_to?.map((assignee) => JSON.parse(assignee)),
            comments: values.comment,
            tag: 'automated_scan',
            scans: scans?.id,
            mark_as_completed: false,
        };
        // const body = category ? category_payload : sub_category ? sub_category_payload : scan_payload;
        const body =
            type === 'Scan constraint'
                ? category_payload
                : type === 'Scan Sub-constraint'
                ? sub_category_payload
                : scan_payload;
        setLoading(true);
        const res = await AssignAutomatedScan(body);
        setLoading(false);
        if (res?.success) {
            action.resetForm();
            toast.success(res.message);
            onClose();
        } else {
            if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                toast.error(res?.message);
            }
        }
    };

    const modified_users = all_employees
        ?.map((user) => {
            return {
                name: user?.user_detail?.email,
                value: user?.id,
            };
        })
        .filter((user) => user?.value !== user_id);

    const find_category_task = all_assigned_task?.find((task) => task?.category === category?.id);
    const find_sub_category_task = all_assigned_task?.find((task) => task?.subcategory === sub_category?.id);
    const find_scans = all_assigned_task?.find((task) => task?.scans === scans?.id);

    const findCategoryUsers = useMemo(() => {
        return modified_users?.filter((user) => {
            return !find_category_task?.assigned_to?.includes(user.value);
        });
    }, [modified_users, find_category_task]);

    const findSubCategoryUsers = useMemo(() => {
        const concat_assigned_to = find_category_task?.assigned_to.concat(find_sub_category_task?.assigned_to || []);
        return modified_users?.filter((user) => {
            return !concat_assigned_to?.includes(user.value);
        });
    }, [modified_users, find_sub_category_task, find_category_task]);

    const findScansUsers = useMemo(() => {
        const concat_assigned_to = find_category_task?.assigned_to.concat(
            find_sub_category_task?.assigned_to || [],
            find_scans?.assigned_to || []
        );
        return modified_users?.filter((user) => {
            return !concat_assigned_to?.includes(user.value);
        });
    }, [modified_users, find_scans, find_sub_category_task, find_category_task]);

    const filtered_users =
        type === 'Scan constraint'
            ? findCategoryUsers
            : type === 'Scan Sub-constraint'
            ? findSubCategoryUsers
            : findScansUsers;

    return (
        <AppForm
            initialValues={{
                assigned_to: '',
                comment: '',
            }}
            validate={assignmentModalValidation}
            onSubmit={handleSubmit}
        >
            <AppCenteredModal
                open={open}
                handleClose={onClose}
                title={
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#202D66' }}>
                        {`Assign ${modalTitle ?? type}`}
                    </Typography>
                }
                headerAction={
                    <button
                        className="border-[#E2E8F0] border rounded-full p-1 h-6 w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                        onClick={onClose}
                    >
                        <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                    </button>
                }
                titleStyle={{ marginTop: '6px' }}
                width={540}
                noBorder
            >
                <Box>
                    <AppFormSelect
                        label={'User'}
                        multiple={organization?.merchant_plan?.name === 'Start Up' ? false : true}
                        name="assigned_to"
                        options={filtered_users}
                        placeholder={'Select User'}
                        loading={loading.content}
                        noDataLabel="No employees at the moment"
                    />
                    <AppFormInput type="text" name="comment" placeholder="Comment" multiline rows={2} />

                    <div className="px-3 py-2 rounded border border-[#F0F0FA] bg-[#F8FAFC] flex gap-2 mt-4 mb-6">
                        <img src="/img/automated-scan/bulb-icon.svg" alt="Bulb icon" className="object-contain" />

                        <Typography sx={{ fontSize: '13px', color: '#64748B', lineHeight: '19.5px' }}>
                            You can view tasks you assigned to users on the activity page of the smartcomply
                            application.
                        </Typography>
                    </div>

                    <div className="bg-[#F8FAFC] flex items-center -mx-7 -mb-[1.6px] justify-end gap-2.5 p-6">
                        <button
                            onClick={onClose}
                            className="text-sm px-3 font-semibold py-[10px] rounded border border-[#E2E8F0] bg-white hover:brightness-90 transition-all"
                        >
                            Cancel
                        </button>

                        <AppSubmitButton
                            text={'Assign'}
                            variant="contained"
                            color="primary"
                            loading={loading}
                            loadingPosition="center"
                        />
                    </div>
                </Box>
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        all_automated_scan_groups: state?.automatedScansReducers?.all_automated_scan_group,
        all_users: state?.merchantReducers?.all_merchant_users,
        all_employees: state?.generalReducers?.all_employees,
        user_id: state?.generalReducers?.user_info?.employee?.id,
        all_assigned_task: state?.automatedScansReducers?.all_assigned_task,
        organization: state?.generalReducers?.user_info?.organization,
    };
};

export default connect(mapStateToProps, {
    CreateScanException,
    AssignAutomatedScan,
})(AssignTaskModal);
