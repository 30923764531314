import React, { useState, useEffect, useContext, useMemo } from 'react';
//components
import PageHeader from 'components/new_components/PageHeader';
import TabLayout from 'components/new_components/tabs/TabLayout';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import NeededDoc from './NeededDoc';
import NewDoc from './NewDoc';

//userguide
// import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import { connect } from 'react-redux';

//translations
import { useTranslation } from 'react-i18next';

const index = (props) => {
    const { all_article_header } = props;
    // state
    const [currentTab, setCurrentTab] = useState(0);
    // const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    //translation
    const { t } = useTranslation('dataroom');

    const {
        // updateOneUserGuide,
        toggleUserGuide,
        setToggleUserGuide,
    } = useContext(UserGuideContext);

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Data Room'),
        [all_article_header]
    );

    //UserGuide
    // const OpenUserGuide = () => {
    //     setOpenUserGuide(true);
    // };
    // const CloseUserGuide = () => {
    //     updateOneUserGuide({ dataroom: true });
    //     setOpenUserGuide(false);
    // };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, dataroom: false });
        setOpenArticle(false);
    };

    // logic function
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };

    // constants
    const TABS = [
        { name: t('additionalDocument'), id: 0 },
        { name: t('clientPortal'), id: 1 },
    ];

    useEffect(() => {
        // !userguide_info ? OpenUserGuide() :
        toggleUserGuide.dataroom ? OpenArticle() : null;
    }, [toggleUserGuide.dataroom]);

    return (
        <>
            <PageHeader browserTitle="Data Room | Smartcomply" />
            <TabLayout onTabChange={handleTabChange} tab={currentTab} tabs={TABS}>
                <AppTabPanel value={currentTab} index={0}>
                    <NeededDoc currentTab={currentTab} />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={1}>
                    <NewDoc currentTab={currentTab} />
                </AppTabPanel>
            </TabLayout>
            {/* <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} targets={steps.target} /> */}
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Data Room"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};

export default connect(mapStateToProps)(index);
