import React, { useEffect, useMemo } from 'react';
import { useTheme } from '@mui/material/styles';
import { Box, Button, Stack, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import PlanCard from './components/PlanCard';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GetAllPlans } from 'store/actions/subscriptionActions';
import { useDispatch, useSelector } from 'react-redux';
import LoadingState from 'components/new_components/LoadingState';
import { toast } from 'react-toastify';
import SubscribeModal from 'views/merchant/UpsellPaywall/SubscribeModal';
import YouImage from 'assets/img/You.svg';
import YouVector from 'assets/img/YouVector.svg';

//translations
import { useTranslation } from 'react-i18next';
import AuditingFeatures from './components/AuditingFeatures';

const AppCenteredModal = () => {
    const [openCard, setOpenCard] = useState(false);
    const [subscribeModal, setSubscribeModal] = useState(false);

    const closeModal = () => {
        setSubscribeModal(false);
    };
    const [plan, setPlan] = useState('');
    const theme = useTheme();
    const history = useHistory();

    const handlePlanSelect = (plan) => {
        setPlan(plan);
        setSubscribeModal(true);
    };

    //translation
    const { t } = useTranslation('common');

    // state
    const [loading, setLoading] = useState(false);

    // redux state
    const dispatch = useDispatch();
    const allPlans = useSelector((state) => state?.subscriptionReducers?.all_plans);
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);

    const merchantActivePlan = useMemo(() => merchant_info?.plan, [merchant_info]);
    const pendingPlan = useMemo(() => merchant_info?.pending_plan_id, [merchant_info]);
    const hasPendingPlan = useMemo(() => merchant_info?.pending_plan);

    // async functions
    const getAllPlans = async () => {
        setLoading(true);
        const res = await dispatch(GetAllPlans());
        setLoading(false);
        if (!res.success) {
            toast.error("Something went wrong! We couldn't get plans.");
        }
    };

    // useEffect
    useEffect(() => {
        getAllPlans();
        // getCompanyDetails();
    }, []);

    const goBack = () => {
        history.goBack();
    };
    return (
        <Box>
            <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
                sx={{ backgroundColor: '#FFFFFF', py: 1, px: 2.5 }}
            >
                <Typography
                    sx={{
                        color: '#395BA9',
                        fontSize: '34px',
                        fontWeight: 700,
                    }}
                >
                    Subscribe to a plan
                </Typography>
                <Close sx={{ color: theme.palette.gray[500], cursor: 'pointer' }} onClick={goBack} />
            </Stack>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    my: '2rem',
                }}
            >
                <Typography
                    sx={{ color: '#011947', fontSize: '20px', fontWeight: 700, width: '50%', textAlign: 'center' }}
                >
                    Get the most of cyber security compliance with the perfect subscription
                </Typography>

                {loading ? (
                    <LoadingState />
                ) : (
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginTop: '7rem' }}
                    >
                        {allPlans
                            ?.sort((a, b) => new Date(a?.date_created) - new Date(b?.date_created))
                            ?.filter((pln) => pln?.name !== 'val')
                            ?.map((plan, index) => (
                                <Box key={index} sx={{ position: 'relative' }}>
                                    {merchantActivePlan === plan?.id && (
                                        <Box sx={{ position: 'absolute', top: '-18%', left: '10%' }}>
                                            <img src={YouImage} alt="You" />
                                            <img src={YouVector} alt="You" className="ml-[2rem]" />
                                        </Box>
                                    )}
                                    <PlanCard
                                        name={plan?.name}
                                        description={plan?.description}
                                        employee_range={plan?.employee_range}
                                        features={plan?.features}
                                        id={plan?.id}
                                        onPlanSelect={handlePlanSelect}
                                        active={plan?.id === merchantActivePlan}
                                        isPendingPlan={pendingPlan === plan?.id}
                                        uncheck_features={plan?.uncheck_features}
                                    />
                                </Box>
                            ))}
                        <PlanCard
                            name={t('custom')}
                            key="custom"
                            description={t('description')}
                            subDesc={t('subdescription')}
                            onPlanSelect={handlePlanSelect}
                            features={[
                                'Customize features suitable for your organization from the list of feature we have.',
                            ]}
                            id="custom"
                            active={merchant_info?.merchant_plan?.tag === 'custom'}
                            isPendingPlan={pendingPlan === null && hasPendingPlan}
                        />
                    </Stack>
                )}
                <Button
                    variant="outlined"
                    endIcon={openCard ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textTransform: 'inherit',
                        border: '1px solid #0E2C66',
                        px: 0.7,
                        py: 0.3,
                        mt: 4,
                        borderRadius: 1,
                    }}
                    onClick={() => setOpenCard(!openCard)}
                >
                    Compare plans
                </Button>

                {openCard && (
                    <Box sx={{ mt: 5 }}>
                        <AuditingFeatures allPlans={allPlans} />
                    </Box>
                )}
            </Box>
            <SubscribeModal open={subscribeModal} handleClose={closeModal} planId={plan} type="subscribe" />
        </Box>
    );
};

export default AppCenteredModal;
