// core components
// import Overview from "views/merchant/Overview";
import PCIScans from 'views/merchant/PCIScans';
import PenetrationTest from 'views/merchant/PenetrationTest';
// import Activities from 'views/merchant/Log';
import PCIDSS from 'views/merchant/Compliance/Pcidss';
import CIIDIRECTIVE from 'views/merchant/Compliance/CiiDirective';
import NDPR from 'views/merchant/Compliance/Ndpr';
import SOC2 from 'views/merchant/Compliance/Soc2';
import BLOCKCHAIN from 'views/merchant/Compliance/Blockchain';
import ISO27001 from 'views/merchant/Compliance/Iso27001';
import ISO27001_2022 from 'views/merchant/Compliance/Iso27001-2022';
import AccountSetup from 'views/merchant/AccountSetup';
// import ReleaseNotes from 'views/merchant/Releases';
// import VulnerabilityScan from 'views/merchant/VulnerabilityScans';
// import Setup from 'views/merchant/Setup';
// import FraudGuardian from 'views/merchant/FraudGuardian/index';
// import OnboardingPolicy from 'views/merchant/OnboardingPolicy';
// import CompanySetup from 'views/merchant/CompanySetup';
// import Users from '../views/merchant/Users';
import Employee from 'views/merchant/Inventory/Employee';
// import Computers from 'views/merchant/Inventory/Computers';
// import Infrastructures from 'views/merchant/Inventory/Infrastructures';
// import Vendors from "views/merchant/VendorManagement/Vendors/Vendors";
// import VendorLayout from 'views/merchant/VendorManagement/Vendors';
// import Response from 'views/merchant/VendorManagement/Responses/Responses';
// import AutomatedScans from 'views/merchant/AutomatedScans';
// import APICompliance from 'views/merchant/APICompliance';
import Riskassessment from 'views/merchant/Risk_Assessment';
// import VulnerabilityMgt from 'views/merchant/VulnerabilityMgt';
import Training from 'views/merchant/training';
// import AuditIndex from 'views/merchant/audit';
// import AuditStandardsIndex from 'views/merchant/audit/AuditStandards';
// import AuditPreviewIndex from 'views/merchant/audit/AuditPreview';
import ISO22301 from 'views/merchant/Compliance/Iso22301/index';
import PCIDSS4POINT0 from 'views/merchant/Compliance/Pcidss4.0/index';
import DataRoom from 'views/merchant/DataRoom';
import Kdpa from 'views/merchant/Compliance/Kdpa';
import Gdpa from 'views/merchant/Compliance/Gdpa';
// import Blank from 'views/merchant/Blank';

// icons
import {
    // Bento,
    Business,
    Class,
    // Computer,
    // ContactMail,
    Dashboard as DashboardIcon,
    // Devices,
    // DnsOutlined,
    // GroupAdd,
    Groups,
    // ManageAccounts,
    // MeetingRoom,
    // People as PeopleIcon,
    Person,
    // Policy,
    PrivacyTip,
    Scanner,
    Security,
    // StackedLineChart,
    // Store,
    Storefront,
    // Summarize,
    // TextSnippet,
    VerifiedUser,
    // VerticalSplit,
    // ViewCarousel,
    Topic,
    // Phishing,
} from '@mui/icons-material';
// import { AiOutlineApi } from 'react-icons/ai';
import DashboardIndex from '../components/Dashboard';
import NewDashboard from 'views/auditor/NewDashboard';
import ISO27001plusISO27035 from 'views/merchant/Compliance/Iso27001+Iso27035';

// import { ReactComponent as VulnerabilityMgtIcon } from 'assets/img/vulnerability-mgt.svg';

// import Blank from './views/merchant/Blank';

const complianceSubmenus = [
    {
        path: '/compliance/pcidss',
        name: 'PCIDSS',
        icon: Security,
        component: PCIDSS,
        layout: '/auditor',
    },
    {
        path: '/compliance/ndpr',
        name: 'NDPR',
        icon: Security,
        component: NDPR,
        layout: '/auditor',
    },
    {
        path: '/compliance/iso27001',
        name: 'ISO27001',
        icon: Security,
        component: ISO27001,
        layout: '/auditor',
    },
    {
        path: '/compliance/iso27001+iso27035',
        name: 'ISO27017+ISO27032',
        icon: Security,
        component: ISO27001plusISO27035,
        layout: '/auditor',
        isNew: true,
    },
    {
        path: '/compliance/iso27001_2',
        name: 'ISO27001-2022',
        icon: Security,
        component: ISO27001_2022,
        layout: '/auditor',
        isNew: true,
    },
    {
        path: '/compliance/soc2',
        name: 'SOC2',
        icon: Security,
        component: SOC2,
        layout: '/auditor',
    },
    {
        path: '/compliance/cii_directive',
        name: 'CII',
        icon: Security,
        component: CIIDIRECTIVE,
        layout: '/auditor',
    },
    {
        path: '/compliance/blockchain',
        name: 'BLOCKCHAIN',
        icon: Security,
        component: BLOCKCHAIN,
        layout: '/auditor',
    },
    {
        path: '/compliance/iso22301',
        name: 'ISO22301',
        icon: Security,
        component: ISO22301,
        layout: '/auditor',
    },
    {
        path: '/compliance/pcidss4.0',
        name: 'PCIDSS4.0',
        icon: Security,
        component: PCIDSS4POINT0,
        layout: '/auditor',
    },
    {
        path: '/compliance/kdpa',
        name: 'KDPA',
        icon: Security,
        component: Kdpa,
        layout: '/auditor',
    },
    {
        path: '/compliance/gdpa',
        name: 'GDPA',
        icon: Security,
        component: Gdpa,
        layout: '/auditor',
    },
];

const getRoutes = () => {
    let complianceStandardRoutes;
    complianceStandardRoutes = complianceSubmenus;

    const premiumRoutes = [
        {
            path: '/index',
            name: 'Auditor Dashboard',
            icon: DashboardIcon,
            component: NewDashboard,
            layout: '/auditor',
            subRoute: false,
        },
        // {
        //     path: '/users',
        //     name: 'Users',
        //     icon: PeopleIcon,
        //     component: Users,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
        // {
        //     path: '/activities',
        //     name: 'Activities',
        //     icon: StackedLineChart,
        //     component: Activities,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
        {
            path: '/pciscans',
            name: 'PCI Scans',
            icon: Scanner,
            component: PCIScans,
            layout: '/auditor',
            subRoute: false,
        },
        {
            path: '/pentest',
            name: 'Penetration Tests',
            icon: Business,
            component: PenetrationTest,
            layout: '/auditor',
            subRoute: false,
        },
        // {
        //     path: '/automated-scans',
        //     name: 'Automated Scans',
        //     icon: Devices,
        //     component: AutomatedScans,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
        {
            name: 'Inventory',
            icon: Storefront,
            subMenus: [
                {
                    path: '/inventory/people',
                    name: 'Employee',
                    icon: Groups,
                    component: Employee,
                    layout: '/auditor',
                    unavailable: false,
                },
                // {
                //     path: '/inventory/computers',
                //     name: 'Computers',
                //     icon: Computer,
                //     component: Computers,
                //     layout: '/auditor',
                //     unavailable: false,
                // },
                // {
                //     path: '/inventory/infrastructures',
                //     name: 'Infrastructures',
                //     icon: ViewCarousel,
                //     component: Infrastructures,
                //     layout: '/auditor',
                //     unavailable: false,
                // },
            ],
            subRoute: false,
        },
        {
            name: 'Compliance',
            icon: VerifiedUser,
            subMenus: complianceStandardRoutes,
            subRoute: false,
            // isNew: true,
        },
        // {
        //     name: 'Vendor Management',
        //     icon: VerticalSplit,
        //     subMenus: [
        //         {
        //             path: '/vendors',
        //             name: 'Vendors',
        //             icon: GroupAdd,
        //             component: VendorLayout,
        //             layout: '/auditor',
        //         },
        //         {
        //             path: '/vendors/responses',
        //             name: 'Vendor Responses',
        //             icon: ContactMail,
        //             component: Response,
        //             layout: '/auditor',
        //         },
        //     ],
        //     subRoute: false,
        // },
        // {
        //     path: '/audits',
        //     name: 'Audits',
        //     icon: Summarize,
        //     component: AuditIndex,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
        // {
        //     path: '/audits/:standard',
        //     name: 'Create Audit',
        //     icon: Summarize,
        //     component: AuditStandardsIndex,
        //     layout: '/auditor',
        //     subRoute: true,
        // },
        // {
        //     path: '/audits/preview/:standard',
        //     name: 'Preview Audit',
        //     icon: Summarize,
        //     component: AuditPreviewIndex,
        //     layout: '/auditor',
        //     subRoute: true,
        // },
        // {
        //     path: '/audits/edit/:standard',
        //     name: 'Edit Audit',
        //     icon: Summarize,
        //     component: AuditStandardsIndex,
        //     layout: '/auditor',
        //     subRoute: true,
        // },
        // {
        //     path: '/vulnerability-mgt',
        //     name: 'Vulnerability Management',
        //     icon: VulnerabilityMgtIcon,
        //     component: VulnerabilityMgt,
        //     layout: '/auditor',
        //     subRoute: false,
        //     // isNew: true,
        // },
        // {
        //     path: '/vulnerability',
        //     name: 'Vulnerability Scans',
        //     icon: MeetingRoom,
        //     component: VulnerabilityScan,
        //     layout: '/auditor',
        //     subRoute: true,
        // },
        {
            path: '/assessment/risk-asessement',
            name: 'Risk Assessment',
            icon: PrivacyTip,
            component: Riskassessment,
            layout: '/auditor',
            unavailable: false,
            subRoute: false,
        },
        {
            path: '/training',
            name: 'Training',
            icon: Class,
            component: Training,
            layout: '/auditor',
            subRoute: false,
        },
        {
            path: '/data-room',
            name: 'Data Room',
            icon: Topic,
            component: DataRoom,
            layout: '/auditor',
            subRoute: false,
            isNew: false,
        },
        {
            divider: true,
            title: 'SetUp',
            subRoute: false,
        },
        // {
        //     path: '/settings/fraud-guardian',
        //     component: FraudGuardian,
        //     name: 'Fraud Guardian',
        //     icon: Phishing,
        //     layout: '/auditor',
        //     subRoute: false,
        //     isNew: false,
        // },
        // {
        //     path: '/settings/api-compliance',
        //     component: APICompliance,
        //     name: 'API Compliance',
        //     icon: AiOutlineApi,
        //     layout: '/auditor',
        //     subRoute: false,
        //     isNew: true,
        // },
        // {
        //     path: '/settings/server',
        //     component: Setup,
        //     name: 'Setups',
        //     icon: Bento,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
        // {
        //     path: '/settings/onboarding-setup',
        //     component: OnboardingPolicy,
        //     name: 'Onboarding Policies',
        //     icon: Policy,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
        // {
        //     path: '/settings/company',
        //     component: CompanySetup,
        //     name: 'Company',
        //     icon: Store,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
        {
            path: '/settings/account',
            component: AccountSetup,
            name: 'Account',
            icon: Person,
            layout: '/auditor',
            subRoute: false,
        },
        {
            divider: true,
            title: 'Updates',
        },
        // {
        //     path: '/release-notes',
        //     component: ReleaseNotes,
        //     name: 'Release Notes',
        //     icon: TextSnippet,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
    ];

    const premiumRoutesFr = [
        {
            path: '/index',
            name: "Tableau de bord de l'auditeur",
            icon: DashboardIcon,
            component: DashboardIndex,
            layout: '/auditor',
            subRoute: false,
        },
        // {
        //     path: '/users',
        //     name: 'Utilisateurs',
        //     icon: PeopleIcon,
        //     component: Users,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
        // {
        //     path: '/activities',
        //     name: 'Activités',
        //     icon: StackedLineChart,
        //     component: Activities,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
        {
            path: '/pciscans',
            name: 'Analyses PCI',
            icon: Scanner,
            component: PCIScans,
            layout: '/auditor',
            subRoute: false,
        },
        {
            path: '/pentest',
            name: 'Tests de Pénétration',
            icon: Business,
            component: PenetrationTest,
            layout: '/auditor',
            subRoute: false,
        },
        // {
        //     path: '/automated-scans',
        //     name: 'Analyses Automatisées',
        //     icon: Devices,
        //     component: AutomatedScans,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
        {
            name: 'Inventaire',
            icon: Storefront,
            subMenus: [
                {
                    path: '/inventory/people',
                    name: 'Employé',
                    icon: Groups,
                    component: Employee,
                    layout: '/auditor',
                    unavailable: false,
                },
                //         {
                //             path: '/inventory/computers',
                //             name: 'Ordinateurs',
                //             icon: Computer,
                //             component: Computers,
                //             layout: '/auditor',
                //             unavailable: false,
                //         },
                //         {
                //             path: '/inventory/infrastructures',
                //             name: 'Infrastructures',
                //             icon: ViewCarousel,
                //             component: Infrastructures,
                //             layout: '/auditor',
                //             unavailable: false,
                //         },
            ],
            subRoute: false,
        },
        {
            name: 'Conformité',
            icon: VerifiedUser,
            subMenus: complianceStandardRoutes,
            subRoute: false,
            // isNew: true,
        },
        // {
        //     name: 'Gestion des fournisseurs',
        //     icon: VerticalSplit,
        //     subMenus: [
        //         {
        //             path: '/vendors',
        //             name: 'Fournisseurs',
        //             icon: GroupAdd,
        //             component: VendorLayout,
        //             layout: '/auditor',
        //         },
        //         {
        //             path: '/vendors/responses',
        //             name: 'Réponses des fournisseurs',
        //             icon: ContactMail,
        //             component: Response,
        //             layout: '/auditor',
        //         },
        //     ],
        //     subRoute: false,
        // },
        // {
        //     path: '/audits',
        //     name: 'Audits',
        //     icon: Summarize,
        //     component: AuditIndex,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
        // {
        //     path: '/audits/:standard',
        //     name: 'Créer un audit',
        //     icon: Summarize,
        //     component: AuditStandardsIndex,
        //     layout: '/auditor',
        //     subRoute: true,
        // },
        // {
        //     path: '/audits/preview/:standard',
        //     name: "Aperçu de l'audit",
        //     icon: Summarize,
        //     component: AuditPreviewIndex,
        //     layout: '/auditor',
        //     subRoute: true,
        // },
        // {
        //     path: '/audits/edit/:standard',
        //     name: "Modifier l'audit",
        //     icon: Summarize,
        //     component: AuditStandardsIndex,
        //     layout: '/auditor',
        //     subRoute: true,
        // },
        // {
        //     name: 'Gestion des vulnérabilités',
        //     icon: VulnerabilityMgtIcon,
        //     component: VulnerabilityMgt,
        //     layout: '/auditor',
        //     subRoute: false,
        //     // isNew: true,
        // },
        // {
        //     path: '/vulnerability',
        //     name: 'Scans de vulnérabilité',
        //     icon: MeetingRoom,
        //     component: VulnerabilityScan,
        //     layout: '/auditor',
        //     subRoute: true,
        // },
        {
            path: '/assessment/risk-assessment',
            name: 'Évaluation des risques',
            icon: PrivacyTip,
            component: Riskassessment,
            layout: '/auditor',
            unavailable: false,
            subRoute: false,
        },
        {
            path: '/training',
            name: 'Formation',
            icon: Class,
            component: Training,
            layout: '/auditor',
            subRoute: false,
        },
        {
            path: '/data-room',
            name: 'Salle de données',
            icon: Topic,
            component: DataRoom,
            layout: '/auditor',
            subRoute: false,
            isNew: false,
        },
        {
            divider: true,
            title: 'SetUp',
            subRoute: false,
        },
        // {
        //     path: '/settings/fraud-guardian',
        //     component: FraudGuardian,
        //     name: 'Gardien de Fraude',
        //     icon: Phishing,
        //     layout: '/auditor',
        //     subRoute: false,
        //     isNew: false,
        // },
        // {
        //     path: '/settings/api-compliance',
        //     component: APICompliance,
        //     name: 'Conformité des API',
        //     icon: AiOutlineApi,
        //     layout: '/auditor',
        //     subRoute: false,
        //     isNew: true,
        // },
        // {
        //     path: '/settings/server',
        //     component: Setup,
        //     name: 'Configurations',
        //     icon: Bento,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
        // {
        //     path: '/settings/onboarding-setup',
        //     component: OnboardingPolicy,
        //     name: `Politiques d'Onboarding`,
        //     icon: Policy,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
        // {
        //     path: '/settings/company',
        //     component: CompanySetup,
        //     name: 'Société',
        //     icon: Store,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
        {
            path: '/settings/account',
            component: AccountSetup,
            name: 'Compte',
            icon: Person,
            layout: '/auditor',
            subRoute: false,
        },
        {
            divider: true,
            title: 'Mises à jour',
        },
        // {
        //     path: '/release-notes',
        //     component: ReleaseNotes,
        //     name: 'Notes de Version',
        //     icon: TextSnippet,
        //     layout: '/auditor',
        //     subRoute: false,
        // },
    ];

    const language = window.localStorage.i18nextLng;

    if (language?.includes('fr')) {
        return premiumRoutesFr;
    } else return premiumRoutes;
};
export default getRoutes;
