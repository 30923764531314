import React from 'react';
import { Drawer } from '@mui/material';
import { Box, Table, TableBody, TableCell, TableContainer, TableRow, Paper, Typography } from '@mui/material';
import ArrowLeft from '../../../assets/img/arrowLeft.svg';
import { useTranslation } from 'react-i18next';
import AppColorTag from 'components/new_components/AppColorTag';

const PCIDetailsDrawer = ({ open, handleClose, detail }) => {
    const dateStr = detail?.date_created;
    const dateObj = new Date(dateStr);

    const options = { day: 'numeric', month: 'long', year: 'numeric' };
    let formattedDate = dateObj.toLocaleDateString('en-GB', options);

    const day = dateObj.getDate();

    const suffix =
        day % 10 === 1 && day !== 11
            ? 'st'
            : day % 10 === 2 && day !== 12
            ? 'nd'
            : day % 10 === 3 && day !== 13
            ? 'rd'
            : 'th';

    formattedDate = day + suffix + formattedDate.slice(formattedDate.indexOf(' '));

    const { t } = useTranslation('pciscans');
    return (
        <>
            <Drawer anchor="right" open={open} onClose={handleClose} transitionDuration={{ enter: 400, exit: 400 }}>
                <Box width="479px">
                    <div className="flex items-center justify-start gap-[8px] h-[56px] border-b border-solid border-[#F1F5F9] pl-[17px]">
                        <img src={ArrowLeft} onClick={handleClose} className="cursor-pointer" />
                        <span className="text-[#202D66] text-[16px] font-semibold">PCI Details</span>
                    </div>
                    <Box mt={2} ml={3} mr={3}>
                        <TableContainer
                            style={{
                                boxShadow: 'none',
                                dropShadow: 'none',
                            }}
                            component={Paper}
                        >
                            <Table
                                style={{
                                    borderCollapse: 'collapse',
                                    width: '100%',
                                    boxShadow: 'none',
                                    dropShadow: 'none',
                                }}
                            >
                                <TableBody style={{ boxShadow: 'none' }}>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                border: '1px solid #F1F5F9',
                                                width: '40%',
                                                borderLeft: 'none',
                                                marginTop: '-8px',
                                                marginBottom: '-8px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    color: '#64748B',
                                                    lineHeight: '19.5px',
                                                    marginLeft: '-10px',
                                                    marginTop: '-8px',
                                                    marginBottom: '-8px',
                                                }}
                                            >
                                                Scan name
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                border: '1px solid #F1F5F9',
                                                width: '60%',
                                                borderRight: 'none',
                                                marginTop: '-8px',
                                                marginBottom: '-8px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    color: '#395BA9',
                                                    marginLeft: '-8px',
                                                    marginTop: '-8px',
                                                    marginBottom: '-8px',
                                                }}
                                            >
                                                {detail?.name}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                border: '1px solid #F1F5F9',
                                                height: '36px',
                                                width: '40%',
                                                borderLeft: 'none',
                                                marginTop: '-8px',
                                                marginBottom: '-8px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    color: '#64748B',
                                                    lineHeight: '19.5px',
                                                    marginLeft: '-10px',
                                                    marginTop: '-8px',
                                                    marginBottom: '-8px',
                                                }}
                                            >
                                                Server
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                border: '1px solid #F1F5F9',
                                                padding: '8px',
                                                width: '60%',
                                                borderRight: 'none',
                                                marginTop: '-8px',
                                                marginBottom: '-8px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    color: '#395BA9',
                                                    marginTop: '-8px',
                                                    marginBottom: '-8px',
                                                }}
                                            >
                                                {detail?.ip_address}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                border: '1px solid #F1F5F9',
                                                height: '36px',
                                                width: '40%',
                                                borderLeft: 'none',
                                                marginTop: '-8px',
                                                marginBottom: '-8px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    color: '#64748B',
                                                    lineHeight: '19.5px',
                                                    marginLeft: '-10px',
                                                    marginTop: '-8px',
                                                    marginBottom: '-8px',
                                                }}
                                            >
                                                Progress
                                            </Typography>
                                        </TableCell>
                                        <TableCell style={{ border: '1px solid #F1F5F9', height: '36px' }}>
                                            <Typography
                                                style={{
                                                    color: '#64748B',
                                                    fontSize: '13px',
                                                    fontWeight: 400,
                                                    marginLeft: '-6px',
                                                    marginTop: '-8px',
                                                    marginBottom: '-8px',
                                                }}
                                            >
                                                {detail?.progress === 'completed' ? (
                                                    <div className="text-[12px] border-[1px] border-[#37A372] rounded-[4px] text-[#37A372] border-solid bg-[#F4FFF5] w-[84px] h-[26px] flex items-center justify-center">
                                                        {detail?.progress.charAt(0).toUpperCase() +
                                                            detail?.progress.slice(1)}
                                                    </div>
                                                ) : detail?.progress === 'running' ? (
                                                    <span
                                                        className="text-[13px] text-[#64748B]"
                                                        style={{ fontStyle: 'italic' }}
                                                    >
                                                        {detail?.progress.charAt(0).toUpperCase() +
                                                            detail?.progress.slice(1)}
                                                    </span>
                                                ) : detail?.progress === 'rerunning' ? (
                                                    <span
                                                        className="text-[13px] text-[#64748B]"
                                                        style={{ fontStyle: 'italic' }}
                                                    >
                                                        {detail?.progress.charAt(0).toUpperCase() +
                                                            detail?.progress.slice(1)}
                                                    </span>
                                                ) : (
                                                    ''
                                                )}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                border: '1px solid #F1F5F9',
                                                height: '36px',
                                                width: '40%',
                                                borderLeft: 'none',
                                                marginTop: '-8px',
                                                marginBottom: '-8px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    color: '#64748B',
                                                    lineHeight: '19.5px',
                                                    marginLeft: '-10px',
                                                    marginTop: '-8px',
                                                    marginBottom: '-8px',
                                                }}
                                            >
                                                Scan Status
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                border: '1px solid #F1F5F9',
                                                height: '36px',
                                                width: '60%',
                                                borderRight: 'none',
                                                marginTop: '-8px',
                                                marginBottom: '-8px',
                                                marginLeft: '-10px',
                                            }}
                                        >
                                            <Typography
                                                style={{ marginLeft: '-8px', marginTop: '-8px', marginBottom: '-8px' }}
                                            >
                                                {(!detail?.status && !detail?.uploaded_by) ||
                                                detail?.progress === 'running' ||
                                                detail?.progress === 'rerunning' ? (
                                                    <div className="border-[1px] -ml-[5px] border-solid border-[#E2E8F0] rounded-[4px] w-[31px] flex items-center justify-center">
                                                        --
                                                    </div>
                                                ) : (
                                                    <AppColorTag
                                                        sx={{ width: '8px', height: '8px' }}
                                                        text={detail?.status ? t('table.success') : t('table.failed')}
                                                        type={detail?.status ? 'success' : 'error'}
                                                        margin="0"
                                                        fontWeight={500}
                                                    />
                                                )}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                border: '1px solid #F1F5F9',
                                                height: '36px',
                                                width: '40%',
                                                borderLeft: 'none',
                                                marginTop: '-8px',
                                                marginBottom: '-8px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    color: '#64748B',
                                                    lineHeight: '19.5px',
                                                    marginLeft: '-10px',
                                                    marginTop: '-8px',
                                                    marginBottom: '-8px',
                                                }}
                                            >
                                                Scanned By
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                border: '1px solid #F1F5F9',
                                                height: '36px',
                                                width: '60%',
                                                borderRight: 'none',
                                                marginTop: '-8px',
                                                marginBottom: '-8px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    color: '#395BA9',
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    marginLeft: '-6px',
                                                    marginTop: '-8px',
                                                    marginBottom: '-8px',
                                                }}
                                            >
                                                {detail?.uploaded_by}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                border: '1px solid #F1F5F9',
                                                height: '36px',
                                                width: '40%',
                                                borderLeft: 'none',
                                                marginTop: '-8px',
                                                marginBottom: '-8px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    color: '#64748B',
                                                    lineHeight: '19.5px',
                                                    marginLeft: '-10px',
                                                    marginTop: '-8px',
                                                    marginBottom: '-8px',
                                                }}
                                            >
                                                Date
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                border: '1px solid #F1F5F9',
                                                height: '36px',
                                                width: '60%',
                                                borderRight: 'none',
                                                marginTop: '-8px',
                                                marginBottom: '-8px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    color: '#395BA9',
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    marginLeft: '-6px',
                                                    marginTop: '-8px',
                                                    marginBottom: '-8px',
                                                }}
                                            >
                                                {formattedDate}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell
                                            style={{
                                                border: '1px solid #F1F5F9',
                                                height: '36px',
                                                width: '40%',
                                                borderLeft: 'none',
                                                marginTop: '-8px',
                                                marginBottom: '-8px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    fontSize: '13px',
                                                    fontWeight: 500,
                                                    color: '#64748B',
                                                    lineHeight: '19.5px',
                                                    marginLeft: '-10px',
                                                    marginTop: '-8px',
                                                    marginBottom: '-8px',
                                                }}
                                            >
                                                Frequency
                                            </Typography>
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                border: '1px solid #F1F5F9',
                                                height: '36px',
                                                width: '60%',
                                                borderRight: 'none',
                                                marginTop: '-8px',
                                                marginBottom: '-8px',
                                            }}
                                        >
                                            <Typography
                                                style={{
                                                    color: '#395BA9',
                                                    fontSize: '14px',
                                                    fontWeight: 400,
                                                    marginLeft: '-6px',
                                                    marginTop: '-8px',
                                                    marginBottom: '-8px',
                                                }}
                                            >
                                                {detail?.frequency}
                                            </Typography>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                </Box>
            </Drawer>
        </>
    );
};

export default PCIDetailsDrawer;
