import React, { useState } from 'react';
import { Box, Typography, Stack } from '@mui/material';
import { Upload } from '@mui/icons-material';
//core components
import AppDrawer from 'components/new_components/AppDrawer';
import { AppFormInput, AppFormSelect, AppSubmitButton, AppForm } from 'components/new_components/forms';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import { AppFormDragFileUpload } from 'components/new_components/forms/AppFormDragFileUpload';
//validate
import { uploadModalValidation } from './utils/validation';
import { connect } from 'react-redux';
import { CreateASVScan } from 'store/actions/merchantActions';
import { toast } from 'react-toastify';
import { statusOptions } from './utils/constant';
import { accept } from 'validate';
import AppFormDateInput from 'components/new_components/forms/AppFormDateInput';
import arrowLeft from '../../../assets/img/arrowLeft.svg';
import bulb from '../../../assets/img/lightBulb.svg';

//translations
import { useTranslation } from 'react-i18next';

const UploadReportDrawer = ({ open, handleClose, CreateASVScan, payload }) => {
    const [authorization, setAuthorization] = useState(false);
    const [loadingState, setLoadingState] = useState(false);

    //translation
    const { t } = useTranslation('pciscans');

    //functions
    const today = new Date().toISOString().split('T')[0];

    const handleAuthorizationCheck = () => {
        setAuthorization(!authorization);
    };

    const handleSubmit = async (values) => {
        if (authorization === true) {
            const formData = new FormData();
            formData.append('name', values?.name);
            formData.append('ip_address', values?.ip_address);
            formData.append('uploaded_by', values?.uploaded_by);
            formData.append('status', values?.status);
            formData.append('file_field', values?.file_field);
            formData.append('authorization', authorization);
            formData.append('conducted_date', values?.conducted_date);
            setLoadingState(true);
            const res = await CreateASVScan(formData);

            setLoadingState(false);
            if (res?.success) {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.success(res?.message);
                    handleClose();
                }
            } else {
                toast.error(res?.message);
            }
        } else {
            toast.info('You have to authorize this request!');
        }
    };

    return (
        <AppForm
            initialValues={{
                ...payload,
                status: payload?.status ? payload?.status : t('uploadReportModal.statusValue'),
            }}
            onSubmit={handleSubmit}
            validate={uploadModalValidation}
        >
            <AppDrawer
                open={open}
                handleClose={handleClose}
                title={
                    <>
                        <div className="flex gap-[4px] w-full items-center justify-start h-[60px] ">
                            <img src={arrowLeft} alt="arrow-left" onClick={handleClose} />
                            <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#202D66' }}>
                                {t('uploadReportModal.title')}
                            </Typography>
                        </div>
                        <div className="border-b-[1px] border-solid border-[#F1F5F9]"></div>
                    </>
                }
                width={511}
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            mb: 2,
                        }}
                    >
                        <AppSubmitButton
                            text={t('uploadReport')}
                            startIcon={<Upload />}
                            variant="contained"
                            color="primary"
                            loading={loadingState}
                            loadingPosition="center"
                            sx={{ width: '50%' }}
                        />
                    </Box>
                }
            >
                <Stack
                    flexDirection="row"
                    justifyContent="flex-start"
                    sx={{
                        py: '8px',
                        px: '16px',
                        fontSize: 13,
                        fontWeight: 400,
                        color: '#64748B',
                        background: '#F8FAFC',
                        borderRadius: '4px',
                        mt: '24px',
                        mr: '24px',
                        ml: '24px',
                        display: 'flex',
                        gap: '10px',
                        alignItems: 'flex-start',
                        border: '1px solid #F0F0FA',
                    }}
                >
                    <img src={bulb} alt="bulb" />
                    {t('uploadReportModal.subtitle')}
                </Stack>
                <div style={{ marginLeft: '24px', marginRight: '24px' }}>
                    <AppFormInput
                        type="text"
                        label={t('uploadReportModal.scanName')}
                        name="name"
                        placeholder={t('uploadReportModal.scanName')}
                    />
                    <AppFormSelect
                        name="status"
                        label={t('uploadReportModal.status')}
                        options={statusOptions}
                        defaultValue={t('uploadReportModal.statusValue')}
                    />
                    <AppFormInput
                        type="text"
                        label={t('uploadReportModal.doneBy')}
                        name="uploaded_by"
                        placeholder={t('uploadReportModal.platformName')}
                    />
                    <AppFormInput
                        type="text"
                        label={t('uploadReportModal.ipOrURL')}
                        name="ip_address"
                        placeholder={t('uploadReportModal.ipAddress')}
                    />
                    <AppFormDateInput name="conducted_date" label={t('uploadReportModal.pciDate')} max={today} />

                    <AppFormDragFileUpload name="file_field" accept={accept} />
                    <div className="mb-[208px]">
                        <AppCheckboxInput
                            name="authorization"
                            label={t('uploadReportModal.authorization')}
                            onChange={handleAuthorizationCheck}
                            value={authorization}
                        />
                    </div>
                </div>
                <div className="flex items-center justify-end w-full gap-[8px] bg-[#F8FAFC] pt-[40px] h-[68px] pr-[20px]">
                    <button
                        type="cancel"
                        onClick={handleClose}
                        className="bg-white relative z-10 flex items-center cursor-pointer justify-center text-[14px] w-[84px] rounded-[2px] h-[36px] font-medium text-[#334155] border-[1px] border-[#E2E8F0] border-solid"
                    >
                        Cancel
                    </button>
                    <div>
                        <AppSubmitButton
                            text="Submit"
                            type="submit"
                            variant="contained"
                            color="primary"
                            loading={loadingState}
                            loadingPosition="center"
                            sx={{ width: '84px', height: '36px', boxShadow: 'none' }}
                        />
                    </div>
                </div>
            </AppDrawer>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { CreateASVScan })(UploadReportDrawer);
