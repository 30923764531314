import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import RiskOverview from './Overview';
import RunAssessment from './RunAssessment/index';
import RiskRegister from './RiskRegister/index';
import Report from './Report';
import { initialValues, reportInitialValues } from './utils/constants';

//userguide
import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';

//translations
import { useTranslation } from 'react-i18next';

//redux
import { connect, useSelector } from 'react-redux';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';

const index = (props) => {
    const { all_article_header } = props;
    const [indexValue, setIndexValue] = useState(1);
    const [selfPayload, setSelfPayload] = useState(initialValues);
    const [intelligencePayload, setIntelligencePayload] = useState(reportInitialValues);
    const [page, setPage] = useState(1);
    const [openModal, setOpenModal] = useState(false);
    const [type, setType] = useState('');
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    //userguide
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);
    const ref4 = useRef(null);

    const STEPS_LENGTH = 4;

    const steps = [
        {
            title: <UserGuideHeader title="Risk Overview" id={1} length={STEPS_LENGTH} />,
            description: 'This gives you a summary of your risk assesment',
            target: () => ref1.current,
            placement: 'bottomLeft',
        },
        {
            title: <UserGuideHeader title="Risk Assessment" id={2} length={STEPS_LENGTH} />,
            description: 'Here you can run a risk assessment on your asset or any entity.',
            target: () => ref2.current,
            placement: 'bottomLeft',
        },
        {
            title: <UserGuideHeader title="Risk register" id={3} length={STEPS_LENGTH} />,
            description: 'This displays a tabular view of every entity for which a risk assessment has been completed.',
            target: () => ref3.current,
            placement: 'bottomLeft',
        },
        {
            title: <UserGuideHeader title="Report" id={4} length={STEPS_LENGTH} />,
            description: 'This displays history of all risk assessment report generated',
            target: () => ref4.current,
            placement: 'bottomLeft',
        },
    ];
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    //translation
    const { t } = useTranslation('riskAssessment');

    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);
    const userguide_info = useSelector((state) => state?.guideReducers?.userguide_info?.[0]?.risk_assessment);

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Risk Management'),
        [all_article_header]
    );

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ risk_assessment: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, riskassessment: false });
        setOpenArticle(false);
    };

    //constants
    const tabList = [
        {
            id: 1,
            name: t('riskOverview'),
            ref: ref1,
        },
        {
            id: 2,
            name: user_type !== 'auditor' ? t('runAssessment') : role !== 'auditor' ? t('runAssessment') : null,
            ref: ref2,
        },
        {
            id: 3,
            name: t('riskRegister'),
            ref: ref3,
        },
        {
            id: 4,
            name: role !== 'auditor' ? t('report') : null,
            ref: ref4,
        },
    ];

    const onChangeTab = async (val) => {
        setIndexValue(val);
        if (val !== 2) {
            setSelfPayload(initialValues);
            setIntelligencePayload(reportInitialValues);
            setOpenModal(true);
            setType('');
        }
    };
    const goBack = (type) => {
        setIndexValue(3);
        setType(type);
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handleType = (type, payload) => {
        setIndexValue(2);
        if (type === 'self') {
            setSelfPayload(payload);
        } else {
            setIntelligencePayload(payload);
        }
        setType(type);
        setOpenModal(false);
    };

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.riskassessment ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.riskassessment]);

    return (
        <div>
            <div className="py-3 px-[2rem] border-b border-gray-[#B8BCCC] flex items-center font-bold textbrandColor dash_tab mx-[-2rem]">
                {tabList?.map((res, index) => (
                    <React.Fragment key={index}>
                        <div
                            className={`flex items-center cursor-pointer px-3 transition tab ${
                                indexValue === res.id && 'active'
                            }`}
                            onClick={() => onChangeTab(res.id)}
                            key={index}
                            ref={res.ref}
                        >
                            <span className={`${indexValue === res.id ? 'textbrandColor' : 'text-[#919094]'}`}>
                                {res.name}
                            </span>
                        </div>
                    </React.Fragment>
                ))}
            </div>
            {indexValue === 1 && <RiskOverview />}
            {indexValue === 2 && role !== 'auditor' ? (
                <RunAssessment
                    intelligencePayload={intelligencePayload}
                    setIntelligencePayload={setIntelligencePayload}
                    selfPayload={selfPayload}
                    setSelfPayload={setSelfPayload}
                    page={page}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    type={type}
                    setType={setType}
                    goBack={goBack}
                    setIndexValue={setIndexValue}
                />
            ) : indexValue === 2 && role !== 'auditor' ? (
                <RunAssessment
                    intelligencePayload={intelligencePayload}
                    setIntelligencePayload={setIntelligencePayload}
                    selfPayload={selfPayload}
                    setSelfPayload={setSelfPayload}
                    page={page}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    type={type}
                    setType={setType}
                    goBack={goBack}
                    setIndexValue={setIndexValue}
                />
            ) : null}
            {indexValue === 3 && <RiskRegister handlePageChange={handlePageChange} handleType={handleType} />}
            {indexValue === 4 && <Report />}
            <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Risk Management"
                headerId={oneArticle?.id}
                icon={true}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    all_article_header: state?.articlesReducers?.all_article_header,
});

export default connect(mapStateToProps)(index);
