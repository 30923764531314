import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Stack } from '@mui/material';
import { Download, MoreVert } from '@mui/icons-material';
import AppTableActionMenu from 'components/new_components/app-table/AppTableActionMenu';
import RequestMenuItem from 'components/new_components/RequestMenuItem';
import { toast } from 'react-toastify';

//translations
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GetDownloadLink } from 'store/actions/generalActions';
import LoadingState from 'components/new_components/LoadingState';

const PentestTableActions = (props) => {
    const { row, openDownloadPentestReportModal, tableTab, openViewModal } = props;

    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);

    const theme = useTheme();

    // state
    const [anchor, setAnchor] = useState(null);
    const [loadingLink, setLoadingLink] = useState(false);

    //translation
    const { t } = useTranslation('pentest');

    // functions
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);
    const openReportModal = () => {
        closeMenu();
        openDownloadPentestReportModal(row?.id);
    };

    const handleOpenViewModal = () => {
        closeMenu();
        openViewModal('view', row);
    };
    const handleOpenRerunModal = () => {
        closeMenu();
        openViewModal('rerun', row);
    };

    const getDownloadLink = async () => {
        if (row?.pentestscan) {
            setLoadingLink(true);
            const res = await GetDownloadLink('asvc_requests', 'pentest_request', row.id)();
            setLoadingLink(false);
            if (res?.success) {
                // open decoded url
                window.open(res?.data, '_blank');
            } else {
                toast.error('Something went wrong!');
                // open original file url
                window.open(row?.pentestscan, '_blank');
            }
        }
    };

    const menus = [
        {
            name: t('table.tableAction.viewDetails'),
            component: (
                <RequestMenuItem
                    header={t('table.tableAction.viewDetails')}
                    onClick={handleOpenViewModal}
                    disabled={row?.progress === 'running' || row?.progress === 'rerunning'}
                />
            ),
            isAvailable: ['requested'],
        },
        user_type !== 'auditor'
            ? {
                  name: t('table.tableAction.rerunTest'),
                  component: (
                      <RequestMenuItem
                          header={t('table.tableAction.rerunTest')}
                          onClick={handleOpenRerunModal}
                          disabled={row?.progress === 'running' || row?.progress === 'rerunning'}
                      />
                  ),
                  isAvailable: ['requested'],
              }
            : role !== 'auditor'
            ? {
                  name: t('table.tableAction.rerunTest'),
                  component: (
                      <RequestMenuItem
                          header={t('table.tableAction.rerunTest')}
                          onClick={handleOpenRerunModal}
                          disabled={row?.progress === 'running' || row?.progress === 'rerunning'}
                      />
                  ),
                  isAvailable: ['requested'],
              }
            : null,
        {
            name: t('table.tableAction.downloadReport'),
            component: (
                <RequestMenuItem
                    header={t('table.tableAction.downloadReport')}
                    onClick={openReportModal}
                    disabled={row?.progress === 'running'}
                />
            ),
            isAvailable: ['uploaded', 'requested'],
        },
        // {
        //     name: 'Delete',
        //     component: (
        //         <RequestMenuItem
        //             header="Delete"
        //             // onClick={handleOpenRerunModal}
        //             // disabled={
        //             //     row?.progress === 'running' ||
        //             //     row?.progress === 'running' ||
        //             //     row?.uploaded_by?.toLowerCase() !== 'clone systems'
        //             // }
        //         />
        //     ),
        //     isAvailable: ['uploaded', 'requested'],
        // },
    ];
    return (
        <Stack direction="row">
            {tableTab === 'requested' && (
                <IconButton
                    sx={{ color: theme.palette.gray[900] }}
                    onClick={getDownloadLink}
                    disabled={!row?.pentestscan}
                >
                    {loadingLink ? <LoadingState size={20} /> : <Download />}
                </IconButton>
            )}

            <IconButton sx={{ color: theme.palette.gray[900] }} onClick={openMenu}>
                <MoreVert />
            </IconButton>
            <AppTableActionMenu anchor={anchor} closeMenu={closeMenu}>
                {menus?.map((menu) => {
                    return menu?.isAvailable?.includes(tableTab) && <div key={menu.name}>{menu.component}</div>;
                })}
            </AppTableActionMenu>
        </Stack>
    );
};

export default PentestTableActions;
