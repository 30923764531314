/* eslint-disable no-unused-vars */
import { ReactComponent as Logo } from 'assets/img/brand/logo.svg';
import { ReactComponent as Loading } from 'assets/img/loading.svg';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import axiosInstance from '../../../../../utils/https';

export default function SpoolVendorReport({ targetRef, vendorReport, setVendorReport }) {
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);

    async function getVendorReport(merchantID) {
        try {
            const response = await axiosInstance().post('/compliance/get_vendor_details/', {
                merchant_id: merchantID,
            });
            setVendorReport(response.data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (merchant_info.id) getVendorReport(merchant_info.id);
    }, [merchant_info.id]);

    return (
        <div className="fixed -bottom-[200%] -right-[200%] h-[85%] w-[746.76px] z-50 bg-white overflow-y-scroll overflow-x-hidden">
            <main ref={targetRef}>
                <section className="w-[746.76px] h-[1070px] relative">
                    <div className="absolute top-10 left-0">
                        <Logo className="w-[120px]" />
                    </div>

                    <div className="absolute top-1/2 text-center left-1/2 -translate-y-1/2 -translate-x-1/2 transform">
                        <h2 className="text-[#334155] font-bold text-[36px]">VENDOR REPORT</h2>

                        <p className="text-[#475569] font-medium text-xl mt-9">{vendorReport?.report_date}</p>

                        <h3 className="mt-20 text-[#475569] font-semibold text-[28px]">
                            {vendorReport?.merchant_name}
                        </h3>
                    </div>
                </section>

                {vendorReport?.vendor_data?.length &&
                    vendorReport?.vendor_data?.map((vendor, index) => (
                        <section className="w-[746.76px] h-[1070px]">
                            <h3 className="text-[#2B3674] font-semibold text-xl mt-6 ml-2">
                                {index + 1}. {vendor?.vendor_name}
                            </h3>

                            <p className="mt-6 text-[#475569] font-semibold text-[17px]">Scorecard</p>

                            <div className="mt-8">
                                {vendor?.vendor_has_responded ? (
                                    <div className="relative h-[180px] w-full">
                                        <div className="absolute top-0 left-12">
                                            <div className="absolute w-[140px] h-[140px] rounded-full bg-[#E2E8F0]">
                                                <div
                                                    className={`absolute rounded-full w-[91px] h-[91px] border-[76px] border-transparent rotate-45 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform ${
                                                        vendor?.vendor_scorecard > 75
                                                            ? 'border-y-[#55BE8B] border-x-[#55BE8B]'
                                                            : vendor?.vendor_scorecard > 66
                                                            ? 'border-y-[#55BE8B] border-r-[#55BE8B]'
                                                            : vendor?.vendor_scorecard > 50
                                                            ? 'border-y-[#F2C021] border-r-[#F2C021]'
                                                            : vendor?.vendor_scorecard > 33
                                                            ? 'border-t-[#F2C021] border-r-[#F2C021]'
                                                            : vendor?.vendor_scorecard > 25
                                                            ? 'border-t-[#FF5449] border-r-[#FF5449]'
                                                            : 'border-t-[#FF5449]'
                                                    }`}
                                                >
                                                    <div className="bg-white rounded-full absolute w-[91px] h-[91px] top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 transform -rotate-45">
                                                        <p className="ml-4 text-[#202D66] font-bold text-[34px]">
                                                            {Math.round(vendor?.vendor_scorecard)}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="absolute top-4 left-[240px]">
                                            <div className="absolute top-0 left-0">
                                                <div className="bg-[#FF5449] h-[16px] w-[16px] rounded-sm absolute top-0 left-0" />

                                                <p className="absolute top-0 left-5 -mt-[9px] text-[#64748B] font-medium text-[13px] whitespace-nowrap">
                                                    0 - 33
                                                </p>
                                            </div>

                                            <div className="absolute top-[37px] left-0">
                                                <div className="bg-[#F2C021] h-[16px] w-[16px] rounded-sm absolute top-0 left-0" />

                                                <p className="absolute top-0 left-5 -mt-[9px] text-[#64748B] font-medium text-[13px] whitespace-nowrap">
                                                    34 - 66
                                                </p>
                                            </div>

                                            <div className="absolute top-[76px] left-0">
                                                <div className="bg-[#55BE8B] h-[16px] w-[16px] rounded-sm absolute top-0 left-0" />

                                                <p className="absolute top-0 left-5 -mt-[9px] text-[#64748B] font-medium text-[13px] whitespace-nowrap">
                                                    67 - 100
                                                </p>
                                            </div>
                                        </div>

                                        <div className="absolute top-4 left-96">
                                            <div className="absolute top-0 left-0 w-[250px] border border-[#E2E8F0] rounded-lg h-[48px]">
                                                <div className="w-[1px] h-full bg-[#F1F5F9] absolute top-0 left-1/2 -translate-x-1/2" />

                                                <p className="text-[#395BA9] absolute top-0 left-1.5 mt-[3px] font-semibold text-sm">
                                                    Score Risk Level
                                                </p>

                                                <div className="absolute top-3.5 left-36">
                                                    <div className="bg-[#F2C021] h-[16px] w-[16px] rounded absolute top-0 left-0" />

                                                    <p className="absolute top-0 left-5 -mt-[11px] text-[#475569] font-medium text-base whitespace-nowrap">
                                                        {vendor?.vendor_risk_level}
                                                    </p>
                                                </div>
                                            </div>

                                            {vendor?.vendor_risk_level !== 'Low' && (
                                                <p
                                                    className={`absolute text-center top-[48px] left-0 w-[250px] font-medium text-[13px] ${
                                                        vendor?.vendor_risk_level === 'Medium'
                                                            ? 'text-[#64748B]'
                                                            : 'text-[#FF5449]'
                                                    }`}
                                                >
                                                    {vendor?.vendor_risk_level === 'Medium'
                                                        ? 'You may be at risk using this vendor'
                                                        : 'You are at risk using this vendor'}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                ) : (
                                    <div className="flex justify-center items-center flex-col gap-2 mb-4 mt-2">
                                        <div className="w-[316px]">
                                            <Loading className="w-[170px]" />
                                        </div>

                                        <p className="text-[#395BA9] font-medium text-lg -mt-[10px]">
                                            No response from this vendor yet
                                        </p>
                                    </div>
                                )}
                            </div>

                            {vendor?.vendor_has_responded && (
                                <div className="flex gap-6">
                                    <div className="flex-1 border-t border-[#F1F5F9]">
                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Organizational Information Security
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                    {vendor?.org_info_sec_info?.percent}
                                                </p>

                                                <div className="flex-1">
                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                        <div>
                                                            <div
                                                                className={`w-2 h-2 rounded-full ${
                                                                    vendor?.org_info_sec_info?.risk_level === 'Low'
                                                                        ? 'bg-[#55BE8B]'
                                                                        : vendor?.org_info_sec_info?.risk_level ===
                                                                          'Medium'
                                                                        ? 'bg-[#F2C021]'
                                                                        : 'bg-[#FF5449]'
                                                                }`}
                                                            />
                                                        </div>

                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                            {vendor?.org_info_sec_info?.risk_level}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                General Security
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                    {vendor?.gen_sec_info?.percent}
                                                </p>

                                                <div className="flex-1">
                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                        <div>
                                                            <div
                                                                className={`w-2 h-2 rounded-full ${
                                                                    vendor?.gen_sec_info?.risk_level === 'Low'
                                                                        ? 'bg-[#55BE8B]'
                                                                        : vendor?.gen_sec_info?.risk_level === 'Medium'
                                                                        ? 'bg-[#F2C021]'
                                                                        : 'bg-[#FF5449]'
                                                                }`}
                                                            />
                                                        </div>

                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                            {vendor?.gen_sec_info?.risk_level}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Network Security
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                    {vendor?.net_sec_info?.percent}
                                                </p>

                                                <div className="flex-1">
                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                        <div>
                                                            <div
                                                                className={`w-2 h-2 rounded-full ${
                                                                    vendor?.net_sec_info?.risk_level === 'Low'
                                                                        ? 'bg-[#55BE8B]'
                                                                        : vendor?.net_sec_info?.risk_level === 'Medium'
                                                                        ? 'bg-[#F2C021]'
                                                                        : 'bg-[#FF5449]'
                                                                }`}
                                                            />
                                                        </div>

                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                            {vendor?.net_sec_info?.risk_level}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Security Monitoring
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                    {vendor?.sec_mon_info?.percent}
                                                </p>

                                                <div className="flex-1">
                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                        <div>
                                                            <div
                                                                className={`w-2 h-2 rounded-full ${
                                                                    vendor?.sec_mon_info?.risk_level === 'Low'
                                                                        ? 'bg-[#55BE8B]'
                                                                        : vendor?.sec_mon_info?.risk_level === 'Medium'
                                                                        ? 'bg-[#F2C021]'
                                                                        : 'bg-[#FF5449]'
                                                                }`}
                                                            />
                                                        </div>

                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                            {vendor?.sec_mon_info?.risk_level}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {vendor?.aml_info && (
                                            <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                                <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                    AML
                                                </p>

                                                <div className="flex-1 relative flex items-center">
                                                    <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                        {vendor?.aml_info?.percent}
                                                    </p>

                                                    <div className="flex-1">
                                                        <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                            <div>
                                                                <div
                                                                    className={`w-2 h-2 rounded-full ${
                                                                        vendor?.aml_info?.risk_level === 'Low'
                                                                            ? 'bg-[#55BE8B]'
                                                                            : vendor?.aml_info?.risk_level === 'Medium'
                                                                            ? 'bg-[#F2C021]'
                                                                            : 'bg-[#FF5449]'
                                                                    }`}
                                                                />
                                                            </div>

                                                            <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                                {vendor?.aml_info?.risk_level}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex-1 border-t border-[#F1F5F9]">
                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Business Continuity / Disaster Recovery
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                    {vendor?.bus_cont_info?.percent}
                                                </p>

                                                <div className="flex-1">
                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                        <div>
                                                            <div
                                                                className={`w-2 h-2 rounded-full ${
                                                                    vendor?.bus_cont_info?.risk_level === 'Low'
                                                                        ? 'bg-[#55BE8B]'
                                                                        : vendor?.bus_cont_info?.risk_level === 'Medium'
                                                                        ? 'bg-[#F2C021]'
                                                                        : 'bg-[#FF5449]'
                                                                }`}
                                                            />
                                                        </div>

                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                            {vendor?.bus_cont_info?.risk_level}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Incident Response
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                    {vendor?.incident_res_info?.percent}
                                                </p>

                                                <div className="flex-1">
                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                        <div>
                                                            <div
                                                                className={`w-2 h-2 rounded-full ${
                                                                    vendor?.incident_res_info?.risk_level === 'Low'
                                                                        ? 'bg-[#55BE8B]'
                                                                        : vendor?.incident_res_info?.risk_level ===
                                                                          'Medium'
                                                                        ? 'bg-[#F2C021]'
                                                                        : 'bg-[#FF5449]'
                                                                }`}
                                                            />
                                                        </div>

                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                            {vendor?.incident_res_info?.risk_level}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Risk Management/Auditing /Regulatory Compliance
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                    {vendor?.risk_mgt_info?.percent}
                                                </p>

                                                <div className="flex-1">
                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                        <div>
                                                            <div
                                                                className={`w-2 h-2 rounded-full ${
                                                                    vendor?.risk_mgt_info?.risk_level === 'Low'
                                                                        ? 'bg-[#55BE8B]'
                                                                        : vendor?.risk_mgt_info?.risk_level === 'Medium'
                                                                        ? 'bg-[#F2C021]'
                                                                        : 'bg-[#FF5449]'
                                                                }`}
                                                            />
                                                        </div>

                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                            {vendor?.risk_mgt_info?.risk_level}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[60%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Availability
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <p className="w-[66px] px-2 text-[#395BA9] font-medium text-sm -mt-[15px]">
                                                    {vendor?.avail_info?.percent}
                                                </p>

                                                <div className="flex-1">
                                                    <div className="w-fit flex px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                        <div>
                                                            <div
                                                                className={`w-2 h-2 rounded-full ${
                                                                    vendor?.avail_info?.risk_level === 'Low'
                                                                        ? 'bg-[#55BE8B]'
                                                                        : vendor?.avail_info?.risk_level === 'Medium'
                                                                        ? 'bg-[#F2C021]'
                                                                        : 'bg-[#FF5449]'
                                                                }`}
                                                            />
                                                        </div>

                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                            {vendor?.avail_info?.risk_level}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}

                            <p className="my-4 text-[#475569] font-semibold text-[17px]">Vendor Details</p>

                            <div className="flex gap-6">
                                <div className="flex-1 border-t border-[#F1F5F9]">
                                    <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                        <p className="w-[35%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                            Vendor name
                                        </p>

                                        <div className="flex-1 relative flex items-center">
                                            <p className="px-2 text-[#395BA9] font-normal text-sm -mt-[15px]">
                                                {vendor?.vendor_name}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                        <p className="w-[35%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                            Vendor type
                                        </p>

                                        <div className="flex-1 relative flex items-center">
                                            <p className="px-2 text-[#395BA9] font-normal text-sm -mt-[15px]">
                                                {vendor?.vendor_type}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                        <p className="flex-1 border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                            Vendor email
                                        </p>

                                        <div className="w-[65%] relative flex items-center">
                                            <p className="px-2 text-[#395BA9] font-normal text-sm -mt-[15px] text-wrap">
                                                {vendor?.vendor_email?.slice(0, 26)}
                                                {vendor?.vendor_email?.length > 25 && (
                                                    <>
                                                        <br /> {vendor?.vendor_email?.slice(26)}
                                                    </>
                                                )}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                        <p className="w-[35%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                            Data shared
                                        </p>

                                        <div className="flex-1 relative flex items-center">
                                            <p className="px-2 text-[#395BA9] font-normal text-sm -mt-[15px] capitalize">
                                                {vendor?.vendor_data_shared}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                        <p className="w-[35%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                            Products
                                        </p>

                                        <div className="flex-1 relative flex items-center">
                                            <p className="px-2 text-[#395BA9] font-normal text-sm -mt-[15px] capitalize">
                                                {vendor?.vendor_products}
                                            </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex-1 border-t border-[#F1F5F9]">
                                    <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                        <p className="w-[35%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                            Description of service
                                        </p>

                                        <div className="flex-1 relative flex items-center">
                                            <p className="px-2 text-[#395BA9] font-normal text-sm -mt-[15px] capitalize">
                                                {vendor?.vendor_service_desc}
                                            </p>
                                        </div>
                                    </div>

                                    <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                        <p className="w-[35%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                            Intended risk level
                                        </p>

                                        <div className="flex-1 relative flex items-center">
                                            <div className="flex-1">
                                                <div className="w-fit flex mx-2 px-2 py-1.5 gap-1 border border-[#E2E8F0] rounded items-center">
                                                    <div>
                                                        <div
                                                            className={`w-2 h-2 rounded-full ${
                                                                vendor?.intended_risk_level === 'Low'
                                                                    ? 'bg-[#55BE8B]'
                                                                    : vendor?.intended_risk_level === 'Medium'
                                                                    ? 'bg-[#F2C021]'
                                                                    : 'bg-[#FF5449]'
                                                            }`}
                                                        />
                                                    </div>

                                                    <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                        {vendor?.intended_risk_level}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                        <p className="w-[35%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                            Risk alert level
                                        </p>

                                        <div className="flex-1 relative flex items-center">
                                            <p className="px-2 text-[#395BA9] font-normal text-sm -mt-[15px]">
                                                {vendor?.vendor_risk_alert_level}
                                            </p>
                                        </div>
                                    </div>

                                    {!vendor?.vendor_has_responded && (
                                        <div className="flex border-b border-[#F1F5F9] overflow-hidden">
                                            <p className="w-[35%] border-r border-[#F1F5F9] text-[#64748B] text-[13px] leading-5 font-medium py-[15px] px-1 -mt-[15px]">
                                                Scorecard
                                            </p>

                                            <div className="flex-1 relative flex items-center">
                                                <div className="flex-1">
                                                    <div className="w-fit flex mx-2 px-2 py-2 gap-1 border border-[#E2E8F0] rounded items-center">
                                                        <p className="text-[#64748B] font-medium text-xs -mt-[15px]">
                                                            ---
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </section>
                    ))}
            </main>
        </div>
    );
}
