import React from 'react';
import { useState } from 'react';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { SubmitMerchantAnswers } from 'store/actions/complianceActions';
import { GetAllFormsByTechSec } from 'store/actions/iso27001+iso27035Actions';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import Isogroups from './components/Isogroups';
import ResourceView from './components/ResourceView';
import { AppForm } from 'components/new_components/forms';
import { validateForm } from '../validation';
import { AppFormContext } from 'components/new_components/forms';

const AuditQuestions = (props) => {
    const {
        loading: loadingIsogroups,
        getIsogroups,
        category,
        view,
        handleViewChange,
        goBack,
        selectedIsogroup,
        openTaskAssignModal,
    } = props;

    // state
    const [loading, setLoading] = useState({ content: false });
    const [formPayload, setFormPayload] = useState({});
    const [touchedFields, setTouchedFields] = useState({});

    const dispatch = useDispatch();
    const merchant_answers = useSelector((state) => state?.complianceReducers?.merchant_answers);
    const all_tech_sec_forms = useSelector((state) => state?.iso27001plusiso27035Reducers?.all_tech_sec_forms);
    const all_compliance_assigned_tasks = useSelector(
        (state) => state?.generalReducers?.user_info?.compliance_assigned_tasks
    );

    // async functions
    const getTechsecgroupQuestions = async (isogroupId) => {
        setLoading((curr) => ({ ...curr, content: true }));
        const res = await dispatch(GetAllFormsByTechSec(isogroupId));
        setLoading((curr) => ({ ...curr, content: false }));
        if (!res?.success) {
            toast.error('Something went wrong!');
            console.log('Error:::::', res?.message);
        }
    };
    const handleQuestionExempted = (isogroupId) => {
        getIsogroups(category);
        getTechsecgroupQuestions(isogroupId);
    };
    const handleSubmitAnswers = async (values) => {
        setLoading((curr) => ({ ...curr, submit: true }));
        const res = await dispatch(
            SubmitMerchantAnswers({
                answer: values,
                form_id: formPayload?.form_id,
            })
        );
        setLoading((curr) => ({ ...curr, submit: false }));
        if (res?.success) {
            toast.success("You have successfully answered this question, press 'NEXT' to go to the nest question.");
        } else {
            toast.error('Something went wrong!');
            console.log('Error::::', res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (selectedIsogroup) {
            getTechsecgroupQuestions(selectedIsogroup);
        }
    }, [selectedIsogroup]);

    return view === 0 ? (
        <Isogroups
            resource="form"
            onViewChange={handleViewChange}
            loading={loadingIsogroups}
            openTaskAssignModal={openTaskAssignModal}
            all_compliance_assigned_tasks={all_compliance_assigned_tasks}
        />
    ) : (
        <AppForm
            initialValues={formPayload.values || {}}
            validate={(values) => validateForm(values, formPayload?.requiredValues)}
            onSubmit={handleSubmitAnswers}
        >
            <AppFormContext getTouched={setTouchedFields}>
                <ResourceView
                    goBack={goBack}
                    isogroupID={selectedIsogroup}
                    resource="form"
                    merchantResources={merchant_answers}
                    isogroupResources={all_tech_sec_forms?.filter((form) => form?.form_fields?.length > 0)}
                    setFormPayload={setFormPayload}
                    loading={loading?.content}
                    touchedFields={touchedFields}
                    submitting={loading?.submit}
                    handleResourceExempted={handleQuestionExempted}
                    openTaskAssignModal={openTaskAssignModal}
                    all_compliance_assigned_tasks={all_compliance_assigned_tasks}
                />
            </AppFormContext>
        </AppForm>
    );
};

export default AuditQuestions;
