import React, { useState } from 'react';
import { CardContainer } from '../../dashboard-utils/DashboardUtils';
import { Doughnut } from 'react-chartjs-2';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import BackgroundCheckGraphReport from './graphrecord/BackgroundCheckGraphReport';
import GroupEmployeeIcon from '../../../../assets/img/dashboard/GroupEmployeeIcon.svg';
import DisabledFeatureMsg from '../../OtherReports/DisabledFeatureMsg';
import { isFullFunctionPlan } from 'utils';

//translations
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const EmployeeReportSection = ({ pricing_plan, dashboardResult, cardStyle, auditor }) => {
    const [indexValue, setIndexValue] = useState(1);
    // const { employees_with_installed_agents_count, employee_count } = dashboardResult;

    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);

    //translation
    const { t } = useTranslation('overview');

    //constant
    const tabList = [
        {
            id: 1,
            name: t('employeeReportSection.withAgents'),
            items: ``,
        },
        {
            id: 2,
            name: t('employeeReportSection.backgroundCheck'),
        },
        {
            id: 3,
            name: t('employeeReportSection.all'),
        },
    ];

    const onChangeTab = (val) => {
        setIndexValue(val);
    };

    const data = {
        labels: [t('employeeReportSection.withAgents'), t('employeeReportSection.withoutAgents')],
        datasets: [
            {
                label: '',
                data: [
                    dashboardResult?.employees_with_installed_agents_count,
                    auditor === 'auditor'
                        ? dashboardResult?.employees_count - dashboardResult?.employees_with_installed_agents_count
                        : dashboardResult?.employee_count - dashboardResult?.employees_with_installed_agents_count,
                ],
                backgroundColor: ['#05DEEC', '#0A1A4A'],
                borderWidth: 0,
                borderRadius: '10px',
                boxShadow: '0px 4px 4px rgba(10, 26, 74, 0.42)',
            },
        ],
    };
    const options = {
        responsive: true,
        plugins: {
            legend: {
                position: 'bottom',
            },
            title: {
                display: true,
                text: '',
            },
            datalabels: {
                display: false,
            },
        },
    };
    ChartJS.register(ArcElement, Tooltip, Legend);
    return (
        <CardContainer cardStyle={cardStyle}>
            <div className="px-4 py-3 border-b border-b-gray-300 lg:text-[20px] text-lg textbrandColor font-bold">
                {t('employeeReportSection.title')}
            </div>
            <div className="bg-[#F2F0F4] flex justify-between text-[#46464A]  px-2 py-2 employee_card">
                <div className="flex gap-3 w-fit">
                    {tabList.map((res, index) => (
                        <React.Fragment key={index}>
                            <div
                                className={`inner_tab ${indexValue === res.id ? 'active' : ''} px-2 py-1`}
                                onClick={() => onChangeTab(res.id)}
                            >
                                {res.name}
                            </div>
                        </React.Fragment>
                    ))}
                </div>
            </div>
            {indexValue === 1 && (
                <div className="p-4">
                    <>
                        <Doughnut
                            options={options}
                            data={data}
                            type="doughnut"
                            className="!h-[250px] !w-[250px] m-auto"
                        />
                    </>
                </div>
            )}
            {indexValue === 2 && (
                <div className="p-4">
                    {isFullFunctionPlan(pricing_plan?.tag) || user_type === 'auditor' || role === 'auditor' ? (
                        <BackgroundCheckGraphReport dashboardResult={dashboardResult} auditor={auditor} />
                    ) : (
                        <div className="h-[230px]">
                            <DisabledFeatureMsg />
                        </div>
                    )}
                </div>
            )}
            {indexValue === 3 && (
                <div className="p-4 h-[295px]">
                    <div className="flex items-center">
                        <img src={GroupEmployeeIcon} alt="GroupEmployeeIcon" />
                        <div className="ml-3">
                            <p className="!mb-0 text-lg textbrandColor">{t('employeeReportSection.allEmployees')}</p>
                            <h3 className="font-bold text-[24px] text-[#334D80]">
                                {auditor === 'auditor'
                                    ? dashboardResult?.employees_count
                                    : dashboardResult?.employee_count}
                            </h3>
                        </div>
                    </div>
                </div>
            )}
        </CardContainer>
    );
};

export default EmployeeReportSection;
