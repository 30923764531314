/* eslint-disable no-unused-vars */
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useState } from 'react';
import ViewExemptedScanDrawer from './ViewExemptedScanDrawer';

export default function ExceptionGroupView({ all_scan_exceptions }) {
    return (
        <div className="mt-[1.6rem] bg-white rounded p-6 w-full max-w-[954px] mx-auto">
            {all_scan_exceptions?.length === 0 ? (
                <div className="py-8 justify-center items-center flex flex-col gap-4">
                    <img
                        src="/img/automated-scan/no-scan-instance-icon.svg"
                        alt="No result Icon"
                        className="object-contain"
                    />

                    <p className="text-[#475569] max-w-[400px] text-center font-semibold text-lg">
                        No scan instance has been exempted from Automated Scans
                    </p>
                </div>
            ) : (
                all_scan_exceptions?.map((group) => <RenderExemptedScanGroup key={group?.category} group={group} />)
            )}
        </div>
    );
}

function RenderExemptedScanGroup({ group }) {
    const [expandedAccordion, setExpandedAccordion] = useState(false);

    const handleAccordionChange = (groupCategory) => (event, isAccordionExpanded) => {
        setExpandedAccordion(isAccordionExpanded ? groupCategory : false);
    };

    const totalExemptedScans = group?.subcategory
        ?.filter((sub) => sub?.account && Object.keys(sub?.account).length > 0)
        .reduce((acc, sub) => {
            const scans = Object.keys(sub?.account).flatMap((scan) => sub?.account[scan]);
            return acc + scans.length;
        }, 0);

    return (
        <Accordion
            expanded={expandedAccordion === group?.category}
            onChange={handleAccordionChange(group?.category)}
            className="!shadow-none !border-b !border-[#F1F5F9] [&.css-9mbo5h-MuiPaper-root-MuiAccordion-root.Mui-expanded]:!my-0 before:hidden !rounded-none"
        >
            <AccordionSummary
                expandIcon={<></>}
                aria-controls={`${group?.category}-content`}
                id={`${group?.category}header`}
                className={`!px-0 [&>.MuiAccordionSummary-content]:!my-0 !min-h-fit transition-colors ${
                    expandedAccordion === group?.category && '!bg-white'
                }`}
            >
                <div className="flex py-2 items-center justify-between w-full">
                    <div className="flex items-center gap-6">
                        <h5 className="text-[#395BA9] font-semibold text-[15px] py-2.5 capitalize">
                            {group?.category}
                        </h5>

                        <div className="border border-[#E2E8F0] flex items-center gap-1 px-2 py-1 rounded">
                            <div>
                                <div className="w-[8px] h-[8px] rounded-full bg-[#94A3B8]" />
                            </div>

                            <p className="text-[#64748B] font-medium text-sm">{totalExemptedScans} exempted</p>
                        </div>
                    </div>

                    <div>
                        <img
                            src="/img/automated-scan/arrow-right-exception-icon.svg"
                            alt="Arrow Icon"
                            className={`object-contain transition-transform ${
                                expandedAccordion === group?.category && 'transform rotate-90'
                            }`}
                        />
                    </div>
                </div>
            </AccordionSummary>

            <AccordionDetails className="!px-0 !py-0">
                <div className="flex flex-col">
                    {group?.subcategory
                        ?.filter((sub) => sub?.account && Object.keys(sub?.account).length > 0)
                        .map((subItem) => (
                            <RenderExemptedScanSubItem key={subItem?.name} subItem={subItem} />
                        ))}
                </div>
            </AccordionDetails>
        </Accordion>
    );
}

function RenderExemptedScanSubItem({ subItem }) {
    const [isViewExemptedScanDrawerOpen, setIsViewExemptedScanDrawerOpen] = useState(false);

    const scans = Object.keys(subItem?.account).flatMap((scan) => subItem?.account[scan]);

    return (
        <>
            <div className="py-2 px-6 flex items-start justify-between">
                <div className="flex items-start gap-2">
                    <div className="pt-[5px]">
                        <div className="bg-[#94A3B8] rounded-full h-[10px] w-[10px]" />
                    </div>

                    <div className="flex flex-col">
                        <p className="text-[#202D66] font-medium text-sm capitalize">
                            {subItem?.name?.replace(/_/g, ' ')}
                        </p>

                        <p className="py-[2px] text-[#395BA9] font-medium text-[13px]">{scans?.length} exempted</p>
                    </div>
                </div>

                <button
                    className="border border-[#E2E8F0] hover:brightness-90 transition-all py-1 px-2 text-[#395BA9] font-medium text-sm bg-white rounded"
                    onClick={() => setIsViewExemptedScanDrawerOpen(true)}
                >
                    View
                </button>
            </div>

            <ViewExemptedScanDrawer
                open={isViewExemptedScanDrawerOpen}
                onClose={() => setIsViewExemptedScanDrawerOpen(false)}
                sub={subItem}
            />
        </>
    );
}
