import React, { useEffect, useState } from 'react';
import { Modal, Row, Select, Col, Button, DatePicker, Checkbox } from 'antd';

//redux
import { connect } from 'react-redux';
import { UpdateMerchantRecords } from 'store/actions/adminActions';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import { AVAILABLE_STANDARDS } from 'utils';
import { removeSpaces } from 'utils';

const { Option } = Select;
const AssignPlanModal = (props) => {
    const { open, handleClose, merchant, all_plans, UpdateMerchantRecords } = props;
    const [data, setData] = useState({});
    const [loading, setloading] = useState(false);
    const [standards, setStandards] = useState([]);

    const closeAll = () => {
        setData({});
        handleClose();
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setloading(true);
        const paid_standards = standards.join(',');
        const res = await UpdateMerchantRecords(merchant?.id, {
            ...data,
            paid_standards,
            standards: paid_standards.toLowerCase(),
        });
        setloading(false);
        if (res?.success) {
            toast.success(res?.message);
            closeAll();
        } else {
            toast.error(res?.message);
        }
    };
    const handleDateChange = (name, string) => {
        setData({ ...data, [name]: dayjs(string).format() });
    };
    const handleStandardChange = (event) => {
        const { name } = event.target;
        ``;
        const newStandards = [...standards];
        const indexOfStandard = newStandards.indexOf(name);
        if (indexOfStandard < 0) {
            newStandards.push(name);
        } else {
            newStandards.splice(indexOfStandard, 1);
        }
        setStandards(newStandards);
    };

    const handleCheckChange = (event) => {
        setData({ ...data, reset_plan: event.target.checked });
    };

    useEffect(() => {
        if (merchant?.plan) {
            const currentPlan = all_plans?.find((plan) => plan?.id === merchant?.plan);
            setData({
                plan: currentPlan?.id,
                plan_start_date: merchant?.plan_start_date,
                plan_end_date: merchant?.plan_end_date,
                reset_plan: false,
            });
        }

        if (merchant?.paid_standards) {
            const paidStandards = removeSpaces(merchant?.paid_standards)?.split(',');
            setStandards(paidStandards);
        }
    }, [merchant, open]);
    console.log({ merchant });

    console.log({ data });

    return (
        <Modal title={'Select Plan to Assign'} open={open} onCancel={closeAll} footer={null} destroyOnClose={true}>
            <form onSubmit={handleSubmit}>
                <Row justify="end">
                    <Col span={24} className="my-2" style={{ marginBottom: '1rem' }}>
                        <label htmlFor="plan">Assign Plan</label>
                        <Select
                            size="large"
                            name="plan"
                            id="plan"
                            onChange={(value) => setData({ ...data, plan: value })}
                            value={data?.plan || ''}
                            required
                            style={{ width: '100%' }}
                        >
                            <Option value="">Select Plan</Option>
                            {all_plans
                                ?.filter((plan) => plan?.tag !== 'custom')
                                ?.map((plan) => {
                                    return (
                                        <Option value={plan?.id} key={plan?.id}>
                                            {plan?.name}
                                        </Option>
                                    );
                                })}
                        </Select>
                    </Col>
                    <Col span={24}>
                        <label className="block">Select required Compliance Standards:</label>
                        {AVAILABLE_STANDARDS.map((standard) => {
                            return (
                                <Checkbox
                                    name={standard}
                                    onChange={handleStandardChange}
                                    key={standard}
                                    className="ml-0 mr-2 mb-1"
                                    checked={standards.includes(standard)}
                                >
                                    {standard}
                                </Checkbox>
                            );
                        })}
                    </Col>
                    <Col span={24} className="my-2">
                        <label htmlFor="plan_start_date">Plan Start Date</label>
                        <DatePicker
                            onChange={(_, str) => handleDateChange('plan_start_date', str)}
                            size="large"
                            name="plan_start_date"
                            id="plan_start_date"
                            required
                            style={{ width: '100%' }}
                            value={data?.plan_start_date ? dayjs(data?.plan_start_date) : null}
                        />
                    </Col>
                    <Col span={24} className="my-2">
                        <label htmlFor="plan_end_date">Plan End Date</label>
                        <DatePicker
                            onChange={(_, str) => handleDateChange('plan_end_date', str)}
                            size="large"
                            name="plan_end_date"
                            id="plan_end_date"
                            required
                            style={{ width: '100%' }}
                            value={data?.plan_end_date ? dayjs(data?.plan_end_date) : null}
                        />
                    </Col>
                    {merchant?.plan && (
                        <Col span={24} className="my-2">
                            <Checkbox
                                name="required"
                                size="large"
                                value={data?.reset_plan}
                                checked={data?.reset_plan}
                                onChange={handleCheckChange}
                            >
                                Reset Plan
                            </Checkbox>
                        </Col>
                    )}
                </Row>
                <Row justify="end" className="mt-3">
                    <Col>
                        <Button className="ant-btn" htmlType="submit" type="primary" loading={loading}>
                            Submit
                        </Button>
                    </Col>
                </Row>
            </form>
        </Modal>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { UpdateMerchantRecords })(AssignPlanModal);
