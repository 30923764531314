export const formatComma = (num) => {
    const numArray = num?.toString().split('');
    let strVal = '';
    while (numArray.length) {
        const threeVal = numArray.splice(-3).join('');
        strVal = (numArray.length ? ',' : '') + threeVal + strVal;
    }
    return strVal;
};

export const paymentOptionDisabled = (totalCost, minCost) => {
    return totalCost < minCost;
};

export const generatePaymentNote = (method, employeeCount) => {
    const first_percent = JSON.parse(method?.first_percent) || '';
    return method?.name?.toLowerCase().includes('standard') ? (
        <>
            <span style={{ fontWeight: 700 }}>{method?.first_percent}%</span> payment before commencement and{' '}
            <span style={{ fontWeight: 700 }}>{method?.second_percent}%</span> payment after completion.
        </>
    ) : (
        <>
            <span style={{ fontWeight: 700 }}>
                {(parseInt(employeeCount) || 0) <= method?.company_size
                    ? first_percent?.smallCompany
                    : first_percent?.largeCompany}
                %
            </span>{' '}
            payment before commencement and{' '}
            <span style={{ fontWeight: 700 }}>the remaining will be spread to be paid on a monthly basis.</span>
        </>
    );
};
