/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AddMerchantPeople, UpdateMerchantPerson } from 'store/actions/merchantActions';
import { GetKycResults } from 'store/actions/FraudGauardianActions';

// Core components
import { Box, List, ListItem, Typography, Stack, Drawer } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AppTag from 'components/new_components/AppTags';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { ArrowBackIosNew } from '@mui/icons-material';
import AppSelectInput from 'components/new_components/AppSelectInput';
import moment from 'moment';

const EmployeeAccessDrawer = (props) => {
    // State
    const [value, setValue] = useState(0);
    const [current_value, setCurrrent_value] = useState('');
    const [loading, setloading] = useState(false);

    //props
    const { open, handleClose, width, payload, kyc_result } = props;

    // console.log('result', kyc_result);

    const theme = useTheme();

    // Function
    const CustomTabPanel = (props) => {
        const { children, value, index, ...other } = props;
        return (
            <Typography
                component="div"
                role="tabpanel"
                hidden={value !== index}
                id={`simple-tabpanel-${index}`}
                aria-labelledby={`simple-tab-${index}`}
                {...other}
            >
                {value === index && <Box>{children}</Box>}
            </Typography>
        );
    };

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    };

    const handleDetailsChange = (e) => {
        setCurrrent_value(e.target.value);
    };

    const match_options = kyc_result?.[0]?.result?.map((data) => {
        return {
            name: data?.doc?.name,
            value: data?.doc?.id,
        };
    });

    const current_fraud_details = kyc_result?.[0]?.result?.find((current) => current?.doc?.id === current_value);

    return (
        <Drawer
            open={open}
            anchor={'right'}
            transitionDuration={1000}
            keepMounted
            onClose={handleClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 370,
                    borderRadius: 2,
                    width: width,
                    flex: 1,
                    overflow: 'auto',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
            width={width}
            sx={{ borderRadius: '4px', color: '#46464A' }}
        >
            <Box
                sx={{
                    borderBottom: `1px solid ${theme.palette.primary[100]}`,
                }}
            >
                <Stack
                    sx={{
                        fontSize: 18,
                        fontWeight: 700,
                        color: theme.palette.primary[900],
                        padding: 2,
                        width: '100%',
                    }}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <ArrowBackIosNew
                            sx={{
                                width: 20,
                                height: 20,
                                color: theme.palette.gray[30],
                                mr: 3,
                                borderRadius: '50%',
                                cursor: 'pointer',
                            }}
                            onClick={handleClose}
                        />
                        <Typography
                            variant="h2"
                            sx={{
                                color: '#46464A',
                                fontWeight: 700,
                                fontSize: '16px',
                                marginTop: 1,
                            }}
                        >
                            {kyc_result?.[0]?.name}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography
                            sx={{ fontWeight: 500, fontSize: '10px', color: theme.palette.gray[40], float: 'right' }}
                        >
                            {kyc_result?.[0]?.total_hits} search hits
                        </Typography>

                        <AppSelectInput
                            name="Search hits"
                            options={match_options}
                            defaultValue={kyc_result?.[0]?.name}
                            sx={{ borderRadius: '8px', fontSize: 12, mt: '-1rem' }}
                            value={current_value}
                            onChange={handleDetailsChange}
                            size="small"
                        />
                    </Box>
                </Stack>
            </Box>
            <Box
                sx={{
                    width: '96%',
                    mx: 'auto',
                    marginBottom: '1.5rem',
                    // height: remainingHeight,
                    flex: '1 1 auto',
                    overflowY: 'auto',
                }}
            >
                <Typography
                    sx={{
                        fontSize: '12px',
                        color: '#5E5E62',
                        marginTop: '1.5rem',
                        marginLeft: '15px',
                        fontWeight: '600',
                    }}
                >
                    Entity details
                </Typography>
                <List sx={{ marginTop: '1rem' }}>
                    <ListItem
                        sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #F0F0FA' }}
                    >
                        <Typography sx={{ fontSize: '12px', color: '#919094', fontWeight: '500' }}>Name</Typography>
                        <Typography
                            sx={{ fontSize: '12px', color: '#5E5E62', fontWeight: '500' }}
                        >{`${kyc_result?.[0]?.name}`}</Typography>
                    </ListItem>
                    <ListItem
                        sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #F0F0FA' }}
                    >
                        <Typography sx={{ fontSize: '12px', color: '#919094', fontWeight: '500' }}>
                            Entity type
                        </Typography>
                        <Typography
                            sx={{ fontSize: '12px', color: '#5E5E62', fontWeight: '500' }}
                        >{`${kyc_result?.[0]?.entity_type}`}</Typography>
                    </ListItem>
                    <ListItem
                        sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #F0F0FA' }}
                    >
                        <Typography sx={{ fontSize: '12px', color: '#919094', fontWeight: '500' }}>
                            Birth Year
                        </Typography>
                        <Typography sx={{ fontSize: '12px', color: '#5E5E62', fontWeight: '500' }}>
                            {/* 04/10/1995 */}
                            {kyc_result?.[0]?.request_details?.birthyear}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #F0F0FA' }}
                    >
                        <Typography sx={{ fontSize: '12px', color: '#919094', fontWeight: '500' }}>Country</Typography>
                        <Typography sx={{ fontSize: '12px', color: '#5E5E62', fontWeight: '500' }}>
                            {kyc_result?.[0]?.request_details?.country}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #F0F0FA' }}
                    >
                        <Typography sx={{ fontSize: '12px', color: '#919094', fontWeight: '500' }}>
                            Date Created
                        </Typography>
                        <Typography sx={{ fontSize: '12px', color: '#5E5E62', fontWeight: '500' }}>
                            {new Date(kyc_result?.[0]?.date_created).toLocaleDateString()}
                        </Typography>
                    </ListItem>
                    <ListItem
                        sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #F0F0FA' }}
                    >
                        <Typography sx={{ fontSize: '12px', color: '#919094', fontWeight: '500' }}>
                            Date Updated
                        </Typography>
                        <Typography sx={{ fontSize: '12px', color: '#5E5E62', fontWeight: '500' }}>
                            {new Date(kyc_result?.[0]?.date_updated).toLocaleDateString()}
                        </Typography>
                    </ListItem>
                </List>
                <Box>
                    <Typography
                        sx={{
                            marginTop: '2rem',
                            marginLeft: '15px',
                            color: '#5E5E62',
                            fontSize: '12px',
                            fontWeight: '600',
                        }}
                    >
                        Entity search criteria
                    </Typography>
                    <List sx={{ marginTop: '1rem' }}>
                        <ListItem
                            sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid #F0F0FA' }}
                        >
                            <Typography sx={{ fontSize: '12px', color: '#919094', fontWeight: '500' }}>
                                Fuziness
                            </Typography>
                            <Typography
                                sx={{ fontSize: '12px', color: '#5E5E62', fontWeight: '500' }}
                            >{`${kyc_result?.[0]?.request_details?.fuziness}`}</Typography>
                        </ListItem>
                        <ListItem sx={{ borderBottom: '1px solid #F0F0FA' }}>
                            <Stack sx={{ direction: 'column' }}>
                                <Typography sx={{ fontSize: '12px', color: '#919094', fontWeight: '500' }}>
                                    KYC types
                                </Typography>
                                <Box sx={{ display: 'flex', gap: '3px', flexWrap: 'wrap' }}>
                                    {(current_value
                                        ? current_fraud_details?.doc?.types
                                        : kyc_result?.[0]?.request_details?.kyc_types
                                    )?.map((type) => {
                                        return (
                                            <Box key={type}>
                                                <AppTag
                                                    text={type}
                                                    type={payload?.security_check}
                                                    hasIcon={true}
                                                    sx={{
                                                        borderColor: `${theme.palette.error[500]}`,
                                                        color: `${theme.palette.error[500]}`,
                                                        borderRadius: '4px',
                                                        fontSize: '10px',
                                                        width: 'fit-content',
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Stack>
                        </ListItem>
                    </List>
                </Box>
                <Box sx={{ marginTop: '2.5rem' }}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                                <Tab label="Online Sources" {...a11yProps(0)} />
                                {/* <Tab label="Item Two" {...a11yProps(1)} />
                                <Tab label="Item Three" {...a11yProps(2)} /> */}
                            </Tabs>
                        </Box>
                        <CustomTabPanel value={value} index={0}>
                            <Box sx={{ overflowY: 'scroll' }}>
                                {current_fraud_details?.doc?.media?.map((source, index) => (
                                    <Box
                                        key={index}
                                        sx={{
                                            width: '96%',
                                            marginTop: '1rem',
                                            mx: 'auto',
                                            border: '1px solid #F0F0FA',
                                            borderRadius: '4px',
                                            padding: '8px',
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '12px',
                                                fontWeight: '600',
                                                color: '#5E5E62',
                                                textDecoration: 'underline',
                                            }}
                                        >
                                            {source?.title}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '10px',
                                                fontWeight: '500',
                                                color: '#919094',
                                                marginTop: '6px',
                                            }}
                                        >
                                            Published {moment(source.date).format('Do MMMM, YYYY')}
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontSize: '10px',
                                                fontWeight: 500,
                                                color: theme.palette.gray[30],
                                                mt: 0.5,
                                            }}
                                        >
                                            <a href={source?.url} target="_blank" rel="noreferrer">
                                                {source?.url}
                                            </a>
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        </CustomTabPanel>
                    </Box>
                </Box>
            </Box>
        </Drawer>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
        kyc_result: state?.fraudGuardianReducers?.kyc_results,
    };
};

export default connect(mapStateToProps, {
    AddMerchantPeople,
    UpdateMerchantPerson,
    GetKycResults,
})(EmployeeAccessDrawer);
