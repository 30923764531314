import React, { useContext, useEffect } from 'react';
import StandardCard from './dashboardComponents/StandardCard';
// import AutomatedScanCard from './dashboardComponents/AutomatedScanCard';
import RiskAssessmentSection from 'components/Dashboard/AllReport/thirdSection/RiskAssessmentSection';
import EmployeeReportSection from 'components/Dashboard/AllReport/thirdSection/EmployeeReportSection';
// import VendorReportSection from 'components/Dashboard/AllReport/fourthSection/VendorReportSection';
//redux
import { useDispatch, useSelector } from 'react-redux';
import { GetAuditorDashboardResult } from 'store/actions/dashboardActions';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';
import { toast } from 'react-toastify';

const NewDashboard = () => {
    const { activeMerchant, merchantStandards } = useContext(SelectedMerchantContext);

    const dispatch = useDispatch();

    const user_type = useSelector((state) => state.authReducers?.user_type);
    const merchant = useSelector((state) => state?.authReducers?.company_details?.id);
    const pricing_plan = useSelector((state) => state?.authReducers.pricing_plan);
    const auditor = useSelector((state) => state?.generalReducers?.user_info?.organization?.id);
    const auditorStandard = useSelector((state) => state.generalReducers?.user_info?.employee?.auditor_standards);
    const auditor_dashboard_result = useSelector((state) => state?.dashboardReducers?.auditor_dashboard_result);

    useEffect(() => {
        const getAuditorDashboardResult = async () => {
            const res = await dispatch(GetAuditorDashboardResult(user_type === 'auditor' ? activeMerchant : merchant));
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getAuditorDashboardResult();
    }, [activeMerchant]);

    console.log({ activeMerchant, auditor });
    const auditorStandards = auditorStandard?.split(',');
    return (
        <div className="py-4 w-full">
            <div className="flex flex-col md:flex-row gap-4 w-full md:w-4/5">
                <StandardCard merchantStandards={user_type === 'auditor' ? merchantStandards : auditorStandards} />
                {/* <AutomatedScanCard /> */}
            </div>
            <div className="flex flex-col md:flex-row gap-4 w-full pt-10 pb-5 md:w-[98%] overflow-y-hidden">
                <div className="w-1/2">
                    <RiskAssessmentSection
                        dashboardResult={auditor_dashboard_result?.risk_assessment}
                        pricing_plan={pricing_plan}
                        auditor={'auditor'}
                    />
                </div>
                <div className="w-1/2">
                    <EmployeeReportSection
                        cardStyle="w-full"
                        dashboardResult={auditor_dashboard_result?.employee_update}
                        pricing_plan={pricing_plan}
                        auditor={'auditor'}
                    />
                </div>
            </div>
            {/* <div className="w-full md:w-[48%]">
                <VendorReportSection dashboardResult={auditor_dashboard_result?.vendor_update} />
            </div> */}
        </div>
    );
};

export default NewDashboard;
