import {
    Box,
    Button,
    MenuItem,
    // MenuList, Paper,
    Menu,
    useTheme,
    Typography,
    Divider,
} from '@mui/material';
import { useLocation, useHistory } from 'react-router-dom';
import SwitchAccessShortcutIcon from '@mui/icons-material/SwitchAccessShortcut';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Bell from 'assets/img/Bell.svg';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { LogoutUser } from 'store/actions/authActions';
// import Settings from 'assets/img/CogOutline.svg';
import Logout from 'assets/img/logout.svg';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import AppClientModal from 'components/new_components/AppClientModal';
import Popover from '@mui/material/Popover';
import SearchIcon from 'assets/img/Searchicon.svg';
import { GetRegisteredClients } from 'store/actions/ImplementerAction';
import { toast } from 'react-toastify';
import { ClientId } from 'store/actions/ImplementerAction';

function Header(props) {
    // props
    const { LogoutUser } = props;

    // States
    const [openModal, setOpenModal] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(null);
    const [openSwitch, setOpenSwitch] = useState(null);
    const [search, setSearch] = useState('');

    // mui
    const theme = useTheme();

    // react router
    const history = useHistory();
    const location = useLocation();

    // Redux
    const dispatch = useDispatch();
    const registered_clients = useSelector((state) => state?.implementerReducer?.registered_clients);

    const user_info = useSelector((state) => state?.generalReducers?.user_info);
    // functions
    const showSwitch = (event) => {
        setOpenSwitch(event.currentTarget);
    };

    const hideSwitch = () => {
        setOpenSwitch(null);
    };

    const handleMenuItemClick = (event, selected) => {
        dispatch(ClientId(selected?.id));
        history.push('/merchant/compliance/audits');
        setOpenSwitch(null);
    };
    const open = Boolean(openSwitch);
    const id = open ? 'simple-popover' : undefined;

    const showDropdown = (event) => {
        setDropdownVisible(event.target);
    };

    const handleOpen = () => {
        setOpenModal(true);
    };
    const handleClose = () => {
        setOpenModal(false);
    };
    const hideDropdown = () => setDropdownVisible(null);

    const logout = async () => {
        await LogoutUser();
        hideDropdown();
        history.push('/');
    };
    var getInitials = function (string) {
        var names = string.split(' '),
            initials = names[0].substring(0, 1)?.toUpperCase();

        if (names.length > 1) {
            initials += names[names.length - 1].substring(0, 1)?.toUpperCase();
        }
        return initials;
    };

    // Async
    useEffect(() => {
        const getRegisteredClients = async () => {
            const res = await dispatch(GetRegisteredClients());
            if (!res?.status) {
                toast.error(res?.message);
            }
        };

        getRegisteredClients();
    }, []);

    const filteredClients = registered_clients?.filter((client) =>
        client.name.toLowerCase().includes(search.toLowerCase())
    );
    return (
        <div className="bg-white flex justify-between items-center h-[70px] px-6">
            <h3 className="text-2xl font-bold text-[#002C72]">
                {location.pathname === '/implementer/clients' ? 'Clients' : 'Dashboard'}
            </h3>
            <div className="flex gap-3 items-center">
                <Button
                    variant="outlined"
                    endIcon={<SwitchAccessShortcutIcon />}
                    sx={{
                        textTransform: 'inherit',
                        color: '#395BA9',
                        fontSize: '14px',
                        fontWeight: 600,
                        border: '1px solid #E2E8F0',
                    }}
                    aria-describedby={id}
                    onClick={showSwitch}
                >
                    Switch to your client Board
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={openSwitch}
                    onClose={hideSwitch}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        style: {
                            width: 240,
                            minHeight: 100,
                            maxHeight: 400,
                            overflow: 'scroll',
                            padding: '10px',
                        },
                    }}
                >
                    <Typography sx={{ color: theme.palette.neutral[500], fontSize: '13px', fontWeight: 500, mb: 1 }}>
                        Switch to client:
                    </Typography>
                    <div className="relative">
                        <input
                            value={search}
                            onChange={(event) => setSearch(event?.target?.value)}
                            className="border border-[#E2E8F0] rounded pl-4 text-[#94A3B8] text-sm font-normal w-full py-1 outline-none"
                            placeholder="search"
                        />
                        <img src={SearchIcon} alt="SearchIcon" className="absolute top-2 left-1" />
                    </div>
                    {filteredClients?.length ? (
                        <div className="my-2">
                            {filteredClients?.map((client) => (
                                <MenuItem
                                    key={client.id}
                                    sx={{
                                        color: '#475569',
                                        fontWeight: 'medium',
                                        fontSize: '14px',
                                        mx: 0,
                                        py: 0.5,
                                        my: 0.5,
                                        '&:hover': {
                                            backgroundColor: '#F1F5F9',
                                        },
                                    }}
                                    onClick={(event) => handleMenuItemClick(event, client)}
                                >
                                    {client.name.substring(0, 40)}
                                    {client.name?.length > 40 ? '...' : ''}
                                </MenuItem>
                            ))}
                        </div>
                    ) : (
                        <p className="flex justify-center items-center my-3 text-[#94A3B8] text-sm font-normal">
                            No Client found
                        </p>
                    )}
                </Popover>
                <div className="flex items-center gap-2">
                    <img src={Bell} alt="Notification Icon" />
                    <AccountCircleOutlinedIcon sx={{ color: '#64748B' }} />
                    <Box component="button" onClick={showDropdown} sx={{ cursor: 'pointer' }}>
                        <KeyboardArrowDownOutlinedIcon sx={{ cursor: 'pointer', color: '#002C72' }} />
                    </Box>
                    <Menu
                        sx={{ p: 0 }}
                        open={Boolean(dropdownVisible)}
                        anchorEl={dropdownVisible}
                        onClose={hideDropdown}
                        PaperProps={{
                            style: {
                                width: 250,
                            },
                        }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem
                            sx={{
                                m: 0,
                                backgroundColor: '#F1F5F9',
                                '&:hover': {
                                    backgroundColor: '#F1F5F9',
                                },
                            }}
                        >
                            <div className="flex gap-2 items-center bg-[#F1F5F9]">
                                <div className="flex justify-center items-center bg-[#64748B] h-[40px] w-[40px] rounded-full text-white text-base font-semibold">
                                    {getInitials(`${user_info?.first_name} ${user_info?.last_name}`) || 'N/A'}
                                </div>
                                <Box sx={{ wordBreak: 'break-word !important', width: '10px !important' }}>
                                    <Typography
                                        sx={{ color: theme.palette.primary[40], fontSize: '14px', fontWeight: 500 }}
                                    >{`${user_info?.first_name} ${user_info?.last_name}`}</Typography>
                                    <Typography
                                        sx={{
                                            color: '#94A3B8',
                                            fontSize: '11px',
                                            fontWeight: 500,
                                            wordBreak: 'break-word !important',
                                            width: '10px !important',
                                        }}
                                    >
                                        {user_info?.email}
                                    </Typography>
                                </Box>
                            </div>
                        </MenuItem>
                        <MenuItem
                            sx={{
                                color: '#475569',
                                fontSize: '14px',
                                fontWeight: 500,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                m: 0,
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            onClick={() => {
                                handleOpen();
                                hideDropdown();
                            }}
                        >
                            <span>Register Client</span> <AddOutlinedIcon sx={{ width: 16, height: 16 }} />
                        </MenuItem>
                        <Divider sx={{ color: '#F0F0FA' }} />
                        <MenuItem
                            sx={{
                                color: '#475569',
                                fontSize: '14px',
                                fontWeight: 500,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                m: 0,
                                '&:hover': {
                                    backgroundColor: 'transparent',
                                },
                            }}
                            onClick={showSwitch}
                        >
                            <span>Switch to my client board</span>{' '}
                            <SwitchAccessShortcutIcon sx={{ width: 16, height: 16 }} />
                        </MenuItem>
                        <Divider sx={{ color: '#F0F0FA' }} />
                        {/* <MenuItem
                            onClick={() => {
                                hideDropdown();
                            }}
                            sx={{
                                color: '#64748B',
                                fontSize: '13px',
                                fontWeight: 500,
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                                m: 0,
                                '&:hover': {
                                    backgroundColor: '#F1F5F9',
                                },
                            }}
                        >
                            <img src={Settings} alt="settings icons" />
                            <span>Settings</span>
                        </MenuItem> */}
                        <MenuItem
                            color={theme.palette.error[700]}
                            sx={{
                                color: '#64748B',
                                fontSize: '13px',
                                fontWeight: 500,
                                display: 'flex',
                                gap: 1,
                                alignItems: 'center',
                                m: 0,
                                '&:hover': {
                                    backgroundColor: '#F1F5F9',
                                },
                            }}
                            onClick={logout}
                        >
                            <img src={Logout} alt="logout icon" /> <span>Logout</span>
                        </MenuItem>
                    </Menu>
                </div>
            </div>
            <AppClientModal open={openModal} handleClose={handleClose} />
        </div>
    );
}

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { LogoutUser })(Header);
