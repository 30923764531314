import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// core components
import AppTable from 'components/new_components/app-table';
import ActivitiesTableActions from './ActivitiesTableActions';
import ActivitiesTableFilter from './ActivitiesTableFilter';
import PageHeader from 'components/new_components/PageHeader';

// redux
import { connect } from 'react-redux';
import { GetAllActivities } from 'store/actions/merchantActions';

//utils
import { getNoOfPages } from '../utils/constants';
import { formatDateObjectHandler, GetFromNowDate } from 'utils';

import { useTranslation } from 'react-i18next';

const Activities = (props) => {
    const theme = useTheme();
    //transaltion
    const { t } = useTranslation('activities');

    const { GetAllActivities, all_activities, total_activities } = props;

    // state
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState({ fetching: false, updating: false });
    const [filters, setFilters] = useState({
        start: '',
        end: '',
    });
    const [activities, setActivities] = useState([]);
    // functions
    // logic functions
    const handlePaginationChange = (page) => {
        setPage(page);
    };
    const handleDateFilterInput = (name, date) => {
        setFilters((current) => ({
            ...current,
            [name]: formatDateObjectHandler(date?.$d) || '',
        }));
    };

    const handleFiltering = (e) => {
        e.preventDefault();
        getAllActivities(filters, 1, 'updating');
        setPage(1);
    };
    // async
    const getAllActivities = async (filters, page, loadingState) => {
        setLoading((current) => ({ ...current, [loadingState]: true }));
        const res = await GetAllActivities({ ...filters, page });
        setLoading((current) => ({ ...current, [loadingState]: false }));
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    // constants
    const columns = [
        { title: t('table.columns.column1'), render: (row) => <>{row?.name}</> },
        {
            title: t('table.columns.column2'),
            render: (row) => <>{row.activity}</>,
        },
        {
            title: t('table.columns.column3'),
            render: (row) => (
                <>
                    {row.date}
                    <Box
                        component="span"
                        sx={{
                            color: theme.palette.gray[500],
                            ml: 0.2,
                            fontSize: '0.9rem',
                            fontWeight: 400,
                        }}
                    >
                        ({row.time_elapsed})
                    </Box>
                </>
            ),
        },
        { title: t('table.columns.column4'), render: (row) => <>{row.ip}</> },
        {
            title: t('table.columns.column5'),
            render: (row) => <ActivitiesTableActions activity={row} />,
        },
    ];

    // useEffect
    useEffect(() => {
        const loadingState = activities.length ? 'updating' : 'fetching';
        getAllActivities(filters, page, loadingState);
    }, [page]);

    useEffect(() => {
        if (all_activities) {
            const activities = all_activities.map((log) => {
                const { detail, user, id, ip, date_created } = log;
                const activity =
                    JSON.parse(detail).message === 'updated a employee'
                        ? 'updated an employee'
                        : JSON.parse(detail).message;
                const name = `${user?.first_name} ${user?.last_name}`;
                const date = formatDateObjectHandler(date_created, 'MMMM Do, YYYY');
                const time_elapsed = GetFromNowDate(date_created, 'YYYY-MM-DDTHH:mm:ss', 'fromNow');
                const email = user?.email;

                return { activity, name, date, time_elapsed, id, ip, detail, email };
            });
            setActivities(activities);
        }
    }, [all_activities]);

    return (
        <>
            <PageHeader browserTitle="Activities | Smartcomply" />
            <AppTable
                columns={columns}
                data={activities}
                // title={t('table.title')}
                actions={
                    <ActivitiesTableFilter
                        handleInput={handleDateFilterInput}
                        handleFiltering={handleFiltering}
                        filters={filters}
                    />
                }
                loading={loading.fetching}
                noOfPages={getNoOfPages(total_activities)}
                page={page}
                onPageChange={handlePaginationChange}
                dataLength={total_activities}
                noPerPage={10}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_activities: state?.merchantReducers?.all_activities?.results,
        total_activities: state?.merchantReducers?.all_activities?.count,
    };
};
export default connect(mapStateToProps, { GetAllActivities })(Activities);
