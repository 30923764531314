/* eslint-disable no-unused-vars */
import { Box, Typography } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppForm, AppFormInput, AppSubmitButton } from 'components/new_components/forms';
import moment from 'moment';
import { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { CreateScanException } from 'store/actions/automatedScansActions';
import { accept } from 'validate';
import { exemptionModalValidation } from '../../utils/validation';

const ExemptScanModal = (props) => {
    const { pdf, docx, images } = accept;
    const { open, onClose, scan, sub, CreateScanException } = props;

    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState('');

    const fileInputRef = useRef(null);

    // / UPLOAD IMAGE ONCHANGE
    const uploadFile = async (event) => {
        const input = event.target;
        const file = input.files?.item(0);
        setFile(file);
    };

    const dragEnter = () => {
        const target = document.getElementById('drop-zone');
        target.style.backgroundColor = '#F1F5F9';
    };

    const dragLeave = () => {
        const target = document.getElementById('drop-zone');
        target.style.backgroundColor = 'transparent';
    };

    const dragOver = (e) => {
        e.preventDefault();

        const target = document.getElementById('drop-zone');
        target.style.backgroundColor = '#F1F5F9';
    };

    const dragDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        if (file && file.type.includes('image' || 'pdf' || 'docx')) {
            setFile(file);
        } else {
            toast.error('Invalid file type');
        }
        dragLeave();
    };

    const handleSubmit = async (values) => {
        const formdata = new FormData();
        formdata.append('reason', values?.reason);
        formdata.append('scan_name', scan?.scan_name);
        formdata.append('file', file);
        formdata.append('userintegration', scan?.userintegration);
        const payload = {
            reason: values?.reason,
            scan_name: scan?.scan_name,
            userintegration: scan?.userintegration,
        };
        const body = file !== '' ? formdata : payload;
        setLoading(true);
        const res = await CreateScanException(body, file, sub?.id, scan?.account_name);
        setLoading(false);
        if (res?.success) {
            toast.success('Scan successfully exempted');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <AppForm
            initialValues={{
                reason: '',
            }}
            validate={exemptionModalValidation}
            onSubmit={handleSubmit}
        >
            <AppCenteredModal
                open={open}
                handleClose={onClose}
                title={
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#202D66' }}>
                        Reason for exempting
                    </Typography>
                }
                headerAction={
                    <button
                        className="border-[#E2E8F0] border rounded-full p-1 h-6 w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                        onClick={onClose}
                    >
                        <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                    </button>
                }
                titleStyle={{ marginTop: '6px' }}
                width={540}
                noBorder
                maxHeight="unset"
            >
                <Box>
                    <AppFormInput type="text" name="reason" placeholder={'Why exempting?'} multiline rows={3} />

                    <Box sx={{ marginTop: '16px' }}>
                        <Typography sx={{ color: '#64748B', fontSize: '13px', fontWeight: 500 }}>
                            Any document/evidence to backup your claim (Optional)
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                gap: '8px',
                                border: '0.7px solid #C7C6CA',
                                borderRadius: '4px',
                                width: '100%',
                                height: '160px',
                                marginTop: '8px',
                            }}
                            id="drop-zone"
                            onDragEnter={dragEnter}
                            onDragLeave={dragLeave}
                            onDrop={dragDrop}
                            onDragOver={dragOver}
                            onClick={() => fileInputRef?.current?.click()}
                            className="cursor-pointer hover:bg-[#F1F5F9] transition-all"
                        >
                            <div>
                                <label htmlFor="file-input" style={{ cursor: 'pointer' }}>
                                    <img
                                        src="/img/automated-scan/folder-icon.svg"
                                        alt="File"
                                        className="object-contain"
                                    />
                                </label>

                                <input
                                    type="file"
                                    accept={pdf + ', ' + docx + ', ' + images}
                                    onChange={(e) => uploadFile(e)}
                                    hidden
                                    id="file-input"
                                    ref={fileInputRef}
                                />
                            </div>

                            <div className="flex flex-col text-center text-[#64748B]">
                                <p className="font-medium text-xs">Drop your files or click to upload</p>

                                <p className="font-normal text-xs w-[223px]">
                                    Supported file types: PDF, IMG, PNG, DOCX
                                </p>
                            </div>

                            <button className="border border-[#E2E8F0] px-2 py-1 rounded-sm text-[#475569] font-normal text-xs">
                                Browse
                            </button>
                        </Box>
                    </Box>

                    {file && (
                        <div className="mt-4 flex items-center justify-between">
                            <div className="flex items-center gap-2">
                                <img
                                    src="/img/automated-scan/file-icon.svg"
                                    alt="File Icon"
                                    className="object-contain scale-150"
                                />

                                <div className="flex flex-col">
                                    <p className="text-[#334155] text-sm capitalize">
                                        {file.name.slice(0, 45)}
                                        {file.name.length > 45 && '...'}
                                    </p>

                                    <div className="flex items-center gap-1 text-[#64748B] text-xs">
                                        <p>{moment(file.lastModified).format('DD MMM, YYYY')}</p>

                                        <div className="bg-[#64748B] w-1 h-1 rounded-full" />

                                        <p>{Math.round(Number(file.size) / 1024)} KB</p>
                                    </div>
                                </div>
                            </div>

                            <img
                                src="/img/automated-scan/delete-icon.svg"
                                alt="Delete Icon"
                                className="object-contain cursor-pointer"
                                onClick={() => setFile('')}
                            />
                        </div>
                    )}

                    <div className="px-3 py-2 rounded border border-[#F0F0FA] bg-[#F8FAFC] flex gap-2 mt-4 mb-6">
                        <img src="/img/automated-scan/bulb-icon.svg" alt="Bulb icon" className="object-contain" />

                        <Typography sx={{ fontSize: '13px', color: '#64748B', lineHeight: '19.5px' }}>
                            Exempted scan instances will not appear when a fresh scan is performed until you revert the
                            exemption.
                        </Typography>
                    </div>

                    <div className="bg-[#F8FAFC] flex items-center -mx-7 -mb-[1.6px] justify-end gap-2.5 p-6">
                        <button
                            onClick={onClose}
                            className="text-sm px-3 font-semibold py-[10px] rounded border border-[#E2E8F0] bg-white hover:brightness-90 transition-all"
                        >
                            Cancel
                        </button>
                        <AppSubmitButton
                            text={'Exempt'}
                            variant="contained"
                            color="primary"
                            loading={loading}
                            loadingPosition="center"
                        />
                    </div>
                </Box>
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        all_automated_scan_groups: state?.automatedScansReducers?.all_automated_scan_group,
    };
};

export default connect(mapStateToProps, { CreateScanException })(ExemptScanModal);
