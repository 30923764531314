import React, { useState } from 'react';
import { useTheme } from '@mui/styles';
import { Box, Stack } from '@mui/material';
//core components
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import AppButton from 'components/new_components/AppButton';
import AppLoadingButton from 'components/new_components/AppLoadingButton';

//redux
import { connect } from 'react-redux';
import { RequestASVscan } from 'store/actions/merchantActions';
import { toast } from 'react-toastify';

//translations
import { useTranslation } from 'react-i18next';

const RerunScanModal = ({ open, handleClose, title, subtitle, payload, RequestASVscan }) => {
    const [rerunloading, setRerunLoading] = useState(false);
    const theme = useTheme();

    //translation
    const { t } = useTranslation('pciscans');

    console.log('Payload', payload);

    const RerunScanRequest = async () => {
        setRerunLoading(true);
        const res = await RequestASVscan(payload);

        console.log('res', res);
        setRerunLoading(false);
        if (res?.success) {
            toast.success('Rerun Scan Request initiated');
            handleClose();
        } else {
            if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                toast.error(res?.message);
            }
        }
    };
    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            width={440}
            height={208}
            sx={{ overflow: 'hidden', borderRadius: 0 }}
            actions={
                <div className="w-[440px] h-[84px] bg-[#F8FAFC] -mb-[10px] flex items-center justify-center -ml-[25px]">
                    <div>
                        <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                            <AppButton
                                name={t('rerunScanModal.button1')}
                                variant="text"
                                sx={{ color: '#334155' }}
                                style={{
                                    border: '1px solid #E2E8F0',
                                    borderRadius: '2px',
                                    background: 'white',
                                    width: '83px',
                                    height: '36px',
                                    color: '#334155',
                                    fontWeight: 500,
                                    fontSize: '14px',
                                }}
                                onClick={handleClose}
                            />
                            <AppLoadingButton
                                text={t('rerunScanModal.button2')}
                                style={{
                                    width: '123px',
                                    height: '36px',
                                    boxShadow: 'none',
                                    borderRadius: '2px',
                                    color: 'white',
                                    background: '#202D66',
                                    fontSize: '14px',
                                    whiteSpace: 'nowrap',
                                }}
                                variant="contained"
                                loading={rerunloading}
                                loadingPosition="center"
                                onClick={RerunScanRequest}
                            />
                        </Stack>
                    </div>
                </div>
            }
        >
            <Box
                sx={{
                    fontWeight: 500,
                    color: theme.palette.gray[800],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                }}
            >
                {title && <span style={{ fontSize: '18px', fontWeight: 600, color: '#475569' }}>{title}</span>}
                {subtitle && (
                    <p style={{ fontSize: '13px', fontWeight: 400, marginTop: '1rem', color: '#64748B' }}>{subtitle}</p>
                )}
            </Box>
        </AppCenteredModal>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};
export default connect(mapStateToProps, { RequestASVscan })(RerunScanModal);
