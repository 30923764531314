import { Box, Button, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { ReactComponent as PlayIcon } from 'assets/img/icons/play-course.svg';
import AppTooltip from 'components/new_components/AppTooltip';
// import { COURSES } from '../data';
import { connect } from 'react-redux';

//translations
import { useTranslation } from 'react-i18next';

const CoursesNavigator = (props) => {
    const { current, onCourseChange, all_training_courses, avaliable_courses, ref1 } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('training');

    //This helps to filter out merchant paid courses
    const availableCourses = useMemo(() => {
        const paid_courses = avaliable_courses?.split(',');
        return all_training_courses?.filter((course) => paid_courses?.includes(course?.id));
    }, [avaliable_courses, all_training_courses]);

    // useEffect
    useEffect(() => {
        // This is safe to use in the dependency array because
        // the courses are populated from the admin side
        onCourseChange(availableCourses?.sort((a, b) => a?.title - b?.title)?.[0]?.id);
    }, [availableCourses]);

    return (
        <Box
            component="nav"
            sx={{
                width: { md: 400 },
                flexShrink: { md: 0 },
                height: '100%',
                backgroundColor: theme.palette.white.main,
            }}
        >
            <Typography
                component="h2"
                sx={{ color: theme.palette.gray[30], fontSize: 16, fontWeight: 600, mt: 2, ml: 4 }}
                ref={ref1}
            >
                {t('courseNavigator.courses')}
            </Typography>
            <Stack
                sx={{
                    height: 'calc(100% - 40px)',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    py: 3,
                    '&::-webkit-scrollbar': {
                        width: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                        backgroundColor: 'transparent',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: theme.palette.primary[900] + '30',
                        borderRadius: '10px',
                    },
                }}
            >
                {availableCourses
                    ?.sort((a, b) => a?.title - b?.title)
                    ?.map((course) => {
                        return (
                            <CourseLink
                                active={current === course?.id}
                                onClick={() => onCourseChange(course?.id)}
                                key={course?.id}
                                course={course}
                            />
                        );
                    })}
            </Stack>
        </Box>
    );
};

const CourseLink = (props) => {
    const { active, onClick, course } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('training');
    const language = localStorage.getItem('i18nextLng');

    const departments = useMemo(
        () =>
            language?.includes('fr')
                ? course?.departments_fr?.split('.') || course?.departments?.split('.')
                : language?.includes('en')
                ? course?.departments_en?.split('.') || course?.departments?.split('.')
                : course?.departments?.split('.'),
        [course]
    );

    const link = (
        <Button
            sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                textTransform: 'unset',
                textAlign: 'left',
                py: 1,
                px: 2,
                borderLeft: '4px solid',
                borderColor: active ? theme.palette.primary[900] : '#FFF',
                borderRadius: 0,
                backgroundColor: active ? '#f8f9fe' : 'transparent',
            }}
            onClick={onClick}
            disabled={course?.videos_count === 0}
        >
            <Box
                component={PlayIcon}
                sx={{ '& path': { fill: active ? theme.palette.primary[900] : theme.palette.neutral[70] } }}
            />
            <Box sx={{ flex: 1, pl: 2 }}>
                <Typography
                    sx={{
                        fontSize: 15,
                        fontWeight: 600,
                        color: active ? theme.palette.primary[900] : theme.palette.neutral[70],
                    }}
                >
                    {language?.includes('fr')
                        ? course?.title_fr
                        : language?.includes('en')
                        ? course?.title_en
                        : course?.title}
                </Typography>
                <Typography
                    sx={{
                        fontSize: 12,
                        fontWeight: 400,
                        color: active ? theme.palette.primary[900] : theme.palette.neutral[70],
                        textTransform: 'capitalize',
                    }}
                >
                    {departments?.length > 2 ? '3+ departments' : departments?.join(', ')}. ({course?.videos_count}{' '}
                    {t('courseNavigator.videos')})
                </Typography>
            </Box>
        </Button>
    );

    return departments?.length > 2 ? (
        <AppTooltip
            title={
                <Box sx={{ minWidth: 250, p: 2 }}>
                    <Typography sx={{ color: theme.palette.white.main, fontSize: 12, fontWeight: 500, mb: 2 }}>
                        {t('courseNavigator.title')}
                    </Typography>
                    <Box component="ul" sx={{ listStyle: 'disc inside' }}>
                        {departments?.map((department) => {
                            return <li key={department}>{department}</li>;
                        })}
                    </Box>
                </Box>
            }
            placement="right"
        >
            {link}
        </AppTooltip>
    ) : (
        link
    );
};

const mapStateToProps = (state) => ({
    all_training_courses: state?.trainingReducers?.all_training_courses,
    avaliable_courses: state?.generalReducers?.user_info?.organization?.avaliable_courses,
});

export default connect(mapStateToProps, {})(CoursesNavigator);
