import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Stack, Typography, Grid } from '@mui/material';
import { Remove, DoneAll, RemoveCircle, AddCircle } from '@mui/icons-material';

// app components
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';

const FeatureRow = (props) => {
    const { label, type, value, suffix, planId, onValueChange, onCheckChange, featureKey, checked } = props;
    const theme = useTheme();

    const incrementValue = () => {
        if (!checked) return;
        onValueChange(featureKey, value + 1);
    };
    const decrementValue = () => {
        if (!checked || value < 1) return;
        onValueChange(featureKey, value - 1);
    };
    const toggleFeatureCheck = () => {
        onCheckChange(featureKey, !checked);
    };

    // functions
    const generateNonCustomValue = () => {
        if (!value) return <Remove sx={{ color: theme.palette.gray[500] }} />;
        switch (type) {
            case 'check':
                return <DoneAll color="primary" />;
            case 'upto':
                return `Up to ${value} ${suffix}`;
            case 'abs_value':
                return `${value} ${suffix}`;
        }
    };
    console.log({ label, type, value });
    const generateCustomValue = () => {
        switch (type) {
            case 'check':
                return (
                    <DoneAll
                        sx={{
                            color: value ? theme.palette.primary[900] : theme.palette.gray[500],
                        }}
                    />
                );
            case 'upto':
            case 'abs_value':
                return (
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ width: 200, mx: 'auto' }}
                    >
                        <button onClick={decrementValue}>
                            <RemoveCircle
                                sx={{
                                    color: theme.palette.gray[500],
                                    fontSize: 14,
                                    cursor: 'pointer',
                                }}
                            />
                        </button>
                        <Stack direction="row" alignItems="center">
                            <Typography sx={{ fontSize: 14, fontWeight: 700 }}>{value}</Typography>
                            <span style={{ marginLeft: '12px' }}>{suffix}</span>
                        </Stack>
                        <button onClick={incrementValue}>
                            <AddCircle
                                sx={{
                                    color: theme.palette.gray[500],
                                    fontSize: 14,
                                    cursor: 'pointer',
                                }}
                            />
                        </button>
                    </Stack>
                );
        }
    };
    console.log({ label, type, value });

    return (
        <Grid container sx={{ borderBottom: `1px solid ${theme.palette.primary[100]}` }}>
            <Grid
                item
                xs={6}
                sx={{
                    borderRight: `1px solid ${theme.palette.primary[100]}`,
                    px: 2.5,
                    py: 0.5,
                }}
            >
                <Stack direction="row">
                    {planId === 'custom' ? (
                        <AppCheckboxInput
                            label={
                                <Typography
                                    sx={{
                                        color: theme.palette.gray[30],
                                        fontWeight: 500,
                                        fontSize: 14,
                                    }}
                                >
                                    {label}
                                </Typography>
                            }
                            sx={{ m: 0, height: 25 }}
                            value={checked}
                            onChange={toggleFeatureCheck}
                        />
                    ) : (
                        <Typography
                            sx={{
                                color: theme.palette.gray[30],
                                fontWeight: 500,
                                fontSize: 14,
                            }}
                        >
                            {label}
                        </Typography>
                    )}
                </Stack>
            </Grid>
            <Grid
                item
                xs={6}
                sx={{
                    textAlign: ' center',
                    fontWeight: 300,
                    fontSize: 14,
                    color: theme.palette.black.main,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {planId === 'custom' ? generateCustomValue() : generateNonCustomValue()}
            </Grid>
        </Grid>
    );
};

export default FeatureRow;
