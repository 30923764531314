import React, { useEffect, useState } from 'react';
import AppTable from 'components/new_components/app-table';
import { GetRegisteredClients } from 'store/actions/ImplementerAction';
import { useDispatch, useSelector } from 'react-redux';
import useSearch from 'hooks/useSearch';
import { toast } from 'react-toastify';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ClientTableAction from './components/clientsTableAction';
import AppClientModal from 'components/new_components/AppClientModal';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import ClientTitle from './ClientTitle';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const NO_PER_PAGE = 10;
const Clients = () => {
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [modalPayload, setModalPayload] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenAdd, setModalOpenAdd] = useState(false);

    // Redux
    const dispatch = useDispatch();
    const registered_clients = useSelector((state) => state?.implementerReducer?.registered_clients);

    const { data, handleSearch } = useSearch(registered_clients, ['name', 'email', 'status']);

    // logic functions

    const openModal = (record) => {
        setModalOpen(true);
        setModalPayload(record);
    };
    const closeModal = () => {
        setModalOpen(false);
        setModalPayload(null);
    };

    const openModalAdd = () => {
        setModalOpenAdd(true);
    };
    const closeModalAdd = () => {
        setModalOpenAdd(false);
    };
    // lo
    const handlePageChange = (page) => {
        setPage(page);
    };
    const columns = [
        {
            title: 'Client Name',
            render: (row) => {
                return <div className="text-[#64748B] text-sm font-normal"> {row.name}</div>;
            },
        },
        {
            title: 'Audit',
            render: (row) => {
                return (
                    <span className="border border-[#E2E8F0] px-2 py-1 flex items-center gap-2 rounded w-[90px]">
                        <FiberManualRecordIcon
                            sx={{
                                width: 8,
                                height: 8,
                                backgroundColor:
                                    row?.audit_status === 'ongoing'
                                        ? '#395BA9'
                                        : row?.audit_status === 'audited'
                                        ? '#0B885A'
                                        : '#94A3B8',

                                color:
                                    row?.audit_status === 'ongoing'
                                        ? '#395BA9'
                                        : row?.audit_status === 'audited'
                                        ? '#0B885A'
                                        : '#94A3B8',
                                borderRadius: '30px',
                            }}
                        />
                        <span
                            className={`${
                                row?.audit_status === 'ongoing'
                                    ? 'text-[#395BA9]'
                                    : row?.audit_status === 'audited'
                                    ? 'text-[#0B885A]'
                                    : 'text-[#94A3B8]'
                            } text-[13px] font-medium capitalize`}
                        >
                            {row?.audit_status}
                        </span>
                    </span>
                );
            },
        },
        {
            title: 'FrameWork',
            render: (row) => {
                return <div> {row?.standards ? row?.standards : '- - -'}</div>;
            },
        },
        {
            title: 'Status',
            render: (row) => {
                return (
                    <div>
                        {' '}
                        <div
                            className={`text-sm font-normal ${
                                row?.implementer_status === 'active'
                                    ? 'text-[#37A372]'
                                    : row?.implementer_status === 'suspended'
                                    ? 'text-[#FF5449]'
                                    : 'text-[#64748B]'
                            }`}
                        >
                            {' '}
                            {row?.implementer_status === 'active'
                                ? 'Active'
                                : row?.implementer_status === 'suspended'
                                ? 'Suspended'
                                : 'Pending Approval'}
                        </div>
                    </div>
                );
            },
        },

        {
            title: <MoreHorizIcon />,
            render: (row) => <ClientTableAction row={row} openModal={openModal} />,
        },
    ];
    useEffect(() => {
        const getRegisteredClients = async () => {
            const res = await dispatch(GetRegisteredClients());
            if (!res?.status) {
                toast.error(res?.message);
            }
        };

        getRegisteredClients();
    }, []);
    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        let filteredData = data;

        setPageData(filteredData?.length > 0 ? filteredData?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);
        return data;
    }, [page, data]);
    return (
        <div>
            {' '}
            <AppTable
                columns={columns}
                data={pageData}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                implementer
                title={<ClientTitle onSearch={handleSearch} />}
                actions={[
                    <Button
                        startIcon={<AddIcon />}
                        color="primary"
                        variant="contained"
                        disableElevation
                        onClick={openModalAdd}
                        key={1}
                        sx={{ textTransform: 'inherit', py: 1 }}
                    >
                        Register Client
                    </Button>,
                ]}
            />
            <AppClientModal open={modalOpenAdd} handleClose={closeModalAdd} />
            <AppClientModal open={modalOpen} handleClose={closeModal} modalPayload={modalPayload} />
        </div>
    );
};
export default Clients;
