const themeColors = {
    white: {
        main: '#FFFFFF',
    },
    black: {
        light: '#12263F',
        main: '#000000',
    },
    transparent: {
        main: 'transparent',
    },
    gray: {
        20: '#303034',
        30: '#46464A',
        40: '#5E5E62',
        50: '#EBEDF5',
        60: '#EEF0FF',
        90: '#E1E2EC',
        95: '#F2F0F4',
        100: '#DCDEE5',
        200: '#D3D5DE',
        300: '#B8BCCC',
        400: '#A1A4B2',
        500: '#858999',
        600: '#707380',
        700: '#3E414D',
        800: '#2B2D33',
        900: '#15161A',
        new60: '#919094',
    },
    neutral: {
        20: '#E2E8F0',
        50: '#77777A',
        60: '#919094',
        70: '#acaaaf',
        80: '#c7c6ca',
        90: '#e4e2e6',
        95: '#F0F0FA',
        500: '#64748B',
        600: '#475569',
        main: '#77777A',
        new60: '#8F9099',
        new80: '#C5C6D0',
    },
    primary: {
        900: '#0e2c66',
        700: '#183366',
        500: '#334d80',
        300: '#46649e',
        100: '#9ea9bd',
        40: '#395BA9',
        30: '#1D438F',
        20: '#002C72',
        50: '#5474C4',
        main: '#0E2C66',
        dark: '#163266',
        new: '#304499',
        lightNew: '#6E8EDF',
        snackbar: '#7889e8',
        badgeBg: '#eaecfb',
        badgeBgHover: '#2a44db',
        badge: '#2643e9',
        disabled: '#9EA9BD',
    },
    secondary: {
        900: '#04CFDC',
        700: '#05DEEC',
        500: '#61E9F2',
        300: '#8EE6EC',
        100: '#BDE9EC',
        main: '#05DEEC',
        light: '#BDE9EC',
        snackbar: '#f8fbfc',
        badgeBgHover: '#cadeeb',
        btnOutline: '#4385b1',
        btnActive: '#d2e3ee',
    },
    warning: {
        100: '#FDECB1',
        300: '#FDDB65',
        500: '#E2C45A',
        700: '#E2BF44',
        800: '#CCA108',
        900: '#E2B208',
        light: '#ffd600',
        main: '#E2B208',
        snackbar: '#fc7c5f',
        badgeBg: '#fee6e0',
        badgeBgHover: '#f93305',
        badge: '#ff3709',
    },
    error: {
        50: '#de3730',
        60: '#ff5449',
        100: '#EBA4A4',
        300: '#EB7575',
        500: '#EB4141',
        700: '#D91B1B',
        900: '#980303',
        main: '#D91B1B',
        light: '#f3a4b5',
        snackbar: '#f75676',
        badgeBg: '#fdd1da',
        badgeBgHover: '#e30b36',
        badge: '#f80031',
        dialogNotification: '#f56036',
    },
    high: {
        main: '#ff4c4c',
    },
    tertiary: {
        70: '#D2A500',
    },
    info: {
        main: '#11cdef',
        snackbar: '#37d5f2',
        badgeBg: '#aaedf9',
        badgeBgHover: '#0c9cb7',
        badge: '#03acca',
    },
    background: {
        default: '#f8f9fe',
    },
    text: {
        primary: '#525f7f',
    },
    dark: {
        tableBorder: '#1f3a68',
        tableHeadColor: '#4d7bca',
        tableHeadBgColor: '#1c345d',
        main: '#0c2346',
        dark: '#0c2346',
        snackbar: '#3c4d69',
        badgeBg: '#4172c6',
        badgeBgHover: '#09111e',
    },
    success: {
        70: '#55BE8B',
        100: '#05FCA3',
        300: '#08CC85',
        500: '#06B877',
        700: '#058F5D',
        900: '#046A45',
        main: '#2dce89',
        snackbar: '#4fd69c',
        badgeBg: '#b0eed3',
        badgeBgHover: '#229c68',
        badge: '#1aae6f',
    },
    shades: {
        white: '#FFFFFF',
        black: '#000000',
    },
};

export default themeColors;
