import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

const LogStatusTag = (props) => {
    const theme = useTheme();

    const { text, type = 'default', sx = {} } = props;

    const getColor = () => {
        return type === 'success'
            ? theme.palette.success[70]
            : type === 'error'
            ? theme.palette.error[60]
            : type === 'warning'
            ? '#937300'
            : type === 'secondary'
            ? theme.palette.primary[20]
            : '';
    };

    return (
        <Box
            component="span"
            sx={{
                color: getColor(),
                background: theme.palette.white.main,
                textTransform: 'capitalize',
                fontWeight: 500,
                padding: '4px 8px',
                border: `0.8px solid ${getColor()}`,
                borderRadius: '4px',
                width: 'fit-content',
                fontSize: 12,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                whiteSpace: 'nowrap',
                gap: '4px',
                ...sx,
            }}
        >
            {text}
        </Box>
    );
};

export default LogStatusTag;
