import React, { useState, useMemo, useEffect } from 'react';

// core components
import LoadingState from 'components/new_components/LoadingState';
import DescriptionLayerNew from '../../common/DescriptionLayerNew';
import DocumentItem from '../../common/document/DocumentItem';
import QuestionItem from '../../common/audit_questions/QuestionItemNew';
// import SubgroupTabs from '../../common/SubgroupTabs';
import SubgroupView from '../../common/SubgroupView';
import DescriptionPreviewDrawer from '../../common/DescriptionPreviewDrawer';

// redux
import { connect } from 'react-redux';

//translations
import { useTranslation } from 'react-i18next';
import { GetEvidencesByControl } from 'store/actions/adminActions';
import { toast } from 'react-toastify';

const SubControls = (props) => {
    const {
        goBack,
        controlId,
        resource,
        merchantResources,
        subcontrolResources,
        onSubcontrolChange,
        loading,
        all_controls,
        all_controls_metrics,
        setFormPayload,
        touchedFields,
        submitting,
        handleResourceExempted,
        openTaskAssignModal,
        all_compliance_assigned_tasks,
        assigned_task_documents_info,
        assigned_task_questions_info,
        GetEvidencesByControl,
        evidences_by_control,
        all_merchant_evidences,
        type,
    } = props;
    // state
    const [page, setPage] = useState(1);
    const [selectedSubcontrolTitle, setSelectedSubcontrolTitle] = useState(null);
    const [descriptionDrawer, setDescriptionDrawer] = useState(false);
    const [openPolicyHistory, setOpenPolicyHistory] = useState(false);

    const handleOpenPolicyHistory = () => {
        setOpenPolicyHistory(true);
    };

    const handleClosePolicyHistory = () => {
        setOpenPolicyHistory(false);
    };

    //translation
    const { t } = useTranslation('compliance');
    const language = localStorage.getItem('i18nextLng');

    // memos
    // get the selected control
    const selectedControl = useMemo(() => {
        return all_controls?.find((control) => control?.id === controlId);
    }, [all_controls, controlId]);
    // overall metric for the selected control
    const overallMetric = useMemo(() => {
        const metrics = all_controls_metrics?.[selectedControl?.title];

        return resource === 'doc'
            ? `${metrics?.user_doc}/${metrics?.total_doc} ${t('documentsUploaded')}`
            : `${metrics?.user_form}/${metrics?.total_form} ${t('questionsAnswered')}`;
    }, [selectedControl, all_controls_metrics]);
    const subcontrols = useMemo(() => {
        return selectedControl?.sub_controls?.[0]?.title?.includes('CC')
            ? selectedControl?.sub_controls
                  ?.sort((a, b) => a?.title?.charAt(a?.title?.length - 1) - b?.title?.charAt(b?.title?.length - 1))
                  ?.map((subcontrol) => ({
                      id: subcontrol?.title,
                      description: subcontrol?.description,
                      title: subcontrol?.title,
                      label: subcontrol?.label,
                      has_resource: resource === 'doc' ? subcontrol?.have_doc : subcontrol?.have_question,
                      completed: resource === 'doc' ? subcontrol?.document_completed : subcontrol?.question_completed,
                  }))
            : selectedControl?.sub_controls
                  ?.sort((a, b) => a?.id - b?.id)
                  ?.map((subcontrol) => ({
                      id: subcontrol?.title,
                      description: subcontrol?.description,
                      title: subcontrol?.title,
                      label: subcontrol?.label,
                      has_resource: resource === 'doc' ? subcontrol?.have_doc : subcontrol?.have_question,
                      completed: resource === 'doc' ? subcontrol?.document_completed : subcontrol?.question_completed,
                  }));
    }, [selectedControl]);
    // get the selected subcontrol from the the subcontrols
    const selectedSubcontrol = useMemo(() => {
        return subcontrols?.find((subcontrol) => subcontrol?.title === selectedSubcontrolTitle);
    }, [selectedSubcontrolTitle]);
    // get a list of the status of each resource in the subcontrol
    const resourcesHaveBeenAttendedTo = useMemo(() => {
        return subcontrolResources?.map((subcontrolDoc) => {
            return merchantResources?.some((merchDoc) =>
                resource === 'doc'
                    ? merchDoc?.document_id === subcontrolDoc?.id
                    : merchDoc?.form_id === subcontrolDoc?.id
            );
        });
    }, [merchantResources, subcontrolResources]);
    // get the percentage completion for the selected subcontrol
    const subcontrolCompletionPercent = useMemo(() => {
        return (
            (resourcesHaveBeenAttendedTo?.reduce((prev, curr) => prev + (curr ? 1 : 0), 0) * 100) /
            resourcesHaveBeenAttendedTo?.length
        );
    }, [resourcesHaveBeenAttendedTo]);

    // get the selected document for the current page
    const selectedResource = useMemo(() => {
        return subcontrolResources?.[page - 1];
    }, [subcontrolResources, page]);
    // task assigned
    const isTaskAssignedToSelectSubControl = useMemo(() => {
        const assignedSubrequirements =
            resource === 'doc'
                ? all_compliance_assigned_tasks?.documents?.sub_category?.soc2
                : all_compliance_assigned_tasks?.questions?.sub_category?.soc2;
        return assignedSubrequirements?.includes(selectedSubcontrol?.title);
    }, [selectedSubcontrol?.title, resource]);
    // functions
    // logic functions
    // move to a particular resource
    const goToResource = (page) => {
        if (page > subcontrolResources?.length) {
            setPage(subcontrolResources?.length);
        } else if (page < 1) {
            setPage(1);
        } else {
            setPage(page);
        }
    };
    const handleSubgroupChange = (title) => {
        setSelectedSubcontrolTitle(title);
        goToResource(1);
    };
    const onResourceChange = () => {
        handleResourceExempted(selectedResource?.sub_control);
    };

    // useEffect
    // to get the first sub control that has a resource and set it as active
    useEffect(() => {
        if (!subcontrols) return;
        // check if the current subcontrol has any resource
        const currentSubHasResource = subcontrols?.find((sub) => sub?.title === selectedSubcontrolTitle)?.has_resource;
        if (currentSubHasResource) return;
        // get the first active resource
        const activeSubcontrol = subcontrols?.find((sub) => sub?.has_resource);
        if (activeSubcontrol) {
            setSelectedSubcontrolTitle(activeSubcontrol?.title);
        } else {
            goBack();
        }
    }, [subcontrols]);
    // when ever the selected sub control chnage
    useEffect(() => {
        if (selectedSubcontrolTitle) {
            onSubcontrolChange(selectedSubcontrolTitle);
        }
    }, [selectedSubcontrolTitle]);

    useEffect(() => {
        if (!selectedResource) {
            if (subcontrolResources) {
                return goToResource(subcontrolResources?.length);
            }
        }
    }, [selectedResource]);
    useEffect(() => {
        if (resource === 'form') {
            // get the admin resource for that page
            const adminResource = selectedResource;
            // get the merchant resource corresponding to that page
            const merchantResource = merchantResources
                ?.sort((a, b) => b?.id - a?.id)
                .find((resource) => resource?.form_id === adminResource?.id);
            // set the answers
            let initialValues = {},
                requiredValues = {};
            adminResource?.form_fields?.forEach((field) => {
                initialValues[field?.id] = merchantResource?.answer?.[field?.id] || '';
                requiredValues[field?.id] = field?.required;
            });
            setFormPayload({
                values: initialValues,
                form_id: adminResource?.id,
                requiredValues,
                subcontrol: adminResource?.sub_control,
            });
        }
    }, [page, selectedResource, merchantResources]);

    //open description preview modal
    const openDescriptionDrawer = () => {
        setDescriptionDrawer(true);
    };
    const closeDescriptionDrawer = () => {
        setDescriptionDrawer(false);
    };
    useEffect(() => {
        if (controlId) {
            const getControlEvidences = async () => {
                const res = await GetEvidencesByControl(controlId);
                if (!res?.success) {
                    toast.error('Something went wrong!');
                }
            };
            getControlEvidences();
        }
    }, []);
    const subgroup_evidences = evidences_by_control?.filter(
        (evidence) => evidence?.sub_control === selectedSubcontrol?.id
    );
    const user_subgroup_evidences = all_merchant_evidences?.filter(
        (evidence) => evidence?.sub_control_title === selectedSubcontrol?.title
    );
    return (
        <>
            <SubgroupView
                goBack={goBack}
                title={selectedControl?.title}
                groupMetric={overallMetric}
                goToNextResource={goToResource}
                currentResourcePage={page}
                data={resourcesHaveBeenAttendedTo}
                resource={resource}
                touchedFields={touchedFields}
                submitting={submitting}
                selectedSubgroup={selectedSubcontrol?.title}
                assigned_task_documents_info={assigned_task_documents_info}
                assigned_task_questions_info={assigned_task_questions_info}
                standard="soc2"
                selectedSubgroupId={selectedSubcontrol?.title}
                subgroups={subcontrols}
                onSubgroupChange={handleSubgroupChange}
                assignedSubrequirements={
                    resource === 'doc'
                        ? all_compliance_assigned_tasks?.documents?.sub_category?.soc2
                        : all_compliance_assigned_tasks?.questions?.sub_category?.soc2
                }
            >
                {loading ? (
                    <LoadingState />
                ) : (
                    <>
                        <DescriptionLayerNew
                            percentCompletion={subcontrolCompletionPercent}
                            label={`${selectedSubcontrolTitle} ${t('completion')}`}
                            description={selectedSubcontrol?.description}
                            openTaskAssignModal={() =>
                                openTaskAssignModal('sub-control', {
                                    sub_control: selectedSubcontrol?.title,
                                    standard: 'soc2',
                                    is_document: resource === 'doc',
                                    common_control: selectedControl?.id,
                                })
                            }
                            resource={resource}
                            type="sub-control"
                            subCategory={selectedSubcontrol?.title}
                            title={`${selectedSubcontrol?.title}`}
                            category={selectedControl?.id}
                            standard="soc2"
                            isTaskAssignedToSelectSubRequirement={isTaskAssignedToSelectSubControl}
                            openDescriptionDrawer={openDescriptionDrawer}
                            subgroup_evidences={subgroup_evidences}
                            user_subgroup_evidences={user_subgroup_evidences}
                            type_={type}
                        />
                        {resource === 'doc' ? (
                            <DocumentItem
                                document={selectedResource}
                                title_key={
                                    language?.includes('fr')
                                        ? 'soc2_title_fr'
                                        : language?.includes('en')
                                        ? 'soc2_title_en'
                                        : 'soc2_title'
                                }
                                standard="soc2"
                                resource="doc"
                                openTaskAssignModal={openTaskAssignModal}
                                onResourceChange={onResourceChange}
                                openPolicyHistory={openPolicyHistory}
                                handleOpenPolicyHistory={handleOpenPolicyHistory}
                                handleClosePolicyHistory={handleClosePolicyHistory}
                            />
                        ) : (
                            <QuestionItem
                                form={selectedResource}
                                title={
                                    language?.includes('fr')
                                        ? 'soc2_title_fr'
                                        : language?.includes('en')
                                        ? 'soc2_title_en'
                                        : 'soc2_title'
                                }
                                standard="soc2"
                                onResourceChange={onResourceChange}
                            />
                        )}
                    </>
                )}
            </SubgroupView>
            <DescriptionPreviewDrawer
                open={descriptionDrawer}
                handleClose={closeDescriptionDrawer}
                category={selectedSubcontrol}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_controls: state?.adminReducers?.all_controls,
        all_controls_metrics: state?.complianceReducers?.all_controls_metrics,
        assigned_task_documents_info:
            state?.generalReducers?.user_info?.compliance_assigned_tasks?.documents?.forms?.soc2,
        assigned_task_questions_info:
            state?.generalReducers?.user_info?.compliance_assigned_tasks?.questions?.forms?.soc2,
        evidences_by_control: state?.adminReducers?.all_admin_evidence_by_common_control,
        all_merchant_evidences: state?.complianceReducers?.all_merchant_evidences,
    };
};
export default connect(mapStateToProps, { GetEvidencesByControl })(SubControls);
