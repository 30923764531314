import React from 'react';
import { Box } from '@mui/material';

// core components
import SidebarDrawer from './SidebarDrawer';
import { connect } from 'react-redux';

const MerchantSidebar = (props) => {
    const { open, handleClose, routes, merchant_info, title, organization } = props;
    return (
        <Box
            // component="nav"
            sx={{
                width: { md: 275 },
                mt: { lg: '1.5rem', md: '3rem' },
                flexShrink: { md: 0 },
                height: organization?.merchant_plan?.name === '3_days_trial' ? '90vh' : '100vh',
            }}
        >
            {/* for small screens */}
            <SidebarDrawer largeScreen={false} open={open} onClose={handleClose} routes={routes} title={title} />

            {/* for large screens */}
            <SidebarDrawer largeScreen={true} routes={routes} merchant_info={merchant_info} title={title} />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        merchant_info: state?.merchantReducers?.merchant_info,
        organization: state?.generalReducers?.user_info?.organization,
    };
};
export default connect(mapStateToProps, {})(MerchantSidebar);
