import React, { useEffect, useState } from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import { Box, Button, Checkbox, FormControlLabel, Menu, MenuItem, Tooltip, Typography } from '@mui/material';
// import { Edit, Add } from '@mui/icons-material';

import AppButton from 'components/new_components/AppButton';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from 'assets/img/Searchicon.svg';

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import AppDrawer from 'components/new_components/AppDrawer';
import { useTheme } from '@mui/styles';
import { useHistory } from 'react-router-dom';
import { AssetCategories, InformationTypeOptions, TechnologicalTypeOptions } from '../utils/constants';
import { UpdateAssets } from 'store/actions/merchantActions';
import { AddAssets } from 'store/actions/merchantActions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAssetGroups } from 'store/actions/merchantActions';
// import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WindowsIcon from 'assets/img/windowsicon.svg';
import LinuxIcon from 'assets/img/linuxicon.svg';
import { GetAllMerchantUsers } from 'store/actions/generalActions';
import EditIcon from '@mui/icons-material/Edit';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { RunAssmentAction } from 'store/actions/riskAssessmentActions';
import { AddAssetRisk } from 'store/actions/riskAssessmentActions';
import { GetAllVendors } from 'store/actions/vendorActions';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import { GetAllCategories } from 'store/actions/riskAssessmentActions';

const InfrastructureModalModal = (props) => {
    const { open, handleClose, isAddModal, openRiskLevelsModal, openGroupUpload, id, details } = props;

    // const [type, setType] = useState('local');
    // const [sensitivityAnchorEl, setSensitivityAnchorEl] = useState(null);
    const [agentAnchorEl, setAgentAnchorEl] = useState(null);
    const [loadingState, setLoadingState] = useState(false);
    const [custodianAnchorEl, setCustodianAnchorEl] = useState(null);
    const [assetCategoryAnchorEl, setAssetCategoryAnchorEl] = useState(null);
    const [ownerAnchorEl, setOwnerAnchorEl] = useState(null);
    const [error, setError] = useState(false);
    const [groupAnchorEl, setGroupAnchorEl] = useState(null);
    const [search, setSearch] = useState('');
    const [assetCategory, setAssetCategory] = useState('');
    const [name, setName] = useState('');
    const [ip_address, setIpAddress] = useState('');
    const [os, setOs] = useState('');
    const [location, setLocation] = useState('');
    const [assetTypeAnchorEl, setAssetTypeAnchorEl] = useState(null);
    const [assetType, setAssetType] = useState('');
    const [riskCategoryAnchorEl, setRiskCategoryAnchorEl] = useState(null);
    const [risk_category, setRiskCategory] = useState(null);
    const [peopleAnchorEl, setRPeopleAnchorEl] = useState(null);
    const [people, setPeople] = useState(null);
    const [vendorAnchorEl, setVendorAnchorEl] = useState(null);
    const [vendor, setVendor] = useState(null);
    const [risk_assessment, setRiskAssessment] = useState(false);
    const [vul_scan, setVulScan] = useState(false);
    const [api_compliance, setApiCompliance] = useState(false);
    const [pci_scan, setPciScan] = useState(false);
    const [custodianEmail, setCustodianEmail] = useState({
        name: '',
        value: '',
    });
    const [ownerEmail, setOwnerEmail] = useState({
        name: '',
        value: '',
    });
    const [group, setGroup] = useState({
        name: '',
        value: '',
    });
    const theme = useTheme();
    const history = useHistory();

    // redux
    const dispatch = useDispatch();
    const all_merchant_asset_groups = useSelector((state) => state?.merchantReducers?.all_merchant_asset_groups);
    const all_merchant_users = useSelector((state) => state?.generalReducers?.all_merchant_users);
    const all_vendors = useSelector((state) => state?.vendorReducers?.all_vendors);

    const { risk_assessment_categories } = useSelector((state) => state.riskAssessmentReducers);
    //async action
    const getAllAssetGroups = async () => {
        const res = await dispatch(GetAssetGroups());
        if (!res?.status) {
            toast.error(res?.status);
        }
    };

    const getAllVendors = async () => {
        const res = await dispatch(GetAllVendors());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllRiskAssessmentCategories = async () => {
        const res = await dispatch(GetAllCategories());
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllEmployees = async () => {
        const res = await dispatch(GetAllMerchantUsers());
        if (!res?.status) {
            toast.error(res?.status);
        }
    };
    useEffect(() => {
        if (open) {
            getAllAssetGroups();
            getAllEmployees();
            getAllVendors();
            getAllRiskAssessmentCategories();
        }
    }, [open]);

    useEffect(() => {
        if (open && details) {
            const risk_cat = risk_assessment_categories?.find((risk) => risk?.id === details?.risk_category);
            setAssetType(details?.asset_type);
            if (details?.group) {
                setGroup({
                    name: details?.group,
                    value: details?.group,
                });
            }
            if (details?.custodian_email) {
                setCustodianEmail({
                    name: details?.custodian_email,
                    value: details?.custodian_email,
                });
            }
            if (details?.group) {
                setGroup({
                    name: details?.group,
                    value: details?.group,
                });
            }
            if (details?.owner_email) {
                setOwnerEmail({
                    name: details?.owner_email,
                    value: details?.owner_email,
                });
            }
            setAssetCategory(details?.asset_category);
            setRiskCategory(risk_cat);
            setName(details?.name);
            setIpAddress(details?.ip_address);
            setOs(details?.os);
            setPeople(details?.person_details);
            setVendor(details?.vendor_details);
            setLocation(details?.location);
        }
    }, [open, details]);
    const all_enployees = all_merchant_users?.users.concat(all_merchant_users?.people);

    const mapped_employees = all_enployees?.map((employee) => {
        return {
            name: employee?.email,
            value: employee?.email,
        };
    });
    const filtered_employees = mapped_employees?.filter((option) =>
        option?.name?.toLowerCase().includes(search.toLowerCase())
    );
    const filtered_all_merchant_asset_groups = all_merchant_asset_groups?.filter((option) =>
        option?.name?.toLowerCase().includes(search.toLowerCase())
    );
    const validate_info_asset = !name || !ownerEmail?.value || !assetType || !location || !risk_category?.id;
    const validate_application_inventory = !name || !ownerEmail?.value || !custodianEmail || !risk_category?.id;
    const validate_servers = !os || !name || !ownerEmail?.value || !location || !risk_category?.id;
    const validate_laptops = !os || !name || !ownerEmail?.value || !ip_address || !risk_category?.id;
    const validate_people = !people?.id;
    const validate_vendor = !vendor?.id;
    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!assetCategory) {
            setError(true);
        } else if (assetCategory === 'information_asset' && validate_info_asset) {
            setError(true);
        } else if (assetCategory === 'application_inventory' && validate_application_inventory) {
            setError(true);
        } else if (assetCategory === 'people' && validate_people) {
            setError(true);
        } else if (assetCategory === 'service_provider' && validate_vendor) {
            setError(true);
        } else if (assetCategory === 'laptops_desktops' && validate_laptops) {
            setError(true);
        } else if (assetCategory === 'servers_appliances' && validate_servers) {
            setError(true);
        } else {
            const info_asset_body = {
                name,
                group: group?.value,
                owner_email: ownerEmail?.value,
                asset_type: assetType,
                location,
                risk_category: risk_category?.id,
                asset_category: assetCategory,
            };
            const application_inventory_body = {
                name,
                group: group?.value,
                owner_email: ownerEmail?.value,
                custodian_email: custodianEmail?.value,
                asset_type: assetType,
                risk_category: risk_category?.id,
                ip_address,
                asset_category: assetCategory,
            };
            const people_body = {
                people: people?.id,
                risk_category: risk_category?.id,
                asset_category: assetCategory,
            };
            const laptops_body = {
                name,
                risk_category: risk_category?.id,
                ip_address,
                owner_email: ownerEmail?.value,
                os,
                asset_category: assetCategory,
            };
            const vendor_body = {
                vendor: vendor?.id,
                risk_category: risk_category?.id,
                asset_category: assetCategory,
            };
            const servers_body = {
                os,
                name,
                group: group?.value,
                ip_address,
                risk_category: risk_category?.id,
                asset_category: assetCategory,
                asset_type: assetType,
                location,
                owner_email: ownerEmail?.value,
            };
            const body =
                assetCategory === 'information_asset'
                    ? info_asset_body
                    : assetCategory === 'application_inventory'
                    ? application_inventory_body
                    : assetCategory === 'people'
                    ? people_body
                    : assetCategory === 'service_provider'
                    ? vendor_body
                    : assetCategory === 'laptops_desktops'
                    ? laptops_body
                    : servers_body;
            setLoadingState(true);
            const res = isAddModal ? await dispatch(AddAssets(body)) : await dispatch(UpdateAssets(body, id));
            if (res?.success) {
                setLoadingState(false);
                setAssetCategory('');
                setAssetType('');
                setName('');
                setIpAddress('');
                setOs('');
                setLocation('');
                setGroup(null);
                setOwnerEmail(null);
                setRiskCategory(null);
                setCustodianEmail(null);
                setError(false);
                if (risk_assessment) {
                    dispatch(RunAssmentAction(true));
                    dispatch(AddAssetRisk(res?.data));
                    history.push('/merchant/assessment/risk-asessement');
                }
                toast.success(isAddModal ? 'Asset Added Successfully' : 'Asset Credentials Updated Successfully');

                setGroup({
                    name: '',
                    value: '',
                });
                // setType('local');
                handleClose();
            } else {
                setLoadingState(false);
                toast.error(res?.message);
            }
        }
    };

    const ownerOpenMenu = (e) => setOwnerAnchorEl(e.target);
    const ownerCloseMenu = () => setOwnerAnchorEl(null);

    const custodianOpenMenu = (e) => setCustodianAnchorEl(e.target);
    const custodianCloseMenu = () => setCustodianAnchorEl(null);

    // const sensitivityOpenMenu = (e) => setSensitivityAnchorEl(e.target);
    // const sensitivityCloseMenu = () => setSensitivityAnchorEl(null);

    const agentOpenMenu = (e) => setAgentAnchorEl(e.target);
    const agentCloseMenu = () => setAgentAnchorEl(null);

    const groupOpenMenu = (e) => setGroupAnchorEl(e.target);
    const groupCloseMenu = () => setGroupAnchorEl(null);

    // const handleSensitivityChange = (value) => {
    //     setSensitivity(value);
    // };

    const handleOwnerChange = (value) => {
        setOwnerEmail(value);
    };
    const handleCustodianChange = (value) => {
        setCustodianEmail(value);
    };

    const handleGroupChange = (item) => {
        setGroup({
            name: item?.name,
            value: item?.id,
        });
    };
    const typeOptions = assetCategory === 'information_asset' ? InformationTypeOptions : TechnologicalTypeOptions;
    return (
        <AppDrawer
            open={open}
            handleClose={() => {
                // setType('local');
                handleClose();
            }}
            title={isAddModal ? 'Add Asset' : 'Update Asset'}
            icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
            noShadow
            noClose
            headerAction={
                <>
                    <AppButton
                        name="Add Asset"
                        sx={{
                            mx: 3,
                            my: 2,
                            overflow: 'scroll !important',
                            height: '80vh',
                            pb: 5,
                            [theme.breakpoints.down('lg')]: {
                                overflow: 'scroll !important',
                                height: '60vh',
                                pb: 5,
                            },
                            [theme.breakpoints.down('md')]: {
                                overflow: 'scroll !important',
                                height: '60vh',
                                pb: 5,
                            },
                        }}
                        icon={<FiHelpCircle size="13px" />}
                        onClick={openRiskLevelsModal}
                    />
                </>
            }
            sx={{ overflow: 'hidden' }}
            width="500px"
        >
            <Box
                sx={{
                    mx: 3,
                    my: 2,
                    overflow: 'scroll !important',
                    height: '80vh',
                    [theme.breakpoints.down('lg')]: {
                        overflow: 'scroll !important',
                        height: '65vh',
                        pb: '4rem',
                    },
                }}
            >
                <Box
                    sx={{
                        backgroundColor: '#F8FAFC',
                        color: '#64748B2',
                        fontSize: '12px',
                        fontWeight: '400',
                        p: 2,
                    }}
                >
                    <Typography sx={{ fontSize: '12px' }}>
                        Assets added here can be used in areas that requires them like Vulnerability, PCI scan etc.
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: '12px',
                            fontWeight: '400',
                            gap: 0.5,
                        }}
                    >
                        -Note: We advice you.
                        <span
                            onClick={() => {
                                dispatch(RunAssmentAction(true));
                                history.push('/merchant/assessment/risk-asessement');
                            }}
                            className="cursor-pointer font-normal underline text-xs text-[#395BA9]"
                        >
                            {' '}
                            run a risk assessment
                        </span>{' '}
                        on your assets in scope
                    </Typography>{' '}
                </Box>{' '}
                <div className="w-full mt-4">
                    <p className="text-[#64748B] font-medium text-[13px] mb-1">Asset Category</p>
                    <button
                        aria-controls={assetCategoryAnchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={assetCategoryAnchorEl ? 'true' : undefined}
                        onClick={(event) => {
                            event.stopPropagation();
                            setAssetCategoryAnchorEl(event.currentTarget);
                        }}
                        className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded py-2.5 !px-2.5 transition-colors w-full flex justify-between items-center"
                    >
                        <p className="text-[#64748B] font-medium text-[13px] text-capitalize">
                            {assetCategory === 'servers_appliances'
                                ? 'Servers and Appliances'
                                : assetCategory?.replace(/_/g, ' ') || 'Asset Category'}
                        </p>

                        <img
                            src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                            alt="Arrow Down Icon"
                            className="object-contain"
                        />
                    </button>
                    <Menu
                        open={Boolean(assetCategoryAnchorEl)}
                        anchorEl={assetCategoryAnchorEl}
                        onClose={() => setAssetCategoryAnchorEl(null)}
                        className="w-full"
                    >
                        {AssetCategories?.map((category, index) => (
                            <MenuItem
                                key={index}
                                sx={{
                                    color: '#64748B',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    '&:hover': {
                                        color: '#64748B',
                                    },
                                    textTransform: 'capitalize',
                                }}
                                onClick={() => {
                                    setAssetCategory(category);
                                    setAssetType('');
                                    setAssetCategoryAnchorEl(null);
                                }}
                            >
                                {category === 'servers_appliances'
                                    ? 'Servers and Appliances'
                                    : category?.replace(/_/g, ' ')}
                            </MenuItem>
                        ))}
                    </Menu>
                    {error && !assetCategory && (
                        <span className="text-xs text-[#FF0000] font-normal">Asset category is required</span>
                    )}
                </div>
                {assetCategory !== 'people' && assetCategory !== 'service_provider' && (
                    <div>
                        <p className="text-[#64748B] font-medium text-[13px] mb-1 mt-3">
                            {assetCategory === 'information_asset'
                                ? 'Information Name'
                                : assetCategory === 'application_inventory'
                                ? 'Application Name'
                                : assetCategory === 'laptops_desktops'
                                ? 'Computer Name'
                                : 'Server Name'}
                        </p>
                        <input
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            type="text"
                            name="name"
                            placeholder="Enter name"
                            className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm p-2.5 border border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                        />
                        {error && !name && (
                            <span className="text-xs text-[#FF0000] font-normal">Asset category is required</span>
                        )}
                    </div>
                )}
                {(assetCategory === 'servers_appliances' || assetCategory === 'laptops_desktops') && (
                    <div>
                        <p className="text-[#64748B] font-medium text-[13px] mb-1 mt-3">Operating System</p>
                        <input
                            value={os}
                            onChange={(event) => setOs(event.target.value)}
                            type="text"
                            placeholder="Enter Operating system"
                            className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm p-2.5 border border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                        />
                        {error &&
                            (assetCategory === 'servers_appliances' || assetCategory === 'laptops_desktops') &&
                            !os && (
                                <span className="text-xs text-[#FF0000] font-normal">Operating system is required</span>
                            )}
                    </div>
                )}
                {assetCategory !== 'people' &&
                    assetCategory !== 'service_provider' &&
                    assetCategory !== 'laptops_desktops' && (
                        <div className="w-full mt-4">
                            <p className="text-[#64748B] font-medium text-[13px] mb-1">Asset Type</p>
                            <button
                                aria-controls={assetTypeAnchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={assetTypeAnchorEl ? 'true' : undefined}
                                onClick={(event) => {
                                    event.stopPropagation();
                                    setAssetTypeAnchorEl(event.currentTarget);
                                }}
                                className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded py-2.5 !px-2.5 transition-colors w-full flex justify-between items-center"
                            >
                                <p className="text-[#64748B] font-medium text-[13px]">{assetType || 'Asset Type'}</p>

                                <img
                                    src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                    alt="Arrow Down Icon"
                                    className="object-contain"
                                />
                            </button>
                            <Menu
                                open={Boolean(assetTypeAnchorEl)}
                                anchorEl={assetTypeAnchorEl}
                                onClose={() => setAssetTypeAnchorEl(null)}
                                className="w-full"
                            >
                                {typeOptions?.map((type, index) => (
                                    <MenuItem
                                        key={index}
                                        sx={{
                                            color: '#64748B',
                                            fontSize: '14px',
                                            fontWeight: 500,
                                            '&:hover': {
                                                color: '#64748B',
                                            },
                                            textTransform: 'capitalize',
                                        }}
                                        onClick={() => {
                                            setAssetType(type);
                                            setAssetTypeAnchorEl(null);
                                        }}
                                    >
                                        {type?.replace(/_/g, ' ')}
                                    </MenuItem>
                                ))}
                            </Menu>
                            {error && !assetType && (
                                <span className="text-xs text-[#FF0000] font-normal">Asset Type is required</span>
                            )}
                        </div>
                    )}
                <div className="w-full mt-4">
                    <p className="text-[#64748B] font-medium text-[13px] mb-1">Risk Category</p>
                    <button
                        aria-controls={riskCategoryAnchorEl ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={riskCategoryAnchorEl ? 'true' : undefined}
                        onClick={(event) => {
                            event.stopPropagation();
                            setRiskCategoryAnchorEl(event.currentTarget);
                        }}
                        className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded py-2.5 !px-2.5 transition-colors w-full flex justify-between items-center"
                    >
                        <p className="text-[#64748B] font-medium text-[13px]">
                            {risk_category?.name || 'Select Risk Category...'}
                        </p>

                        <img
                            src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                            alt="Arrow Down Icon"
                            className="object-contain"
                        />
                    </button>
                    <Menu
                        open={Boolean(riskCategoryAnchorEl)}
                        anchorEl={riskCategoryAnchorEl}
                        onClose={() => setRiskCategoryAnchorEl(null)}
                        className="w-full"
                    >
                        <div className="h-[300px] overflow-auto">
                            {risk_assessment_categories?.map((category, index) => (
                                <MenuItem
                                    key={index}
                                    sx={{
                                        color: '#64748B',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        '&:hover': {
                                            color: '#64748B',
                                        },
                                        textTransform: 'capitalize',
                                    }}
                                    onClick={() => {
                                        setRiskCategory(category);
                                        setRiskCategoryAnchorEl(null);
                                    }}
                                >
                                    {category?.name}
                                </MenuItem>
                            ))}
                        </div>
                    </Menu>
                    {error && !risk_category?.id && (
                        <span className="text-xs text-[#FF0000] font-normal">Risk Category is required</span>
                    )}
                </div>
                <Box className="mb-3">
                    {(assetCategory === 'servers_appliances' ||
                        assetCategory === 'application_inventory' ||
                        assetCategory === 'laptops_desktops') && (
                        <div className="mt-3">
                            <p className="text-[#64748B] font-medium text-[13px] mb-1">Asset IP Address</p>
                            <input
                                type="text"
                                value={ip_address}
                                onChange={(event) => setIpAddress(event.target.value)}
                                placeholder="Enter Ip"
                                className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm p-2.5 border border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                            />
                            {error &&
                                (assetCategory === 'servers_appliances' ||
                                    assetCategory === 'application_inventory' ||
                                    assetCategory === 'laptops_desktops') &&
                                !ip_address && (
                                    <span className="text-xs text-[#FF0000] font-normal">
                                        Risk Category is required
                                    </span>
                                )}
                        </div>
                    )}
                    {(assetCategory === 'information_asset' || assetCategory === 'servers_appliances') && (
                        <div className="mt-3">
                            <p className="text-[#64748B] font-medium text-[13px] mb-1">Asset Location</p>
                            <input
                                type="text"
                                value={location}
                                onChange={(event) => setLocation(event.target.value)}
                                placeholder="Enter Location"
                                className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm p-2.5 border border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                            />
                            {error &&
                                (assetCategory === 'information_asset' || assetCategory === 'servers_appliances') &&
                                !location && (
                                    <span className="text-xs text-[#FF0000] font-normal">Location is required</span>
                                )}
                        </div>
                    )}
                    <div className="mt-3">
                        {assetCategory === 'people' && (
                            <div className="w-full mt-4">
                                <div className="flex items-center gap-2">
                                    <p className="text-[#64748B] font-medium text-[13px] mb-1">Employee</p>
                                </div>
                                <button
                                    aria-controls={peopleAnchorEl ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={peopleAnchorEl ? 'true' : undefined}
                                    onClick={(event) => setRPeopleAnchorEl(event.currentTarget)}
                                    className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded py-2.5 !px-2.5 transition-colors w-full flex justify-between items-center"
                                >
                                    <p className="text-[#64748B] font-medium text-[13px]">
                                        {people?.id !== '' ? people?.email : 'Select an employee'}
                                    </p>

                                    <img
                                        src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                        alt="Arrow Down Icon"
                                        className="object-contain"
                                    />
                                </button>
                                <Menu
                                    open={Boolean(peopleAnchorEl)}
                                    anchorEl={peopleAnchorEl}
                                    onClose={() => setRPeopleAnchorEl(null)}
                                    PaperProps={{
                                        style: {
                                            width: 400,
                                        },
                                    }}
                                >
                                    <div className="px-3 py-1">
                                        <p className="text-[#64748B] font-medium text-[13px] mt-2">Search name</p>
                                    </div>
                                    <div className="relative px-3">
                                        <input
                                            placeholder="Search..."
                                            value={search}
                                            onChange={(event) => setSearch(event?.target?.value)}
                                            className="text-gray-400 text-sm font-normal placeholder:text-gray-400 border border-gray-200 py-2 rounded-[4px] w-[230px] px-[30px] w-full"
                                        />
                                        <img
                                            src={SearchIcon}
                                            alt="SearchIcon"
                                            className="absolute left-6 top-2.5 w-[17px]"
                                        />
                                    </div>
                                    {all_merchant_users?.people?.length ? (
                                        <div className="mt-3">
                                            {all_merchant_users?.people?.map((option) => (
                                                <MenuItem
                                                    sx={{
                                                        color: people?.id === option?.id ? '#FFFFFF' : '#64748B',
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                        mx: 2,
                                                        backgroundColor: people?.id === option?.id ? '#395BA9' : '',
                                                        '&:hover': {
                                                            color: '#64748B',
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        setPeople(option);
                                                        setRPeopleAnchorEl(null);
                                                    }}
                                                >
                                                    {option?.email}
                                                </MenuItem>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="flex justify-center items-center text-[#64748B] font-medium text-[13px] mt-2">
                                            No User Found
                                        </p>
                                    )}
                                </Menu>{' '}
                            </div>
                        )}
                        {error && assetCategory === 'people' && !people?.name && (
                            <span className="text-xs text-[#FF0000] font-normal">Employee is required</span>
                        )}
                    </div>
                    <div className="mt-3">
                        {assetCategory === 'service_provider' && (
                            <div className="w-full mt-4">
                                <div className="flex items-center gap-2">
                                    <p className="text-[#64748B] font-medium text-[13px] mb-1">Vendor</p>
                                </div>
                                <button
                                    aria-controls={vendorAnchorEl ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={vendorAnchorEl ? 'true' : undefined}
                                    onClick={(event) => setVendorAnchorEl(event.currentTarget)}
                                    className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded py-2.5 !px-2.5 transition-colors w-full flex justify-between items-center"
                                >
                                    <p className="text-[#64748B] font-medium text-[13px]">
                                        {vendor?.value !== '' ? vendor?.name : 'Select a vendor'}
                                    </p>

                                    <img
                                        src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                        alt="Arrow Down Icon"
                                        className="object-contain"
                                    />
                                </button>
                                <Menu
                                    open={Boolean(vendorAnchorEl)}
                                    anchorEl={vendorAnchorEl}
                                    onClose={() => setVendorAnchorEl(null)}
                                    PaperProps={{
                                        style: {
                                            width: 400,
                                        },
                                    }}
                                >
                                    <div className="px-3 py-1">
                                        <p className="text-[#64748B] font-medium text-[13px] mt-2">Search name</p>
                                    </div>
                                    <div className="relative px-3">
                                        <input
                                            placeholder="Search..."
                                            value={search}
                                            onChange={(event) => setSearch(event?.target?.value)}
                                            className="text-gray-400 text-sm font-normal placeholder:text-gray-400 border border-gray-200 py-2 rounded-[4px] w-[230px] px-[30px] w-full"
                                        />
                                        <img
                                            src={SearchIcon}
                                            alt="SearchIcon"
                                            className="absolute left-6 top-2.5 w-[17px]"
                                        />
                                    </div>
                                    {all_vendors?.length ? (
                                        <div className="mt-3">
                                            {all_vendors?.map((option) => (
                                                <MenuItem
                                                    sx={{
                                                        color: vendor?.id === option?.id ? '#FFFFFF' : '#64748B',
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                        mx: 2,
                                                        backgroundColor: vendor?.id === option?.id ? '#395BA9' : '',
                                                        '&:hover': {
                                                            color: '#64748B',
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        setVendor(option);
                                                        setVendorAnchorEl(null);
                                                    }}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="flex justify-center items-center text-[#64748B] font-medium text-[13px] mt-2">
                                            No Vendor Found
                                        </p>
                                    )}
                                </Menu>{' '}
                            </div>
                        )}
                        {error && assetCategory === 'service_provider' && !vendor?.name && (
                            <span className="text-xs text-[#FF0000] font-normal">Vendor is required</span>
                        )}
                    </div>
                    <div className="mt-3">
                        {assetCategory === 'application_inventory' && (
                            <div className="w-full mt-4">
                                <div className="flex items-center gap-2">
                                    <p className="text-[#64748B] font-medium text-[13px] mb-1">Asset Custodian</p>
                                    <Tooltip
                                        title={'The person using the assIet for their day to day activities'}
                                        placement="bottom-start"
                                        arrow
                                        componentsProps={{
                                            tooltip: {
                                                sx: {
                                                    bgcolor: '#000000',
                                                    color: 'white',
                                                },
                                                '& .MuiTooltip-arrow': {
                                                    background: '#000000',
                                                },
                                            },
                                        }}
                                        sx={{
                                            width: '100px',
                                            '& .MuiTooltip-arrow': {
                                                background: '#000000',
                                            },
                                        }}
                                    >
                                        <HelpOutlineIcon sx={{ width: 15, mt: -0.5, cursor: 'pointer' }} />
                                    </Tooltip>
                                </div>
                                <button
                                    aria-controls={custodianAnchorEl ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={custodianAnchorEl ? 'true' : undefined}
                                    onClick={custodianOpenMenu}
                                    className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded py-2.5 !px-2.5 transition-colors w-full flex justify-between items-center"
                                >
                                    <p className="text-[#64748B] font-medium text-[13px]">
                                        {custodianEmail?.value !== '' ? custodianEmail?.name : 'Select an option'}
                                    </p>

                                    <img
                                        src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                        alt="Arrow Down Icon"
                                        className="object-contain"
                                    />
                                </button>
                                <Menu
                                    open={Boolean(custodianAnchorEl)}
                                    anchorEl={custodianAnchorEl}
                                    onClose={custodianCloseMenu}
                                    PaperProps={{
                                        style: {
                                            width: 400,
                                        },
                                    }}
                                >
                                    <div className="px-3 py-1">
                                        <p className="text-[#64748B] font-medium text-[13px] mt-2">Search name</p>
                                    </div>
                                    <div className="relative px-3">
                                        <input
                                            placeholder="Search..."
                                            value={search}
                                            onChange={(event) => setSearch(event?.target?.value)}
                                            className="text-gray-400 text-sm font-normal placeholder:text-gray-400 border border-gray-200 py-2 rounded-[4px] w-[230px] px-[30px] w-full"
                                        />
                                        <img
                                            src={SearchIcon}
                                            alt="SearchIcon"
                                            className="absolute left-6 top-2.5 w-[17px]"
                                        />
                                    </div>
                                    {filtered_employees?.length ? (
                                        <div className="mt-3">
                                            {filtered_employees?.map((option) => (
                                                <MenuItem
                                                    sx={{
                                                        color:
                                                            custodianEmail?.value === option?.value
                                                                ? '#FFFFFF'
                                                                : '#64748B',
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                        mx: 2,
                                                        backgroundColor:
                                                            custodianEmail?.value === option?.value ? '#395BA9' : '',
                                                        '&:hover': {
                                                            color: '#64748B',
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        handleCustodianChange(option);
                                                        custodianCloseMenu();
                                                    }}
                                                >
                                                    {option.name}
                                                </MenuItem>
                                            ))}
                                        </div>
                                    ) : (
                                        <p className="flex justify-center items-center text-[#64748B] font-medium text-[13px] mt-2">
                                            No User Found
                                        </p>
                                    )}
                                </Menu>{' '}
                            </div>
                        )}
                        {error && assetCategory === 'application_inventory' && !custodianEmail?.name && (
                            <span className="text-xs text-[#FF0000] font-normal">Asset Custodian is required</span>
                        )}
                    </div>
                    {assetCategory !== 'people' && assetCategory !== 'service_provider' && (
                        <div className="mt-3">
                            <p className="text-[#64748B] font-medium text-[13px] mb-1">Asset Owner</p>
                            <button
                                aria-controls={groupAnchorEl ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={groupAnchorEl ? 'true' : undefined}
                                onClick={ownerOpenMenu}
                                className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded py-2.5 !px-2.5 transition-colors w-full flex justify-between items-center"
                            >
                                <p className="text-[#64748B] font-medium text-[13px]">
                                    {ownerEmail?.value !== '' ? ownerEmail?.name : 'Select an option'}
                                </p>

                                <img
                                    src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                    alt="Arrow Down Icon"
                                    className="object-contain"
                                />
                            </button>
                            <Menu open={Boolean(ownerAnchorEl)} anchorEl={ownerAnchorEl} onClose={ownerCloseMenu}>
                                <div className="px-3 py-1">
                                    <p className="text-[#64748B] font-medium text-[13px] mt-2">Search name</p>
                                </div>
                                <div className="relative px-3">
                                    <input
                                        placeholder="Search..."
                                        value={search}
                                        onChange={(event) => setSearch(event?.target?.value)}
                                        className="text-gray-400 text-sm font-normal placeholder:text-gray-400 border border-gray-200 py-2 rounded-[4px] w-[230px] px-[30px] w-full"
                                    />
                                    <img
                                        src={SearchIcon}
                                        alt="SearchIcon"
                                        className="absolute left-6 top-2.5 w-[17px]"
                                    />
                                </div>
                                {filtered_employees?.length ? (
                                    <div className="mt-3">
                                        {filtered_employees?.map((option) => (
                                            <MenuItem
                                                sx={{
                                                    color: ownerEmail?.value === option?.value ? '#FFFFFF' : '#64748B',
                                                    fontSize: '14px',
                                                    fontWeight: 500,
                                                    mx: 2,
                                                    backgroundColor:
                                                        ownerEmail?.value === option?.value ? '#395BA9' : '',
                                                    '&:hover': {
                                                        color: '#64748B',
                                                    },
                                                }}
                                                onClick={() => {
                                                    handleOwnerChange(option);
                                                    ownerCloseMenu();
                                                }}
                                            >
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </div>
                                ) : (
                                    <p className="flex justify-center items-center text-[#64748B] font-medium text-[13px] mt-2">
                                        No User Found
                                    </p>
                                )}
                            </Menu>{' '}
                            {error && !ownerEmail?.value && (
                                <span className="text-xs text-[#FF0000] font-normal">Asset Owner is required</span>
                            )}
                        </div>
                    )}
                    <div className="mt-3">
                        {' '}
                        <p className="text-[#64748B] font-medium text-[13px] mb-1">Group asset in</p>
                        <button
                            aria-controls={groupAnchorEl ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={groupAnchorEl ? 'true' : undefined}
                            onClick={groupOpenMenu}
                            className="!border !border-[#E2E8F0] flex items-center gap-1.5 rounded py-2.5 !px-2.5 transition-colors w-full flex justify-between items-center"
                        >
                            <p className="text-[#64748B] font-medium text-[13px]">
                                {group?.value !== '' ? group?.name : 'Select a group'}
                            </p>

                            <img
                                src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                                alt="Arrow Down Icon"
                                className="object-contain"
                            />
                        </button>
                        <Menu
                            open={Boolean(groupAnchorEl)}
                            anchorEl={groupAnchorEl}
                            onClose={groupCloseMenu}
                            sx={{ p: 6 }}
                        >
                            {' '}
                            <div className="px-3 py-1">
                                <p className="text-[#64748B] font-medium text-[13px] mt-2">Search name</p>
                            </div>
                            <div className="relative px-3">
                                <input
                                    placeholder="Search..."
                                    value={search}
                                    onChange={(event) => setSearch(event?.target?.value)}
                                    className="text-gray-400 text-sm font-normal placeholder:text-gray-400 border border-gray-200 py-2 rounded-[4px] w-[230px] px-[30px] w-full"
                                />
                                <img src={SearchIcon} alt="SearchIcon" className="absolute left-6 top-2.5 w-[17px]" />
                            </div>
                            {filtered_all_merchant_asset_groups?.length ? (
                                <div>
                                    {filtered_all_merchant_asset_groups?.map((option) => (
                                        <MenuItem
                                            sx={{
                                                color: group?.value === option?.id ? '#FFFFFF' : '#64748B',
                                                fontSize: '14px',
                                                fontWeight: 500,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                backgroundColor: group?.value === option?.id ? '#395BA9' : '',
                                                '&:hover': {
                                                    color: '#64748B',
                                                },
                                            }}
                                            onClick={() => {
                                                handleGroupChange(option);
                                                groupCloseMenu();
                                            }}
                                        >
                                            <Typography>{option.name}</Typography>
                                            <EditIcon
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    openGroupUpload('edit', option, option?.id);
                                                    groupCloseMenu();
                                                }}
                                            />
                                        </MenuItem>
                                    ))}
                                </div>
                            ) : (
                                <p className="flex justify-center items-center text-[#64748B] font-medium text-[13px] mt-2">
                                    No group available
                                </p>
                            )}
                            <div
                                className="flex justify-left items-center gap-2 mt-2 px-2 cursor-pointer"
                                onClick={() => {
                                    openGroupUpload('add');
                                    groupCloseMenu();
                                }}
                            >
                                <AddIcon sx={{ color: '#395BA9' }} />
                                <p className=" text-[#395BA9] font-medium text-[13px]">Add new group</p>
                            </div>
                        </Menu>
                    </div>
                    {/* {type === 'local' && ( */}
                    {assetType === 'local' && (
                        <Typography sx={{ color: '#64748B', fontSize: '13px', fontWeight: 400, mt: 1 }}>
                            <strong>Local asset</strong> requires you to{' '}
                            <strong className="text-[#395BA9] underline cursor-pointer" onClick={agentOpenMenu}>
                                download our agent
                            </strong>{' '}
                            <Menu
                                open={Boolean(agentAnchorEl)}
                                anchorEl={agentAnchorEl}
                                onClose={agentCloseMenu}
                                PaperProps={{
                                    style: {
                                        width: 300,
                                    },
                                }}
                            >
                                <MenuItem
                                    sx={{
                                        color: '#64748B',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        '&:hover': {
                                            color: '#64748B',
                                        },
                                    }}
                                >
                                    <a
                                        href="https://secure-gen.s3.amazonaws.com/winserver_exe/windows_server.zip"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div className="flex items-center gap-1">
                                            <img src={WindowsIcon} alt="WindowsIcon" />{' '}
                                            <p className="text-[#64748B] text-sm font-medium">Download for Windows</p>
                                        </div>
                                    </a>
                                </MenuItem>
                                <MenuItem
                                    sx={{
                                        color: '#64748B',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        '&:hover': {
                                            color: '#64748B',
                                        },
                                    }}
                                >
                                    <a
                                        href="https://secure-gen.s3.amazonaws.com/linuxserver_exe/windows_server.zip"
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <div className="flex items-center gap-1">
                                            <img src={LinuxIcon} alt="LinuxIcon" />{' '}
                                            <p className="text-[#64748B] text-sm font-medium">Download for Linux</p>
                                        </div>
                                    </a>
                                </MenuItem>
                            </Menu>
                            and install the agent in order to perform vulnerability scan on them.
                        </Typography>
                    )}
                    {/* )} */}
                    {/* {type === 'public' && ( */}
                    {assetType === 'local' && (
                        <FormControlLabel
                            label={
                                <p className="text-[13px] text-[#64748B] font-medium ">
                                    {details?.api_compliance
                                        ? 'Rerun API compliance on asset'
                                        : 'Perform API compliance on asset'}
                                </p>
                            }
                            control={
                                <Checkbox
                                    checked={vul_scan}
                                    onChange={(event) => setVulScan(event.target.checked)}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            }
                        />
                    )}

                    {assetType === 'local' && (
                        <FormControlLabel
                            className="my-[-0.5rem]"
                            label={
                                <p className="text-[13px] text-[#64748B] font-medium ">
                                    {details?.pci_scan
                                        ? 'Perform api compliance scan on asset'
                                        : 'Perform api compliance on asset'}
                                </p>
                            }
                            control={
                                <Checkbox
                                    checked={api_compliance}
                                    onChange={(event) => setApiCompliance(event.target.checked)}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            }
                        />
                    )}
                    {assetType === 'local' && (
                        <FormControlLabel
                            className="my-[-0.5rem]"
                            label={
                                <p className="text-[13px] text-[#64748B] font-medium ">
                                    {details?.pci_scan ? 'Perform pci scan on asset' : 'Perform pci scan on asset'}
                                </p>
                            }
                            control={
                                <Checkbox
                                    checked={pci_scan}
                                    onChange={(event) => setPciScan(event.target.checked)}
                                    sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                                />
                            }
                        />
                    )}
                </Box>
                {/* )} */}
                <FormControlLabel
                    label={<p className="text-[13px] text-[#64748B] font-medium ">Run risk assessment on this asset</p>}
                    control={
                        <Checkbox
                            checked={risk_assessment}
                            onChange={(event) => setRiskAssessment(event.target.checked)}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 20 } }}
                        />
                    }
                />
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    paddingLeft: '18rem',
                    backgroundColor: '#F8FAFC',
                    width: '100% !important',
                    display: 'flex',
                    py: 2.5,
                }}
            >
                <Button
                    variant="outlined"
                    sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit' }}
                    onClick={handleClose}
                >
                    Close
                </Button>
                <AppLoadingButton
                    text="Add Asset"
                    variant="contained"
                    color="primary"
                    loading={loadingState}
                    loadingPosition="center"
                    type="button"
                    onClick={handleSubmit}
                    sx={{ borderRadius: 1 }}
                />
            </Box>
        </AppDrawer>
    );
};

export default InfrastructureModalModal;
