import React, { useState, useEffect } from 'react';
import { Box, Typography, FormHelperText } from '@mui/material';
import { toast } from 'react-toastify';
import { useFormikContext } from 'formik';
import { accept } from 'validate';
import XslIcon from 'assets/img/xsl.svg';
import PdfIcon from 'assets/img/pdf.svg';
import DocxIcon from 'assets/img/pdf.svg';
import PptIcon from 'assets/img/pdf.svg';
import TxtIcon from 'assets/img/pdf.svg';
import FolderIcon from 'assets/img/folder.svg';

export const AppFormDragFileUpload = ({ name, label }) => {
    const [fileBase64URL, setFileBase64URL] = useState(null);
    const [uploadedFile, setUploadedFile] = useState(null); // State for storing the uploaded file

    const { excel } = accept;

    const { setFieldValue, errors, values, touched } = useFormikContext();

    // Function to upload the file
    const uploadImage = (e) => {
        handleFile(e.target.files[0]);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        handleFile(e.dataTransfer.files[0]);
    };

    const handleFile = (file) => {
        const fileSize = file?.size / 1000 / 1000;
        if (fileSize > 5) {
            toast.info('File too large, minimum of 5MB');
        } else {
            setFieldValue(name, file);
            setUploadedFile(file); // Set uploaded file in state
        }
    };

    const dragEnter = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#C4C4C4';
        target.style.backgroundColor = '#A58980';
    };

    const dragLeave = () => {
        const target = document.getElementById('drop-zone');
        target.style.borderColor = '#A58980';
        target.style.backgroundColor = 'transparent';
    };

    const dragOver = (e) => {
        e.preventDefault();
    };

    // Use effect to update base64 URL when file changes
    useEffect(() => {
        if (!values?.[name]) return setFileBase64URL(null);
        const reader = new FileReader();
        reader.onload = () => {
            setFileBase64URL(reader.result);
        };
        reader.readAsDataURL(values?.[name]);
    }, [values?.[name]]);

    // Function to return the appropriate icon based on file extension
    const getFileIcon = (file) => {
        const extension = file.name.split('.').pop();
        switch (extension) {
            case 'xlsx':
            case 'xls':
                return <img src={XslIcon} alt="Excel icon" />;
            case 'pdf':
                return <img src={PdfIcon} alt="PDF icon" />;
            case 'docx':
            case 'doc':
                return <img src={DocxIcon} alt="Word icon" />;
            case 'pptx':
            case 'ppt':
                return <img src={PptIcon} alt="PowerPoint icon" />;
            case 'txt':
                return <img src={TxtIcon} alt="Text icon" />;
            default:
                return null;
        }
    };
    console.log(fileBase64URL);

    return (
        <>
            <label
                htmlFor={label}
                className={`block text-[14px] ${errors[name] ? 'text-[#D91B1B]' : 'text-[#15161A]'} mt-3 `}
            >
                {label}
            </label>
            <Box
                sx={{
                    border: '1px dashed #CBD5E1',
                    borderRadius: 2,
                    padding: 2,
                    height: '144px',
                    position: 'relative',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Box
                    id="drop-zone"
                    onDragEnter={dragEnter}
                    onDragLeave={dragLeave}
                    onDrop={handleDrop}
                    onDragOver={dragOver}
                    sx={{ height: '100%', p: 2 }}
                >
                    <Box
                        sx={{
                            position: 'absolute',
                            bottom: 15,
                            inset: 0,
                            textAlign: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <label htmlFor="file-input">
                            <Box
                                sx={{
                                    borderRadius: '2px',
                                    padding: 1,
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <img src={FolderIcon} alt="FolderIcon" />
                                <span className="text-[#64748B] text-[12px] font-medium">
                                    Drop your files or click to upload
                                </span>
                                <span className="text-[#94A3B8] text-[12px]">Supported file types: EXCEL</span>
                                <div className="w-[60px] h-[24px] flex items-center justify-center text-[#475569] rounded-[2px] mt-[8px] text-[12px] border-[1px] border-solid border-[#E2E8F0]">
                                    Browse
                                </div>
                            </Box>
                        </label>
                        <input type="file" accept={`${excel}`} onChange={uploadImage} hidden id="file-input" />
                    </Box>
                </Box>
            </Box>

            {/* Show error if no file is uploaded */}
            {!values?.[name] && touched[name] && <FormHelperText error>Please upload a file</FormHelperText>}

            {/* Show uploaded file name and icon */}
            {uploadedFile && (
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        mt: 3,
                    }}
                >
                    {getFileIcon(uploadedFile)}
                    <Box sx={{ ml: 1 }}>
                        <Typography
                            sx={{ color: '#475569', fontWeight: 400, fontSize: '14px', wordBreak: 'break-word' }}
                        >
                            {uploadedFile.name}
                        </Typography>
                    </Box>
                </Box>
            )}
        </>
    );
};
