import React, { useState, useMemo } from 'react';
import { toast } from 'react-toastify';
import { Typography, Stack } from '@mui/material';

//core component
// import AppModal from 'components/new_components/AppModal';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppFormSelect, AppSubmitButton, AppForm } from 'components/new_components/forms';
import AppCheckboxInput from 'components/new_components/AppCheckboxInput';
import { scheduleOptions } from './utils/constant';
import closeModal from '../../../assets/img/closeModal.svg';
import bulb from '../../../assets/img/bulb2.svg';
//validate
// import { requestModalValidation } from './utils/validation';
// import { pattern } from 'validate';

//redux
import { connect } from 'react-redux';
import { RequestASVscan, UpdateASVScanRequest } from 'store/actions/merchantActions';
import FormTooltip from 'components/new_components/FormTooltip';

//translations
import { useTranslation } from 'react-i18next';

const ChangeSchedulingModal = ({ open, handleClose, payload, type, RequestASVscan, UpdateASVScanRequest, id }) => {
    const [authorization, setAuthorization] = useState(false);
    const [loadingState, setLoadingState] = useState(false);

    //translation
    const { t } = useTranslation('pciscans');

    const sortedScheduleOptions = useMemo(() => {
        let filteredOptions;
        if (type === 'update') {
            filteredOptions = scheduleOptions?.filter((option) => option.name !== 'Run Now');
        } else {
            filteredOptions = scheduleOptions;
        }
        return filteredOptions;
    }, [scheduleOptions, type]);

    //functions
    const handleAuthorizationCheck = () => {
        setAuthorization(!authorization);
    };

    const handleSubmit = async (values) => {
        if (authorization) {
            setLoadingState(true);
            const res =
                type === 'request'
                    ? await RequestASVscan({ ...values, authorization })
                    : await UpdateASVScanRequest(id, { schedule: values?.schedule });

            setLoadingState(false);
            if (res?.success) {
                toast.success(type === 'request' ? 'Successful request' : 'Schedule Updated Successfully');
                handleClose();
            } else {
                if (res?.message !== 'Upsell' && res?.message !== 'Paywall') {
                    toast.error(res?.message);
                }
            }
        } else {
            toast.info('You have to authorize this request!');
        }
    };

    return (
        <AppForm
            initialValues={{
                schedule: payload.schedule,
            }}
            onSubmit={handleSubmit}
            // validate={requestModalValidation}
        >
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                title={
                    <div className="flex items-center justify-between w-full h-[52px]">
                        <Typography sx={{ fontSize: '24px', fontWeight: 600, color: '#202D66' }}>
                            {type === 'request' ? t('pciRequestModal.title1') : t('pciRequestModal.title2')}
                        </Typography>
                        <div
                            onClick={handleClose}
                            className="w-[24px] h-[24px] flex items-center justify-center cursor-pointer border-[1px] border-solid border-[#E2E8F0] rounded-full"
                        >
                            <img src={closeModal} alt="close" />
                        </div>
                    </div>
                }
                subtitle={
                    <Stack
                        flexDirection="row"
                        justifyContent="flex-start"
                        sx={{
                            py: '8px',
                            px: '16px',
                            fontSize: 13,
                            fontWeight: 400,
                            color: '#64748B',
                            background: '#F8FAFC',
                            borderRadius: '4px',
                            mt: '24px',
                            display: 'flex',
                            gap: '10px',
                            alignItems: 'flex-start',
                            border: '1px solid #F0F0FA',
                        }}
                    >
                        <img src={bulb} alt="bulb" />
                        <span>This section helps you change a scan's PCI schedule.</span>
                    </Stack>
                }
                width={511}
                actions={
                    <div className="flex items-center justify-end w-[511px] gap-[8px] bg-[#F8FAFC] mt-[40px]  h-[84px] -ml-[25px]  -mb-[5px]">
                        <div className="flex items-center justify-end gap-[8px] w-full pr-[35px]">
                            <button
                                type="cancel"
                                onClick={handleClose}
                                className="bg-white flex items-center cursor-pointer justify-center text-[14px] w-[84px] rounded-[2px] h-[36px] font-medium text-[#334155] border-[1px] border-[#E2E8F0] border-solid"
                            >
                                Cancel
                            </button>
                            <AppSubmitButton
                                type="submit"
                                text={type === 'request' ? t('pciRequestModal.button1') : t('pciRequestModal.button2')}
                                variant="contained"
                                color="primary"
                                loading={loadingState}
                                loadingPosition="center"
                                style={{ width: '84px', height: '36px', boxShadow: 'none' }}
                            />
                        </div>
                    </div>
                }
            >
                <AppFormSelect
                    name="schedule"
                    label={
                        <>
                            <span className="mr-1">{t('pciRequestModal.schedule')}</span>
                            <FormTooltip placement="top" text={t('pciRequestModal.scheduleTip')} />
                        </>
                    }
                    options={sortedScheduleOptions}
                    defaultValue={t('pciRequestModal.scheduleValue')}
                />
                <AppCheckboxInput
                    name="authorization"
                    label={t('pciRequestModal.authorization')}
                    onChange={handleAuthorizationCheck}
                    value={authorization}
                />
            </AppCenteredModal>
        </AppForm>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
    };
};

export default connect(mapStateToProps, { RequestASVscan, UpdateASVScanRequest })(ChangeSchedulingModal);
