import { Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
// core component
import AppLinearProgress from 'components/new_components/AppLinearProgress';

//translation
import { useTranslation } from 'react-i18next';

const ComplianceProgressBar = (props) => {
    const { percent, label, standard, isCategoryAssigned, isTaskMissed } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('compliance');
    return (
        <Stack sx={{ posiiton: 'relative' }}>
            {standard !== 'sslc' && (
                <Typography
                    sx={{
                        alignSelf: 'flex-end',
                        fontWeight: standard === 'soc2' ? 400 : 500,
                        fontSize: standard === 'soc2' ? 11 : 12,
                        color: standard === 'soc2' ? '#64748B' : theme.palette.primary[900],
                    }}
                >
                    <Typography
                        component="span"
                        sx={{
                            fontWeight: standard === 'soc2' ? 700 : 700,
                            fontSize: standard === 'soc2' ? 11 : 12,
                            color: standard === 'soc2' ? '#64748B' : theme.palette.primary[900],
                        }}
                    >
                        {percent?.toFixed(2) || 0}%
                    </Typography>{' '}
                    {standard === 'soc2' ? 'done' : label || t('completed')}
                </Typography>
            )}

            {isCategoryAssigned &&
            (standard === 'sslc' ||
                standard === 'iso9001' ||
                standard === 'soc2' ||
                standard === 'tech_sec' ||
                standard === 'ismi') ? (
                <Typography
                    component="span"
                    variant="span"
                    className="w-fit"
                    sx={{
                        color: theme.palette.white.main,
                        background: isTaskMissed ? '#FF5449' : theme.palette.tertiary[70],
                        fontSize: 12,
                        px: 0.5,
                        py: 0.3,
                        fontWeight: 600,
                        borderRadius: 1,
                    }}
                >
                    {isTaskMissed ? 'Task Missed' : 'Assigned to you'}
                </Typography>
            ) : null}
            <AppLinearProgress value={percent} sx={{ my: 1, color: '#395BA9' }} />
        </Stack>
    );
};

export default ComplianceProgressBar;
