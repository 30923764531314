import { useContext, useEffect, useMemo, useState } from 'react';
// core components
import { Button, useTheme } from '@mui/material';
import PageHeader from 'components/new_components/PageHeader';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import Infrastructures from 'views/merchant/Inventory/Infrastructures';
import Integrations from '../Integrations';
import IntegrationsLayout from '../IntegrationsLayout/IntegrationsLayout';

//translation
import { PlusSquareOutlined } from '@ant-design/icons';
import AppArticle from 'components/new_components/AppArticle';
import { connect, useSelector } from 'react-redux';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';

const Setup = (props) => {
    const { all_article_header } = props;

    const theme = useTheme();
    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [modal, setModal] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    const { toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const userguide_info = useSelector((state) => state?.guideReducers?.userguide_info?.[0]?.infrastructures);

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Integrations'),
        [all_article_header]
    );

    // functions
    const openModal = () => setModal(true);
    const closeModal = () => setModal(false);
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, infrastructures: false });
        setOpenArticle(false);
    };

    useEffect(() => {
        toggleUserGuide.infrastructures ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.infrastructures]);

    const RequestToolButton = () => {
        return (
            <Button
                sx={{
                    background: 'transparent',
                    textTransform: 'capitalize',
                    color: theme.palette.primary[20],
                    display: 'flex',
                    gap: 0.5,
                    alignItems: 'center',
                }}
                onClick={openModal}
            >
                <PlusSquareOutlined className="text-[#202d66]" />
                Request for a tool
            </Button>
        );
    };

    return (
        <div>
            <PageHeader browserTitle="Setup | Smartcomply" />
            <IntegrationsLayout
                onTabChange={handleTabChange}
                tab={currentTab}
                tabAction={currentTab === 0 && <RequestToolButton />}
                open={modal}
                closeToolModal={closeModal}
            >
                <AppTabPanel value={currentTab} index={0}>
                    <Integrations />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={1}>
                    <Infrastructures />
                </AppTabPanel>
            </IntegrationsLayout>
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Integrations"
                headerId={oneArticle?.id}
                icon={true}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};
export default connect(mapStateToProps, {})(Setup);
