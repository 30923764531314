import React, { useState } from 'react';
import { toast } from 'react-toastify';

// redux
import { useDispatch } from 'react-redux';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { Box, Button, Stack, useTheme } from '@mui/material';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import { UpdateRiskiskAssessment } from 'store/actions/riskAssessmentActions';

const ApproveRiskModal = (props) => {
    const { open, handleClose, id, params, approvedComment, setApprovedComment } = props;

    const [updating, setUpdating] = useState(false);
    const dispatch = useDispatch();
    const theme = useTheme();

    //function
    const UpdateRisk = async () => {
        setUpdating(true);
        const body = {
            risk_assessment_status: 'disapproved',
            approval_comment: approvedComment,
        };
        const res = await dispatch(UpdateRiskiskAssessment(body, id, params));
        setUpdating(false);
        if (res?.success) {
            toast.success('Risk approval success.');
            handleClose();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <AppCenteredModal
            open={open}
            handleClose={handleClose}
            width={450}
            actions={
                <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mt: 3, mb: 1.5, gap: 1 }}>
                    <Button
                        variant="text"
                        sx={{
                            backgroundColor: '#FFFFFF !important',
                            color: '#334155 !important',
                            textTransform: 'inherit !Important',
                            border: '1px solid #E2E8F0 !important',
                            px: 2,
                            py: 1,
                            borderRadius: 1,
                            '&:hover': {
                                backgroundColor: '#FFFFFF !important',
                            },
                        }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>

                    <AppLoadingButton
                        text={'Yes, mark as disapproved'}
                        variant="contained"
                        loading={updating}
                        loadingPosition="center"
                        onClick={UpdateRisk}
                        disabled={!approvedComment}
                        sx={{
                            backgroundColor: '#FF0000',
                            color: '#FFFFFF !important',
                            textTransform: 'inherit',
                            '&:hover': {
                                backgroundColor: '#FF0000',
                            },
                        }}
                    />
                </Stack>
            }
        >
            <Box
                sx={{
                    fontWeight: 500,
                    color: theme.palette.gray[800],
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    textAlign: 'center',
                    mt: 2,
                }}
            >
                <span style={{ color: '#475569', fontSize: '18px', fontWeight: 600 }}>Disapprove mitigation plan?</span>

                <p
                    style={{
                        color: '#64748B',
                        fontSize: '12px',
                        fontWeight: 400,
                        marginTop: '0.5rem',
                    }}
                >
                    This means the risk mitigation plan will be marked as disapproved.. Are you sure you want to perform
                    this action
                </p>
                <textarea
                    rows={3}
                    placeholder="Comment"
                    value={approvedComment}
                    onChange={(event) => setApprovedComment(event?.target?.value)}
                    className="text-gray-400 text-sm font-normal placeholder:text-gray-400 border border-gray-200 py-2 rounded-[4px] w-full px-2"
                />
            </Box>
        </AppCenteredModal>
    );
};
export default ApproveRiskModal;
