import { Dialog, Menu, MenuItem } from '@mui/material';
import { Loader2 } from 'lucide-react';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { AddNewThreat } from 'store/actions/riskAssessmentActions';

const NewThreat = ({ riskFirstPageStates }) => {
    // state
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [validate, setValidate] = useState(false);
    const [name, setName] = useState('');
    const [search, setSearch] = useState('');
    const [threatAnchorEl, setThreatAnchorEl] = useState('');
    const [threatCategory, setThreatCategory] = useState({
        name: '',
        value: '',
    });
    const [tempThreat, setTempThreat] = useState('');

    // redux
    const dispatch = useDispatch();
    const { all_risk_threat_categories } = useSelector((state) => state.riskAssessmentReducers);

    const handleCreateCategory = async () => {
        if (!name) {
            setValidate(true);
        } else {
            setLoading(true);
            const body = {
                name,
                tag: 'custom',
                risk_category: riskFirstPageStates?.category,
                threat_category: threatCategory?.value,
            };
            const res = await dispatch(AddNewThreat(body));
            setLoading(false);
            if (res?.success) {
                setName('');
                setIsDialogOpen(false);
            } else {
                toast.error(res?.message);
            }
        }
    };

    return (
        <div onMouseLeave={() => setTempThreat('')}>
            <button
                className="flex items-center gap-1 !py-1.5 bg-white !px-2 hover:brightness-95 transition-all"
                onClick={() => setIsDialogOpen(true)}
            >
                <img src="/img/risk-assessment/add-light.svg" alt="Plus Icon" className="object-contain" />

                <p className="font-medium text-[13px] text-[#395BA9]">Add new Threat</p>
            </button>

            <Dialog
                open={isDialogOpen}
                onClose={() => setIsDialogOpen(false)}
                PaperProps={{
                    sx: {
                        borderRadius: '4px !important',
                        padding: 0,
                        boxShadow: '0px 16px 24px 0px #00000026',
                        width: '406px',
                    },
                }}
            >
                <div className="flex items-center justify-between px-6 !pt-6">
                    <h5 className="text-lg font-semibold text-[#202D66]">New Threat</h5>

                    <button
                        className="border-[#E2E8F0] border rounded-full !p-1 h-6 w-6 grid place-items-center hover:bg-[#E2E8F0] transition-colors"
                        onClick={() => setIsDialogOpen(false)}
                    >
                        <img src="/img/automated-scan/close.svg" alt="close icon" className="object-contain" />
                    </button>
                </div>

                <div className="!p-6 flex flex-col gap-2">
                    <label htmlFor="new-category-input" className="font-medium text-[13px] text-[#64748B] !m-0">
                        Threat Name
                    </label>

                    <input
                        type="text"
                        placeholder="Enter threat name"
                        value={name}
                        maxLength={40}
                        onChange={(event) => setName(event.target.value)}
                        className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                    />

                    <div className={`flex items-center ${validate && !name ? 'justify-between' : 'justify-end'}`}>
                        {validate && !name && (
                            <span className="text-xs text-[#FF0000] font-normal">Threat name is required</span>
                        )}
                        <p className="text-[#64748B] font-normal text-xs">{name.length} of 40 characters left</p>
                    </div>
                </div>
                <div className="!px-6 !py-3 flex flex-col gap-2">
                    <label htmlFor="new-category-input" className="font-medium text-[13px] text-[#64748B] !m-0">
                        Threat Category
                    </label>
                    <button
                        aria-controls={tempThreat ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={tempThreat ? 'true' : undefined}
                        onClick={(event) => {
                            event.stopPropagation();
                            setThreatAnchorEl(event.currentTarget);
                        }}
                        className="!border !border-[#E2E8F0] flex items-center justify-between !w-full !rounded-sm !p-2 hover:bg-[#E2E8F0] transition-colors h-9"
                    >
                        <p
                            className={`font-normal text-sm ${
                                threatCategory?.name ? 'capitalize text-[hsl(215,20%,40%)]' : 'text-[#94A3B8]'
                            }`}
                        >
                            {threatCategory?.name ? threatCategory?.name : 'Select threat category'}
                        </p>

                        <img
                            src="/img/risk-assessment/material-symbols_keyboard-arrow-down-rounded.svg"
                            alt="Arrow Down Icon"
                            className="object-contain"
                        />
                    </button>

                    <Menu
                        anchorEl={threatAnchorEl}
                        open={Boolean(threatAnchorEl)}
                        onClose={() => setThreatAnchorEl(null)}
                        sx={{
                            '& .MuiPaper-root': {
                                borderRadius: '4px',
                                boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                pb: -2,
                            },
                            '& .MuiList-root': {
                                padding: 0,
                            },
                        }}
                    >
                        <div className="!py-[5px] !px-3 flex flex-col gap-2.5">
                            <p className="border-b border-[#F1F5F9] !py-1 !px-3 text-[#64748B] font-medium text-xs">
                                Search name
                            </p>

                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search"
                                    className="w-full !py-2.5 !pr-2.5 !pl-8 !border !border-[#E2E8F0] h-7 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] !rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                                    value={search}
                                    onChange={(event) => {
                                        event.stopPropagation();
                                        setSearch(event.target.value);
                                    }}
                                />

                                <img
                                    src="/img/risk-assessment/ri_search-line-small.svg"
                                    alt="Search Icon"
                                    className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                                />
                            </div>
                        </div>
                        {all_risk_threat_categories?.length ? (
                            <div className="!p-2 flex flex-col w-fit">
                                {all_risk_threat_categories?.map((threat) => (
                                    <MenuItem
                                        key={threat?.id}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            setThreatCategory({
                                                name: threat?.name,
                                                value: threat?.id,
                                            });
                                            setThreatAnchorEl(null);
                                        }}
                                        sx={{
                                            color: '#64748B',
                                            fontWeight: 500,
                                            fontSize: '13px',
                                            textTransform: 'capitalize',
                                            padding: '6px 8px',
                                        }}
                                    >
                                        {threat?.name}
                                    </MenuItem>
                                ))}
                            </div>
                        ) : (
                            <p className="flex items-center justify-center my-4 text-sm text-[#64748B] font-medium">
                                Threat category not found
                            </p>
                        )}
                    </Menu>
                </div>

                <div className="flex items-center justify-end !p-6 bg-[#F8FAFC] gap-2">
                    <button
                        className="bg-[#fff] border border-[#E2E8F0] rounded-sm !px-4 !py-2 hover:bg-red-500 hover:text-white transition-colors text-[#334155] text-sm font-medium"
                        onClick={() => setIsDialogOpen(false)}
                    >
                        Cancel
                    </button>

                    <button
                        className="bg-[#202D66] rounded-sm !px-4 !py-2 hover:bg-[#151e44] transition-all text-white text-sm font-semibold disabled:opacity-50 disabled:pointer-events-none flex gap-2 items-center"
                        onClick={handleCreateCategory}
                        disabled={!name || !threatCategory?.name}
                    >
                        {loading && <Loader2 className="mr-1 h-4 w-4 animate-spin" />} Add
                    </button>
                </div>
            </Dialog>
        </div>
    );
};
export default NewThreat;
