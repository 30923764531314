import {
    ALL_PORTFOLIOS,
    AUDITOR_CERTIFICATIONS,
    AUDITOR_MERCHANTS,
    ONE_AUDITOR,
    AUDITOR_DETAILS,
    AUDITOR_MERCHANT_DOC_MESSAGES,
    AUDITOR_MERCHANT_DOCUMENTS,
    AUDITOR_MERCHANT_DOCUMENTS_VERSIONS,
    AUDITOR_MERCHANT_COMPLIANCE_SUMMARY,
    AUDITOR_MERCHANT_PCIDSS4POINT0_SUMMARY,
    AUDITOR_MERCHANT_FORMS,
    MERCHANT_PENTEST_REQUESTS,
    MERCHANT_SERVER_RESULT_SCAN,
    MERCHANT_PENTEST_UPLOADS,
    MERCHANT_ASV_SCANS,
    AUDITOR_MERCHANT_ASVSCANS_UPLOADS,
    ALL_MERCHANT_SCANS_EXCEPTIONS,
    ALL_MERCHANT_EXEMPTED_SCANS,
    AUDITOR_MERCHANT_NDPR_GENERAL_SUMMARY,
    AUDITOR_MERCHANT_VULNERABILITY_SCANS,
    AUDITOR_MERCHANT_SOC2_GENERAL_SUMMARY,
    AUDITOR_MERCHANT_ISO_GENERAL_SUMMARY,
} from '../constants';
import axiosInstance from '../../utils/https';

// new endpoints
export const getOneAuditor = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/auditors/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ONE_AUDITOR, payload: res?.data });
        return {
            status: true,
            message: res?.message,
        };
    } catch (err) {
        return {
            success: false,
            message: err?.message,
        };
    }
};
export const GetAuditorDetails = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/employees/${id}/`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: AUDITOR_DETAILS, payload: res?.data });
        return {
            status: true,
            message: res?.message,
        };
    } catch (err) {
        return {
            success: false,
            message: err?.message,
        };
    }
};
export const updateAuditorPersonalDetails = (credentials) => async (dispatch, getState) => {
    const auditor_id = getState().authReducers?.user_details?.user?.organization?.id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/auditors/${auditor_id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(getOneAuditor(auditor_id));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return {
            success: false,
            message: err?.message,
        };
    }
};
export const GetAllPortfolios = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.user_details?.user?.organization?.id;
    try {
        const res = await axiosInstance().get(`/user_mgt/portfolios/?auditor=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_PORTFOLIOS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return {
            success: false,
            message: err?.message,
        };
    }
};
export const AddPortfolio = (credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.user_details?.user?.organization?.id;
    credentials.append('auditor', company_id);
    try {
        const res = await axiosInstance().post(`/user_mgt/portfolios/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllPortfolios());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return {
            success: false,
            message: err?.message,
        };
    }
};
export const EditPortfolio = (port_id, credentials) => async (dispatch, getState) => {
    const company_id = getState().authReducers?.user_details?.user?.organization?.id;
    credentials.append('auditor', company_id);
    try {
        const res = await axiosInstance().patch(`/user_mgt/portfolios/${port_id}/`, credentials);
        console.log('res', res);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetAllPortfolios());
        return {
            success: true,
            message: res.message,
        };
    } catch (err) {
        return {
            success: false,
            message: err.message,
        };
    }
};
export const DeletePortfolio = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/user_mgt/portfolios/${id}/`);
        if (!res?.ok) throw new Error(res?.statusText);
        dispatch(GetAllPortfolios());
        return {
            success: true,
            message: 'Successfully deleted a portfolio',
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const getAuditorCertifications = () => async (dispatch, getState) => {
    const company_id = getState().authReducers?.user_details?.user?.organization?.id;
    try {
        const res = await axiosInstance().get(`/user_mgt/certificates/?auditor=${company_id}`);
        if (res?.status?.toLowerCase() !== 'success') console.log(res?.status);
        dispatch({ type: AUDITOR_CERTIFICATIONS, payload: res?.data });
        return {
            status: true,
            message: res.message,
        };
    } catch (err) {
        return {
            status: false,
            message: err.message,
        };
    }
};
export const addCertification = (credentials) => async (dispatch, getState) => {
    const auditor_id = getState().authReducers?.user_details?.user?.organization?.id;
    try {
        const res = await axiosInstance().post(`/user_mgt/certificates/`, {
            ...credentials,
            auditor: auditor_id,
        });
        if (res?.status === 'fail') throw new Error(res?.message);
        dispatch(getAuditorCertifications());
        return {
            status: 'success',
            message: res?.message,
        };
    } catch (err) {
        return {
            status: 'failed',
            message: err.message,
        };
    }
};
export const updateCertification = (credentials, id) => async (dispatch, getState) => {
    const auditor_id = getState().authReducers?.user_details?.user?.organization?.id;
    try {
        const res = await axiosInstance().patch(`/user_mgt/certificates/${id}/`, {
            ...credentials,
            auditor: auditor_id,
        });
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(getAuditorCertifications());

        return {
            status: 'success',
            message: res.message,
        };
    } catch (err) {
        return { status: 'failed', message: err.message };
    }
};
export const deleteCertification = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().delete(`/user_mgt/certificates/${id}`);
        if (!res?.ok) throw new Error(res?.statusText);
        dispatch(getAuditorCertifications());

        return {
            status: true,
            message: res.message,
        };
    } catch (err) {
        return { status: false, message: err.message };
    }
};
export const getAuditorMerchants = (id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/user_mgt/merchants/?auditor=${id}`);
        if (res?.status === 'fail') throw new Error(res?.message);
        dispatch({ type: AUDITOR_MERCHANTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//Compliance
export const GetAllAuditorMerchantDocuments = (merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/user_document/?merchant_id=${merchant_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: AUDITOR_MERCHANT_DOCUMENTS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

export const GetAuditorMerchantDocumentVersions = (doc_id, merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(
            `/compliance/user_document/?merchant_id=${merchant_id}&document_id=${doc_id}`
        );
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: AUDITOR_MERCHANT_DOCUMENTS_VERSIONS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetDocumentMessages = (doc_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/chat/messages/?topic=${doc_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: AUDITOR_MERCHANT_DOC_MESSAGES, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const AddDocumentMessage = (credentials) => async (dispatch) => {
    try {
        const res = await axiosInstance().post(`/chat/messages/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetDocumentMessages(credentials?.topic));
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAuditorMerchantComplianceSummary = (merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/general_summary/?merchant_id=${merchant_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: AUDITOR_MERCHANT_COMPLIANCE_SUMMARY,
            payload: res.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAuditorMerchantPcidss4Point0Summary = (merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/card4_general_summary/?merchant_id=${merchant_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: AUDITOR_MERCHANT_PCIDSS4POINT0_SUMMARY,
            payload: res.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAuditorMerchantNDPRGeneralSummary = (merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/ndpr_general_summary/?merchant_id=${merchant_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: AUDITOR_MERCHANT_NDPR_GENERAL_SUMMARY,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAuditorMerchantISOGeneralSummary = (merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/iso_general_summary/?merchant_id=${merchant_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: AUDITOR_MERCHANT_ISO_GENERAL_SUMMARY,
            payload: res.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAuditorMerchantSOC2GeneralSummary = (merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/soc2_general_summary/?merchant_id=${merchant_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: AUDITOR_MERCHANT_SOC2_GENERAL_SUMMARY,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAuditorMerchantForms = (merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/compliance/user_form/?merchant_id=${merchant_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: AUDITOR_MERCHANT_FORMS, payload: res.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//Penetration Tests
export const GetMerchantPentTestRequests = (merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/asvc_requests/Pentestrequest/?merchant_id=${merchant_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: MERCHANT_PENTEST_REQUESTS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UploadMerchantPentTestReport = (credentials, id, merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/asvc_requests/Pentestrequest/${id}/`, credentials);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantPentTestRequests(merchant_id));
        return {
            status: true,
            message: res?.message,
        };
    } catch (err) {
        return { status: false, message: err.message };
    }
};
export const GetMerchantPentTestUploads = (merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/asvc_requests/Pentestuploads/?merchant_id=${merchant_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: MERCHANT_PENTEST_UPLOADS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetMerchantAsvScans = (merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/asvc_requests/avscansrequest/?merchant_id=${merchant_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: MERCHANT_ASV_SCANS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const UploadMerchantAsvscanReport = (credentials, id, merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().patch(`/asvc_requests/avscansrequest/${id}/`, credentials);

        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch(GetMerchantAsvScans(merchant_id));
        return {
            status: true,
            message: res?.message,
        };
    } catch (err) {
        return { status: false, message: err.message };
    }
};
export const GetMerchantAsvScansUploads = (merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/asvc_requests/asvscanuploads/?merchant_id=${merchant_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: AUDITOR_MERCHANT_ASVSCANS_UPLOADS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
//Scans
export const GetMerhantServerScanResults = (merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/get_scans/?merchant=${merchant_id}`, {});
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: MERCHANT_SERVER_RESULT_SCAN, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllExemptedScans = (merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/excempted_scans/?merchant=${merchant_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_EXEMPTED_SCANS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
export const GetAllScanExceptions = (merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/exceptions/?merchant=${merchant_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({ type: ALL_MERCHANT_SCANS_EXCEPTIONS, payload: res?.data });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};

//VulnerabilityScans
export const GetMerchantVulnerabilityScans = (merchant_id) => async (dispatch) => {
    try {
        const res = await axiosInstance().get(`/scans/vunerabilty_scan/?merchant_id=${merchant_id}`);
        if (res?.status?.toLowerCase() !== 'success') throw new Error(res?.message);
        dispatch({
            type: AUDITOR_MERCHANT_VULNERABILITY_SCANS,
            payload: res?.data,
        });
        return {
            success: true,
            message: res?.message,
        };
    } catch (err) {
        return { success: false, message: err.message };
    }
};
