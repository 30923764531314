import AppUserGuide from 'components/new_components/AppUserGuide';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { steps } from './steps';

import { Add, MoreHoriz } from '@mui/icons-material';
//core components
import { Box, Button, ButtonGroup, Divider, Menu, MenuItem } from '@mui/material';
import AppTable from 'components/new_components/app-table';
// import AppButton from 'components/new_components/AppButton';
import AppTag from 'components/new_components/AppTags';
import RiskLevelsModal from './RiskLevelsModal';
import VendorDeleteModal from './VendorDeleteModal';
import VendorModal from './VendorModal';
import VendorsTableActions from './VendorsTableActions';
//redux
import { connect, useSelector } from 'react-redux';
import { GetAllVendors } from 'store/actions/vendorActions';

//utils
import { vendorResponseInitialValues, vendorsInitialValues } from '../../utils/constants';
// import { GetRemaining } from '../../../../../components/Pricing/usePricingHook';
// import AuthNotificationBar from "../../../AccountSetup/TwoFactorAuth/AuthNotificationBar";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
//translations
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { useTheme } from '@mui/material/styles';
import useSearch from 'hooks/useSearch';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { usePDF } from 'react-to-pdf';
import { EditVendor, GetFilteredVendors } from 'store/actions/vendorActions';
import DownloadQuestionnaireModal from './DownloadQuestionaire';
import SpoolVendorReport from './SpoolVendorReport';
import UploadCsv from './UploadCsvModal';
import UploadResponse from './UploadResponse';
import VendorDetailsModal from './VendorDetailsModal';
import VendorTitle from './VendorTitle';

const NO_PER_PAGE = 8;

const Vendors = (props) => {
    const { GetAllVendors, all_vendors, userguide_info, EditVendor, GetFilteredVendors } = props;
    const theme = useTheme();
    const [loading, setloading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalUploadOpen, setModalUploadOpen] = useState(false);
    const [modalDetailsOpen, setModalDetailsOpen] = useState(false);
    const [modalMode, setModalMode] = useState('');
    const [modalPayload, setModalPayload] = useState(vendorsInitialValues);
    const [modalPayloadId, setModalPayloadId] = useState(0);
    const [riskModalOpen, setRiskModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [downloadQuestionaire, setDownloadQuestionaire] = useState(false);
    const [uploadResponse, setUploadResponse] = useState(false);
    const [responseDrawerPayload, setResponseDrawerPayload] = useState(vendorResponseInitialValues);
    const [anchorEl, setAnchorEl] = useState(null);
    const [status, setStatus] = useState('');
    const [entity_type, setEntity_type] = useState('');
    const [vendorReport, setVendorReport] = useState({});

    const openMenu = (e) => setAnchorEl(e.target);
    const closeMenu = () => setAnchorEl(null);

    const { data, handleSearch } = useSearch(all_vendors, ['name', 'remail', 'products']);

    //accessing values from the redux store
    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);
    //translation
    const { t } = useTranslation('vendorManagement');

    const stepsTargetRef = useRef([]);

    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    const { targetRef, toPDF } = usePDF({
        filename: 'vendor-report.pdf',
        page: {
            margin: 12.5,
        },
    });

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ vendors: true });
        setToggleUserGuide({ ...toggleUserGuide, vendors: false, actingasavendor: false });
        setOpenUserGuide(false);
    };

    const ResendQuestionaire = async (id) => {
        const body = {
            send_questionaire: true,
            has_responded: false,
        };
        const res = await EditVendor(body, id);
        if (res?.success) {
            toast.success('Questionaire sent successfully');
        } else {
            toast.error(res?.message);
        }
    };

    const columns = [
        {
            title: t('table.column1'),
            render: (row) => <>{row?.name}</>,
        },
        {
            title: t('table.column2'),
            key: 'email',
            render: (row) => <>{row?.email}</>,
        },
        {
            title: t('table.column4'),
            key: 'product',
            render: (row) => <>{row?.products}</>,
        },
        {
            title: t('table.column3'),
            key: 'risk_level',
            render: (row) => (
                <>
                    {row?.risk_level ? (
                        <>
                            {row?.risk_level?.toLowerCase() === 'low' ? (
                                <AppTag
                                    text="Low"
                                    icon={
                                        <FiberManualRecordIcon
                                            sx={{ width: 12, height: 12, color: theme.palette.success[500], mr: 0.5 }}
                                        />
                                    }
                                    type="default"
                                    noRounded
                                    noItalic
                                    sx={{
                                        padding: '4px 8px',
                                    }}
                                />
                            ) : row?.risk_level?.toLowerCase() === 'medium' ? (
                                <AppTag
                                    text="Medium"
                                    icon={
                                        <FiberManualRecordIcon
                                            sx={{ width: 12, height: 12, color: theme.palette.warning[900], mr: 0.5 }}
                                        />
                                    }
                                    noRounded
                                    noItalic
                                    type="default"
                                    sx={{
                                        padding: '4px 8px',
                                    }}
                                />
                            ) : row?.risk_level?.toLowerCase() === 'high' ? (
                                <AppTag
                                    text="High"
                                    icon={
                                        <FiberManualRecordIcon
                                            sx={{ width: 12, height: 12, color: theme.palette.error[500], mr: 0.5 }}
                                        />
                                    }
                                    noRounded
                                    noItalic
                                    type="default"
                                    sx={{
                                        padding: '4px 8px',
                                    }}
                                />
                            ) : row?.risk_level?.toLowerCase() === 'critical' ? (
                                <AppTag
                                    text="Critical"
                                    icon={
                                        <FiberManualRecordIcon
                                            sx={{ width: 12, height: 12, color: theme.palette.error[300], mr: 0.5 }}
                                        />
                                    }
                                    noRounded
                                    noItalic
                                    type="default"
                                    sx={{
                                        padding: '4px 8px',
                                    }}
                                />
                            ) : (
                                <AppTag
                                    text={row?.risk_level?.toUpperCase()}
                                    icon={
                                        <FiberManualRecordIcon
                                            sx={{ width: 12, height: 12, color: '#77777A', mr: 0.5 }}
                                        />
                                    }
                                    noRounded
                                    noItalic
                                    sx={{
                                        padding: '4px 8px',
                                    }}
                                />
                            )}
                        </>
                    ) : (
                        'N/A'
                    )}
                </>
            ),
        },
        {
            title: t('table.column5'),
            key: 'has_responded',
            render: (row) => (
                <>
                    {row?.has_responded ? (
                        <AppTag
                            text={t('table.responded')}
                            type="success"
                            noRounded
                            noItalic
                            sx={{
                                padding: '2px 8px',
                            }}
                        />
                    ) : (
                        <AppTag
                            text={t('table.notResponded')}
                            type="failed"
                            noRounded
                            noItalic
                            sx={{
                                padding: '2px 8px',
                            }}
                        />
                    )}
                </>
            ),
        },
        {
            title: t('table.column6'),
            key: 'date_created',
            render: (row) => <>{moment(row?.date_created).format('MMMM DD, YYYY')}</>,
        },
        {
            title: <MoreHoriz />,
            align: 'center',
            key: 'action',
            render: (row) => (
                <>
                    <VendorsTableActions
                        row={row}
                        openModal={openModal}
                        openDeleteModal={openDeleteModal}
                        openDetailsModal={openDetailsModal}
                        ResendQuestionaire={ResendQuestionaire}
                    />
                </>
            ),
        },
    ];

    //functions
    const handlePageChange = (page) => {
        setPage(page);
    };

    const closeModal = () => {
        setModalOpen(false);
        setTimeout(() => setModalPayload({}), 1000);
    };

    const openModal = (mode, payload = vendorsInitialValues, id) => {
        setModalOpen(true);
        setModalMode(mode);
        setModalPayload(payload);
        setModalPayloadId(id);
        closeMenu();
    };

    const openModalUpload = () => {
        setModalUploadOpen(true);
        closeMenu();
    };
    const closeModalUpload = () => {
        setModalUploadOpen(false);
    };

    const openUploadResponse = (payload = vendorResponseInitialValues) => {
        setUploadResponse(true);
        setResponseDrawerPayload(payload);
        closeMenu();
    };
    const closeUploadResponse = () => {
        setUploadResponse(false);
        setTimeout(() => setResponseDrawerPayload({}), 1000);
    };

    const openDownloadQuestionaire = () => {
        setDownloadQuestionaire(true);
        closeMenu();
    };
    const closeDownloadQuestionaire = () => {
        setDownloadQuestionaire(false);
    };
    const openRiskLevelsModal = () => {
        setRiskModalOpen(true);
    };
    const closeRiskLevelsModal = () => {
        setRiskModalOpen(false);
    };

    const openDetailsModal = (payload = {}) => {
        setModalDetailsOpen(true);
        setModalPayload(payload);
        closeMenu();
    };

    const closeDetailsModal = () => {
        setModalDetailsOpen(false);
    };

    const openDeleteModal = (id) => {
        setModalPayloadId(id);
        setDeleteModalOpen(true);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setModalPayloadId(0);
    };

    useEffect(() => {
        !userguide_info || toggleUserGuide.vendors ? OpenUserGuide() : null;
    }, [userguide_info, toggleUserGuide.vendors]);

    const query_params = useMemo(() => {
        const params = {
            ...(status.length && { has_responded: status === 'All' ? '' : status }),
            ...(entity_type.length && { risk_level: entity_type }),
        };
        return params;
    }, [status, entity_type]);

    const getAllVendors = async () => {
        setloading(true);
        const res = await GetAllVendors();
        setloading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    const getAllFilteredVendors = async () => {
        setloading(true);
        const res = await GetFilteredVendors(page, query_params);
        setloading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        if (query_params) {
            getAllFilteredVendors();
        } else {
            getAllVendors();
        }
    }, [query_params, page]);

    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        setPageData(data?.length ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);

        return () => setPageData([]);
    }, [page, data]);

    // const { reminderCount } = GetRemaining(pricing_plan?.vendor_mgt, all_vendors?.length);
    return (
        <Box sx={{ mt: '3rem', mx: '1.3rem', backgroundColor: '#FFFFFF', px: '2rem' }}>
            {/*<div>
        {pricing_plan?.vendor_mgt !== all_vendors?.length && <AuthNotificationBar text="You have exceeded your number of users" />}
      </div>*/}
            <AppTable
                columns={columns}
                data={pageData}
                loading={loading}
                sorter={(a, b) => b?.id - a?.id}
                colored_cell_color="#FFFBEB"
                tooltip={'This vendor is within minimum risk alert level'}
                title={
                    <VendorTitle
                        onSearch={handleSearch}
                        status={status}
                        entity_type={entity_type}
                        setStatusFilter={setStatus}
                        setEntity_type={setEntity_type}
                    />
                }
                dataLength={all_vendors?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                actions={[
                    <ButtonGroup disabled={user_type === 'auditor' || role === 'auditor'}>
                        <Button
                            startIcon={<Add />}
                            ref={(el) => (stepsTargetRef.current[0] = el)}
                            color="primary"
                            variant="contained"
                            // disabled={reminderCount === 0}
                            disableElevation
                            onClick={() => openModal('add')}
                            sx={{
                                display: 'flex-inline',
                                alignItems: 'center',
                                justifyContent: 'center',
                                fontWeight: '600',
                                // borderRadius: '4px',
                                py: 1.2,
                                px: 2.4,
                                textTransform: 'unset',
                                '&.Mui-disabled': {
                                    color: theme.palette.white.main,
                                    backgroundColor: theme.palette.gray[600],
                                },
                            }}
                        >
                            {t('table.addVendor')}
                        </Button>
                        <Button
                            onClick={openMenu}
                            color="primary"
                            variant="contained"
                            sx={{ borderLeft: '1px solid #5474C4', py: 1.2 }}
                        >
                            <KeyboardArrowDownIcon />
                        </Button>
                    </ButtonGroup>,
                ]}
            />
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={closeMenu}
                sx={{ mt: 2 }}
                PaperProps={{
                    style: {
                        width: 220,
                    },
                }}
            >
                <MenuItem
                    onClick={openModalUpload}
                    sx={{ color: '#64748B', fontSize: '14px', fontWeight: 500, py: 0.2 }}
                >
                    {t('addVendorMenu.uploadCSV')}
                </MenuItem>
                {vendorReport?.vendor_data?.length ? (
                    <>
                        <Divider />
                        <MenuItem
                            onClick={() => {
                                toPDF();
                                closeMenu();
                            }}
                            sx={{ color: '#64748B', fontSize: '14px', fontWeight: 500, py: 0.2 }}
                        >
                            {t('addVendorMenu.exportVendor')}
                        </MenuItem>
                    </>
                ) : null}
                <Divider />
                <MenuItem
                    onClick={openUploadResponse}
                    sx={{ color: '#64748B', fontSize: '14px', fontWeight: 500, py: 0.2 }}
                >
                    {t('addVendorMenu.uploadResponse')}
                </MenuItem>
                <Divider />
                <MenuItem
                    onClick={openDownloadQuestionaire}
                    sx={{ color: '#64748B', fontSize: '14px', fontWeight: 500, py: 0.2 }}
                >
                    {t('addVendorMenu.downloadQuestionaire')}
                </MenuItem>
            </Menu>
            <UploadCsv open={modalUploadOpen} handleClose={closeModalUpload} />

            <VendorModal
                open={modalOpen}
                handleClose={closeModal}
                isAddModal={modalMode === 'add'}
                payload={modalPayload}
                id={modalPayloadId}
                openRiskLevelsModal={openRiskLevelsModal}
            />
            <RiskLevelsModal open={riskModalOpen} handleClose={closeRiskLevelsModal} />

            <VendorDeleteModal open={deleteModalOpen} handleClose={closeDeleteModal} id={modalPayloadId} />
            <VendorDetailsModal open={modalDetailsOpen} handleClose={closeDetailsModal} details={modalPayload} />

            <AppUserGuide
                open={openUserGuide}
                onClose={CloseUserGuide}
                steps={steps}
                targets={stepsTargetRef.current}
            />
            <DownloadQuestionnaireModal open={downloadQuestionaire} handleClose={closeDownloadQuestionaire} />
            <UploadResponse open={uploadResponse} handleClose={closeUploadResponse} payload={responseDrawerPayload} />

            <SpoolVendorReport targetRef={targetRef} vendorReport={vendorReport} setVendorReport={setVendorReport} />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        all_vendors: state?.vendorReducers?.all_vendors,
        pricing_plan: state?.authReducers.pricing_plan,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.vendors,
    };
};

export default connect(mapStateToProps, { GetAllVendors, EditVendor, GetFilteredVendors })(Vendors);
