import React from 'react';
// Core components
import { Menu, MenuItem } from '@mui/material';
// Translations
import { useTranslation } from 'react-i18next';

const RequestScanAction = ({ open, handleClose, openUploadPCIReportModal, handleOpenUploadCSVModal }) => {
    // Translation
    const { t } = useTranslation('pciscans');

    const handleMenuItemClick = (action) => {
        action();
        handleClose();
    };

    return (
        <Menu
            open={Boolean(open)}
            onClose={handleClose}
            anchorEl={open}
            // anchorOrigin={{
            //     vertical: 'bottom',
            //     horizontal: 'right',
            // }}
            // transformOrigin={{
            //     vertical: 'bottom',
            //     horizontal: 'right',
            // }}
        >
            <MenuItem
                onClick={() => handleMenuItemClick(openUploadPCIReportModal)}
                sx={{
                    height: '44px',
                    paddingLeft: '16px',
                    color: '#64748B',
                    fontSize: '14px',
                    fontWeight: 500,
                    borderTop: '1px solid #F1F5F9',
                    borderLeft: '1px solid  #F1F5F9',
                    borderRight: '1px solid #F1F5F9',
                    borderBottom: '0.5px solid #F1F5F9',
                    marginBottom: '-8px',
                    marginTop: '-8px',
                }}
            >
                {t('requestScanAction.upload')}
            </MenuItem>
            <MenuItem
                onClick={() => handleMenuItemClick(handleOpenUploadCSVModal)}
                sx={{
                    height: '44px',
                    paddingLeft: '16px',
                    color: '#64748B',
                    fontSize: '14px',
                    fontWeight: 500,
                    marginTop: '8px',
                    marginBottom: '-8px',
                }}
            >
                {t('requestScanAction.request')}
            </MenuItem>
        </Menu>
    );
};

export default RequestScanAction;
