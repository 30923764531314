import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';

// core components
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import TabLayout from 'components/new_components/tabs/TabLayout';
import PreviewLink from './components/PreviewLink';
import OnboardTable from './components/OnboardTable';
import PageHeader from 'components/new_components/PageHeader';

// utils
// import { TABS } from './utils';

// redux
import { connect } from 'react-redux';
import { GetAllMerchantOnboardingItems, GetAllOnboardingItemsForMerchant } from 'store/actions/onboardingActions';
import { toast } from 'react-toastify';

//userguide
import { Tour } from 'antd';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AppArticle from 'components/new_components/AppArticle';

//translations
import { useTranslation } from 'react-i18next';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';

const OnboardingPolicy = (props) => {
    const {
        GetAllMerchantOnboardingItems,
        GetAllOnboardingItemsForMerchant,
        all_merchant_onboarding_items,
        all_onboarding_items_for_merchant,
        userguide_info,
        all_article_header,
    } = props;

    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [documents, setDocuments] = useState([]);
    const [videos, setVideos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    //userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const ref3 = useRef(null);

    const STEPS_LENGTH = 3;

    const steps = [
        {
            title: <UserGuideHeader title="Policy Documents" id={1} length={STEPS_LENGTH} />,
            description: 'You can upload the policies that govern your organization here.',
            target: () => ref1.current,
            placement: 'bottomLeft',
        },
        {
            title: <UserGuideHeader title="Awareness Videos" id={2} length={STEPS_LENGTH} />,
            description: 'Upload videos that create awareness to your employees.',
            target: () => ref2.current,
            placement: 'bottomLeft',
        },
        {
            title: <UserGuideHeader title="Preview" id={3} length={STEPS_LENGTH} />,
            description: 'See a preview of how your employee is been onboarded.',
            target: () => ref3.current,
            placement: 'bottomRight',
        },
    ];

    //translation
    const { t } = useTranslation('onboardingPolicy');

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Onboarding Policy'),
        [all_article_header]
    );

    // logic functions
    const handleTabChange = (tab) => {
        console.log(tab);
        setCurrentTab(tab);
    };
    const preparePolicies = (adminPolicies = [], merchantPolicies = []) => {
        const videos = [],
            documents = [];
        adminPolicies.forEach((policy) => {
            let selected;
            // check if it has a merchant replica
            selected = merchantPolicies?.find((mPolicy) => policy?.name === mPolicy?.name) || policy;
            // check if it is a document or a video
            if (selected?.tag === 'video') videos.push(selected);
            else if (selected?.tag === 'document') documents.push(selected);
        });
        setVideos(videos);
        setDocuments(documents);
    };

    // async functions
    const getAllMerchantOnboardingItems = async () => {
        setLoading(true);
        const res = await GetAllMerchantOnboardingItems();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const getAllOnboardingItemsForMerchant = async () => {
        setLoading(true);
        const res = await GetAllOnboardingItemsForMerchant();
        setLoading(false);
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ onboarding: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, onboarding: false });
        setOpenArticle(false);
    };

    // useEffect
    useEffect(() => {
        getAllMerchantOnboardingItems();
        getAllOnboardingItemsForMerchant();
    }, []);

    useEffect(() => {
        preparePolicies(all_onboarding_items_for_merchant, all_merchant_onboarding_items);
    }, [all_merchant_onboarding_items, all_onboarding_items_for_merchant]);
    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.onboarding ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.onboarding]);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.onboarding ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.onboarding]);

    return (
        <>
            <PageHeader browserTitle="Onboarding Policies | Smartcomply" />
            <TabLayout
                onTabChange={handleTabChange}
                tab={currentTab}
                tabs={t('tabs', { returnObjects: true })}
                tabAction={<PreviewLink text={t('preview')} to="/verifystatus/001" ref3={ref3} />}
                ref1={ref1}
                ref2={ref2}
            >
                <AppTabPanel value={currentTab} index={0} ref1={ref1}>
                    <OnboardTable data={documents} loading={loading} type="document" />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={1} ref2={ref2}>
                    <OnboardTable data={videos} loading={loading} type="video" />
                </AppTabPanel>
            </TabLayout>
            <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Onboarding Policy"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        all_merchant_onboarding_items: state?.onboardingReducers?.all_merchant_onboarding_items,
        all_onboarding_items_for_merchant: state?.onboardingReducers?.all_onboarding_items_for_merchant,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.onboarding,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};

export default connect(mapStateToProps, {
    GetAllMerchantOnboardingItems,
    GetAllOnboardingItemsForMerchant,
})(OnboardingPolicy);
