/* eslint-disable no-unused-vars */
import { Accordion, AccordionDetails, AccordionSummary, Drawer, Menu, MenuItem, Slide } from '@mui/material';
import { forwardRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { RemoveScanException } from 'store/actions/automatedScansActions';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="right" ref={ref} {...props} />;
});

export default function ViewExemptedScanDrawer({ open, onClose, sub }) {
    const [searchIntance, setSearchInstance] = useState('');
    const [currentAccount, setCurrentAccount] = useState(Object.keys(sub?.account || {})[0]);
    const [scanInstanceStatus, setScanInstanceStatus] = useState('success');
    const [anchorEl, setAnchorEl] = useState(null);
    const [all_exempted_scan_items, setAll_exempted_scan_items] = useState([]);

    const filtered_all_scan_items = all_exempted_scan_items
        ?.filter((scan) => scan?.status === scanInstanceStatus)
        .filter((scan) => scan?.scan_name?.toLowerCase().includes(searchIntance.toLowerCase()));

    useEffect(() => {
        if (currentAccount) {
            setAll_exempted_scan_items(sub?.account[currentAccount]);
        }
    }, [currentAccount]);

    useEffect(() => {
        setSearchInstance('');
        setCurrentAccount(Object.keys(sub?.account || {})[0]);
        setScanInstanceStatus('success');
        setAnchorEl(null);
    }, [sub?.name]);

    return (
        <Drawer
            open={open}
            anchor="right"
            TransitionComponent={Transition}
            transitionDuration={1000}
            keepMounted
            onClose={onClose}
            PaperProps={{
                sx: {
                    boxShadow: '0px 12px 24px rgba(0, 0, 0, 0.16)',
                    minWidth: 370,
                    width: 535,
                    flex: 1,
                    overflow: 'auto',
                    zIndex: 9999,
                },
            }}
            scroll="paper"
        >
            <div className="flex flex-col gap-4">
                <div className="border-b border-[#F1F5F9] px-2 !py-4 flex items-center gap-2">
                    <img
                        src="/img/automated-scan/arrow-left-icon.svg"
                        alt="arrow-left-icon"
                        onClick={onClose}
                        className="cursor-pointer pl-2 object-contain"
                    />

                    <h3 className="pl-2 text-[#202D66] capitalize font-semibold text-base">
                        {sub?.name?.replace(/_/g, ' ')} (Exempted)
                    </h3>
                </div>

                <div className="flex items-center justify-between px-4">
                    <div className="relative">
                        <img
                            src="/img/automated-scan/search-icon.svg"
                            alt="Search Icon"
                            className="absolute object-contain top-1/2 -translate-y-1/2 left-[10px]"
                        />

                        <input
                            type="text"
                            value={searchIntance}
                            onChange={(event) => setSearchInstance(event.target.value)}
                            placeholder="Search in scan instance"
                            className="w-full max-w-[238px] py-2.5 pr-2.5 !pl-8 border border-[#E2E8F0] h-8 text-sm font-normal placeholder:text-[#94A3B8] text-[#64748B] rounded focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-[#2a447f] focus-visible:ring-opacity-75"
                        />
                    </div>

                    <div className="flex items-center gap-2">
                        {currentAccount && (
                            <div>
                                <button
                                    aria-controls={anchorEl ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={anchorEl ? 'true' : undefined}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setAnchorEl(event.currentTarget);
                                    }}
                                    className="border border-[#E2E8F0] px-2 py-1.5 flex items-center gap-1 rounded hover:bg-[#E2E8F0] transition-colors"
                                >
                                    <p className="capitalize text-[#64748B] font-medium text-[13px]">
                                        {currentAccount}
                                    </p>

                                    <img
                                        src="/img/automated-scan/arrow-down.svg"
                                        alt="Arrow Down Icon"
                                        className="object-contain"
                                    />
                                </button>

                                <Menu
                                    anchorEl={anchorEl}
                                    open={Boolean(anchorEl)}
                                    onClose={() => setAnchorEl(null)}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            borderRadius: '4px',
                                            boxShadow:
                                                '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                                        },
                                    }}
                                >
                                    {Object?.keys(sub?.account)?.map(
                                        (scan, index) =>
                                            scan !== 'scan_time' && (
                                                <MenuItem
                                                    key={index}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setCurrentAccount(scan);
                                                        setAnchorEl(null);
                                                    }}
                                                    sx={{
                                                        color: '#64748B',
                                                        fontWeight: 500,
                                                        fontSize: '14px',
                                                        textTransform: 'capitalize',
                                                    }}
                                                >
                                                    {scan}
                                                </MenuItem>
                                            )
                                    )}
                                </Menu>
                            </div>
                        )}

                        <div className="border border-[#E2E8F0] rounded flex items-center overflow-hidden">
                            <button
                                className={`grid place-items-center h-8 px-2 text-[#64748B] ${
                                    scanInstanceStatus !== 'success' && 'bg-[#395BA9] text-white'
                                } transition-all hover:brightness-90 font-medium text-sm`}
                                onClick={() => setScanInstanceStatus('failed')}
                            >
                                Failed
                            </button>

                            <button
                                className={`grid place-items-center h-8 px-2 text-[#64748B] ${
                                    scanInstanceStatus === 'success' && 'bg-[#395BA9] text-white'
                                } transition-all hover:brightness-90 font-medium text-sm`}
                                onClick={() => setScanInstanceStatus('success')}
                            >
                                Passed
                            </button>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col">
                    {filtered_all_scan_items?.length === 0 ? (
                        <div className="pt-8 justify-center items-center flex flex-col gap-4">
                            <img
                                src="/img/automated-scan/no-scan-instance-icon.svg"
                                alt="No result Icon"
                                className="object-contain"
                            />

                            <p className="text-[#475569] font-semibold text-lg">
                                No {scanInstanceStatus === 'success' ? 'passed' : 'failed'} scan found
                            </p>
                        </div>
                    ) : (
                        filtered_all_scan_items?.map((scan) => (
                            <RenderExemptedScanItems key={scan?.id} scan={scan} onClose={onClose} />
                        ))
                    )}
                </div>
            </div>
        </Drawer>
    );
}

function RenderExemptedScanItems({ scan, onClose }) {
    const [expandedAccordion, setExpandedAccordion] = useState(false);

    const user = useSelector((state) => state?.generalReducers?.user_info);

    const organization = useSelector((state) => state?.generalReducers?.user_info?.organization);

    const dispatch = useDispatch();

    const handleAccordionChange = (scanID) => (event, isAccordionExpanded) => {
        setExpandedAccordion(isAccordionExpanded ? scanID : false);
    };

    const handleDelete = async (id) => {
        const res = await dispatch(RemoveScanException(id));
        if (res?.success) {
            toast.success('Scan successfully reverted');
            onClose();
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <>
            <Accordion
                expanded={expandedAccordion === scan?.id}
                onChange={handleAccordionChange(scan?.id)}
                className="!shadow-none !border-b !border-[#F1F5F9] [&.css-9mbo5h-MuiPaper-root-MuiAccordion-root.Mui-expanded]:!my-0 before:hidden"
            >
                <AccordionSummary
                    expandIcon={<></>}
                    aria-controls={`${scan?.id}-content`}
                    id={`${scan?.id}header`}
                    className={`!px-3 [&>.MuiAccordionSummary-content]:!my-1.5 !min-h-fit transition-colors ${
                        expandedAccordion === scan?.id && '!bg-[#F8FAFC]'
                    }`}
                >
                    <div className="flex items-center justify-between w-full">
                        <div className="flex items-center gap-2">
                            <div className="grid place-items-center w-[22px] h-[22px] rounded-full bg-[#F8FAFC] border border-[#F1F5F9] drop-shadow-[2px,2px,8px,0px,#09090914]">
                                <img
                                    src="/img/automated-scan/arrow-right-light-icon.svg"
                                    alt="Arrow Icon"
                                    className={`object-contain transition-transform ${
                                        expandedAccordion === scan?.id && 'transform rotate-90'
                                    }`}
                                />
                            </div>

                            <p className="text-[#475569] font-normal text-sm">{scan?.scan_name}</p>
                        </div>

                        <div>
                            <img
                                src={
                                    scan?.status === 'success'
                                        ? '/img/automated-scan/scan-success-icon.svg'
                                        : '/img/automated-scan/scan-failed-icon.svg'
                                }
                                alt="Scan Status Icon"
                                className="object-contain"
                            />
                        </div>
                    </div>
                </AccordionSummary>

                <AccordionDetails className="!px-6 !py-2">
                    <div className="flex flex-col gap-4">
                        <p className="text-[#64748B] font-normal text-[13px] leading-5">
                            <span className="font-bold">Reason:</span> {scan?.reason || 'No reason provided'}
                        </p>

                        <div>
                            <button
                                className="border border-[#E2E8F0] bg-[#F8FAFC] hover:brightness-90 transition-all py-1 px-2 text-[#395BA9] font-normal text-sm"
                                onClick={() => handleDelete(scan.x_id)}
                            >
                                Revert
                            </button>
                        </div>
                    </div>
                </AccordionDetails>
            </Accordion>
        </>
    );
}
