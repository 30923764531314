import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreHoriz } from '@mui/icons-material';
import { MdFileDownload } from 'react-icons/md';
import EditPen from 'assets/img/editPen.svg';
import { useHistory } from 'react-router-dom';
import { AUDIT_STATUS } from './utils';

const AuditTableAction = (props) => {
    const theme = useTheme();
    const { record, openDownloadModal } = props;
    const history = useHistory();

    // state
    const [anchor, setAnchor] = useState(null);

    // functions
    const openMenu = (e) => {
        e.stopPropagation();
        setAnchor(e.target);
    };
    const closeMenu = (e) => {
        e.stopPropagation();
        setAnchor(null);
    };
    const handleDownload = () => {
        openDownloadModal(record);
    };

    const gotoPreview = (standard) => {
        history?.push(`/merchant/audits/preview/${standard}`);
    };

    return (
        <>
            <IconButton
                sx={{
                    backgroundColor: theme.palette.gray[95],
                    width: 32,
                    height: 32,
                    color: theme.palette.gray[30],
                }}
                onClick={openMenu}
            >
                <MoreHoriz />
            </IconButton>
            <Menu
                open={Boolean(anchor)}
                anchorEl={anchor}
                onClose={closeMenu}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClick={(e) => {
                    e.stopPropagation();
                    closeMenu(e);
                }}
            >
                <MenuItem onClick={() => gotoPreview(record?.compliance)} disabled={record?.spoolreport === null}>
                    <div className="flex items-center text-[#303034]">
                        <img src={EditPen} width={17} alt="edit" />
                        <span className="pl-2 text-[14px]">Review & Signature</span>
                    </div>
                </MenuItem>
                <MenuItem onClick={handleDownload} disabled={record?.status !== AUDIT_STATUS.COMPLETE}>
                    <div className="flex items-center text-[#303034]">
                        <MdFileDownload />
                        <span className="pl-2 text-[14px]">Download</span>
                    </div>
                </MenuItem>
            </Menu>
        </>
    );
};

export default AuditTableAction;
