import { MoreHoriz } from '@mui/icons-material';
import { Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const RiskTableAction = (props) => {
    const {
        row,
        openViewDetails,
        openDeleteModal,
        openReslovedModal,
        openExtendTimelineModal,
        openUpdateRiskModal,
        openApprovalModal,
        openMarkDisApprovalModal,
    } = props;
    const [actionAnchorEl, setActionAnchorEl] = useState(null);
    const user_info = useSelector((state) => state?.generalReducers?.user_info);
    return (
        <div>
            <button
                aria-controls={actionAnchorEl ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={actionAnchorEl ? 'true' : undefined}
                onClick={(event) => {
                    event.stopPropagation();
                    setActionAnchorEl(event.currentTarget);
                }}
                className="hover:scale-90 transition-all"
            >
                <MoreHoriz />
            </button>

            <Menu
                anchorEl={actionAnchorEl}
                open={Boolean(actionAnchorEl)}
                onClose={() => setActionAnchorEl(null)}
                sx={{
                    '& .MuiPaper-root': {
                        borderRadius: '4px',
                        boxShadow: '4px 4px 8px rgba(175, 172, 172, 0.15), -4px -4px 8px rgba(175, 172, 172, 0.15)',
                    },
                    '& .MuiList-root': {
                        padding: 0,
                    },
                }}
            >
                <MenuItem
                    onClick={() => {
                        openViewDetails(row);
                        setActionAnchorEl(null);
                    }}
                    sx={{
                        color: '#64748B',
                        fontWeight: 500,
                        fontSize: '14px',
                        textTransform: 'none',
                        borderBottom: '1px solid #F1F5F9',
                        padding: '8px 16px',
                    }}
                >
                    View details
                </MenuItem>

                {/* <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();
                        setActionAnchorEl(null);
                    }}
                    sx={{
                        color: '#64748B',
                        fontWeight: 500,
                        fontSize: '14px',
                        textTransform: 'none',
                        borderBottom: '1px solid #F1F5F9',
                        padding: '8px 16px',
                    }}
                >
                    Add mitigation
                </MenuItem> */}

                {row?.risk_assessment_status !== 'resolved' && (
                    <MenuItem
                        onClick={(event) => {
                            event.stopPropagation();
                            openUpdateRiskModal(row);
                            setActionAnchorEl(null);
                        }}
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '14px',
                            textTransform: 'none',
                            borderBottom: '1px solid #F1F5F9',
                            padding: '8px 16px',
                        }}
                    >
                        Update
                    </MenuItem>
                )}

                {row?.risk_assessment_status === 'awaiting_approval' && user_info?.email === row?.solution_owner && (
                    <MenuItem
                        onClick={(event) => {
                            event.stopPropagation();
                            openApprovalModal(row);
                            setActionAnchorEl(null);
                        }}
                        sx={{
                            color: '#395BA9',
                            fontWeight: 500,
                            fontSize: '14px',
                            textTransform: 'capitalize',
                            borderBottom: '1px solid #F1F5F9',
                            padding: '8px 16px',
                        }}
                    >
                        Approve treatment
                    </MenuItem>
                )}

                {row?.risk_assessment_status === 'awaiting_approval' && user_info?.email === row?.solution_owner && (
                    <MenuItem
                        onClick={(event) => {
                            event.stopPropagation();
                            openMarkDisApprovalModal(row);
                            setActionAnchorEl(null);
                        }}
                        sx={{
                            color: '#FF0000',
                            fontWeight: 500,
                            fontSize: '14px',
                            textTransform: 'capitalize',
                            borderBottom: '1px solid #F1F5F9',
                            padding: '8px 16px',
                        }}
                    >
                        Disapprove treatment
                    </MenuItem>
                )}

                {(row?.risk_assessment_status === 'not_resolved' || row?.risk_assessment_status === 'approved') &&
                    user_info?.email === row?.solution_owner && (
                        <MenuItem
                            onClick={(event) => {
                                event.stopPropagation();
                                openReslovedModal(row);
                                setActionAnchorEl(null);
                            }}
                            sx={{
                                color: '#37A372',
                                fontWeight: 500,
                                fontSize: '14px',
                                textTransform: 'capitalize',
                                borderBottom: '1px solid #F1F5F9',
                                padding: '8px 16px',
                            }}
                        >
                            Mark risk as resolved
                        </MenuItem>
                    )}

                {row?.risk_assessment_status !== 'resolved' && (
                    <MenuItem
                        onClick={(event) => {
                            event.stopPropagation();
                            openExtendTimelineModal(row);
                            setActionAnchorEl(null);
                        }}
                        sx={{
                            color: '#64748B',
                            fontWeight: 500,
                            fontSize: '14px',
                            textTransform: 'capitalize',
                            borderBottom: '1px solid #F1F5F9',
                            padding: '8px 16px',
                        }}
                    >
                        Extend timeline
                    </MenuItem>
                )}

                <MenuItem
                    onClick={(event) => {
                        event.stopPropagation();
                        openDeleteModal(row);
                        setActionAnchorEl(null);
                    }}
                    sx={{
                        color: '#FF5449',
                        fontWeight: 500,
                        fontSize: '14px',
                        textTransform: 'capitalize',
                        borderBottom: '1px solid #F1F5F9',
                        padding: '8px 16px',
                    }}
                >
                    Delete
                </MenuItem>
            </Menu>
        </div>
    );
};

export default RiskTableAction;
