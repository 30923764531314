/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
//core components
import CircularLoader from 'components/Loaders/CircularLoader';

// redux
import { Box } from '@mui/material';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllExceptions, GetExemptedScans } from 'store/actions/automatedScansActions';
import ExceptionGroupView from './Components/ExceptionGroupView';

const Exceptions = (props) => {
    const { all_scan_exceptions, GetAllExceptions, view, changeView, category, setCategory } = props;

    // state
    const [loading, setLoading] = useState(false);
    // functions

    const getAllScansExceptions = async () => {
        setLoading(true);
        const res = await GetAllExceptions();
        setLoading(false);
        if (!res.success) {
            toast.error(res?.message);
        }
    };

    // useEffect
    useEffect(() => {
        getAllScansExceptions();
        return () => {
            setLoading(false);
        };
    }, []);

    // const handleChangeView = (viewType, category) => {
    //     changeView(viewType);
    //     setCategory(category);
    // };

    return (
        <>
            {loading ? (
                <CircularLoader />
            ) : (
                <Box sx={{ height: 'calc(100vh - 150px)' }}>
                    {/* {view === 'groups' ? (
                        <ScanGroups handleChangeView={handleChangeView} groups={all_scan_exceptions} isScan={false} />
                    ) : (
                        <ScansView
                            goBack={handleChangeView}
                            category={category}
                            isScan={false}
                            view={view}
                            loading={loading}
                        />
                    )} */}
                    <ExceptionGroupView all_scan_exceptions={all_scan_exceptions} />
                </Box>
            )}
            ,
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        all_scan_exceptions: state?.automatedScansReducers?.all_scan_exceptions,
        all_exempted_scans: state?.automatedScansReducers?.all_exempted_scans,
    };
};
export default connect(mapStateToProps, { GetExemptedScans, GetAllExceptions })(Exceptions);
