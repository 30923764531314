import React, { useState } from 'react';
import { IconButton, MenuItem, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GroupCard from './GroupCard';
import { MoreVert } from '@mui/icons-material';
import AppTableActionMenu from 'components/new_components/app-table/AppTableActionMenu';
import { useSelector } from 'react-redux';
import { getType } from 'utils';

const CategoryCard = (props) => {
    const {
        metric,
        resourceLabel,
        onViewChange,
        id,
        title,
        openTaskAssignModal,
        isCategoryAssigned,
        resourceAssigned,
        standard,
        tag,
        description,
    } = props;
    // hooks
    const [anchor, setAnchor] = useState(null);

    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = getType('role');

    const theme = useTheme();

    const handleOpenTaskModal = (e) => {
        e.stopPropagation();
        openTaskAssignModal('requirement', {
            tech_sec: id,
            is_document: resourceLabel !== 'Questions Answered',
            standard,
        });
        closeMenu();
    };
    // functions
    const openMenu = (event) => {
        event.stopPropagation();
        setAnchor(event.target);
    };

    const closeMenu = () => setAnchor(null);

    const handleCardClick = () => {
        if (metric?.totalValue > 0) {
            onViewChange(id);
        }
    };
    return (
        <GroupCard
            percentCompleted={
                metric?.totalAttendedTo === metric?.totalValue
                    ? 100
                    : (metric?.totalAttendedTo / metric?.totalValue) * 100 || 0
            }
            totalValue={metric?.totalValue}
            totalAttendedTo={metric?.totalAttendedTo}
            resourceLabel={resourceLabel}
            onCardClick={handleCardClick}
            empty={metric?.totalValue == 0}
            isCategoryAssigned={isCategoryAssigned}
            isResourceAssigned={resourceAssigned?.includes(id)}
            standard={standard}
            tag={tag}
        >
            <Stack direction="row" justifyContent="space-between">
                <Typography
                    variant="h3"
                    component="h3"
                    sx={{
                        color: standard === 'cii' ? '#46464A' : theme.palette.black.main,
                        fontWeight: standard === 'cii' ? 500 : 600,
                        mb: 0,
                        fontSize: 15,
                    }}
                >
                    {title}
                </Typography>
                {role !== 'auditor' && (
                    <>
                        {standard && !isCategoryAssigned && metric?.totalValue > 0 ? (
                            <>
                                <IconButton
                                    sx={{ color: theme.palette.gray[900], minWidth: 'unset', p: 0 }}
                                    onClick={openMenu}
                                >
                                    <MoreVert />
                                </IconButton>
                                <AppTableActionMenu
                                    anchor={anchor}
                                    closeMenu={closeMenu}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    {/* <MenuItem
                                sx={{
                                    fontSize: 13,
                                    fontWeight: 500,
                                    color: theme.palette.gray[700],
                                }}
                            >
                                Generate Report
                            </MenuItem> */}
                                    {!isCategoryAssigned && user_type !== 'auditor' ? (
                                        <MenuItem sx={{ fontSize: 12 }} onClick={handleOpenTaskModal}>
                                            Assign to:
                                        </MenuItem>
                                    ) : null}
                                </AppTableActionMenu>
                            </>
                        ) : null}
                    </>
                )}
            </Stack>

            {standard === 'cii' && (
                <Typography sx={{ color: '#77777A', fontWeight: 500, mt: 1.5, fontSize: 11 }}>{description}</Typography>
            )}
        </GroupCard>
    );
};

export default CategoryCard;
