import React from 'react';
import moment from 'moment';

const AppDateInput = (props) => {
    const { label, name, onChange, value, errors, max, placeholder, height } = props;
    return (
        <>
            <label htmlFor={label} className={`block text-[14px] ${errors ? 'text-[#D91B1B]' : 'text-[#15161A]'} mt-3`}>
                {label}
            </label>
            <input
                type="date"
                id={label}
                name={name}
                onChange={onChange}
                placeholder={placeholder}
                className={`w-full py-2 px-3 ${
                    errors ? 'border-[#D91B1B]' : 'border-[#E2E8F0]'
                } border-[0.5px] text-[#15161A] text-[14px] outline-0 rounded-[4px] focus:outline-0`}
                style={{ color: '#15161A', height: height ? height : '60px' }}
                max={max}
                value={moment(new Date(value)).format('YYYY-MM-DD')}
            />
        </>
    );
};

export default AppDateInput;
