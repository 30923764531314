import React, { useState } from 'react';
import { Stack } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import more from '../../../assets/img/more.svg';

//redux
import { connect, useSelector } from 'react-redux';
// import { GetMerchantAsvscanReportById } from 'store/actions/merchantActions';

//translations
import { useTranslation } from 'react-i18next';

const PCIScansTableActions = (props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedRow, setSelectedRow] = useState(null);

    const handleClick = (event, row) => {
        setAnchorEl(event.currentTarget);
        setSelectedRow(row);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setSelectedRow(null);
    };

    const { row, openDownloadPCIReportModal, openRerunModal, openViewDetails, openOnlySchedulingModal } = props;

    //accessing values from the redux store
    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);

    //translation
    const { t } = useTranslation('pciscans');

    // functions

    const handleOpenReportModal = () => {
        const payload = {
            id: row?.id,
        };
        openDownloadPCIReportModal(payload);
    };

    const handleOpenRerunModal = () => {
        const payload = {
            name: row?.name,
            ip_address: row?.ip_address,
        };

        openRerunModal(payload);
    };

    const handleOpenOnlySchedulingModal = () => {
        const payload = {
            schedule: row?.runschedule,
        };
        openOnlySchedulingModal('update', payload, row?.id);
    };

    const handleOpenViewDetails = () => {
        const payload = {
            name: row?.name,
            ip_address: row?.ip_address,
            progress: row?.progress,
            status: row?.status,
            uploaded_by: row?.uploaded_by,
            date_created: row?.date_created,
            frequency: row?.frequency,
        };

        openViewDetails(payload, row?.id);
    };

    return (
        <>
            <Stack direction="row">
                <>
                    <img
                        onClick={handleClick}
                        src={more}
                        className="cursor-pointer text-[#64748B] text-[14px] font-semibold"
                        alt="More"
                    />

                    <div className="relative left-[73px]">
                        <Menu
                            anchorEl={anchorEl}
                            open={Boolean(anchorEl) && selectedRow?.key === row.key}
                            onClose={handleClose}
                            PaperProps={{
                                style: {
                                    backgroundColor: 'white',
                                    width: '179px',
                                    borderRadius: '2px',
                                },
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleOpenViewDetails();
                                    handleClose();
                                }}
                                style={{
                                    height: '44px',
                                    paddingLeft: '16px',
                                    color: '#64748B',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    marginTop: '-8px',
                                    borderTop: '1px solid #F1F5F9',
                                    borderLeft: '1px solid  #F1F5F9',
                                    borderRight: '1px solid #F1F5F9',
                                    borderBottom: '0.5px solid #F1F5F9',
                                }}
                            >
                                View Details
                            </MenuItem>
                            {user_type !== 'auditor' && role !== 'auditor' && (
                                <MenuItem
                                    style={{
                                        height: '44px',
                                        paddingLeft: '16px',
                                        color: '#64748B',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        borderTop: '1px solid #F1F5F9',
                                        borderLeft: '1px solid  #F1F5F9',
                                        borderRight: '1px solid #F1F5F9',
                                        borderBottom: '0.5px solid #F1F5F9',
                                    }}
                                    onClick={() => {
                                        handleOpenRerunModal();
                                        handleClose();
                                    }}
                                    disabled={
                                        row?.progress === 'running' ||
                                        row?.progress === 'rerunning' ||
                                        row?.uploaded_by?.toLowerCase() !== 'clone systems'
                                    }
                                >
                                    {t('pciscansTableAction.header1')}
                                </MenuItem>
                            )}

                            {user_type !== 'auditor' && role !== 'auditor' && (
                                <MenuItem
                                    sx={{
                                        height: '44px',
                                        paddingLeft: '16px',
                                        color: '#64748B',
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        borderTop: '1px solid #F1F5F9',
                                        borderLeft: '1px solid  #F1F5F9',
                                        borderRight: '1px solid #F1F5F9',
                                        borderBottom: '0.5px solid #F1F5F9',
                                    }}
                                    onClick={() => {
                                        handleOpenOnlySchedulingModal();
                                        handleClose();
                                    }}
                                    disabled={row?.uploaded_by?.toLowerCase() !== 'clone systems'}
                                >
                                    {' '}
                                    {t('pciscansTableAction.header3')}{' '}
                                </MenuItem>
                            )}
                            <MenuItem
                                sx={{
                                    height: '44px',
                                    paddingLeft: '16px',
                                    color: '#64748B',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    marginBottom: '-8px',
                                    borderTop: '1px solid #F1F5F9',
                                    borderLeft: '1px solid  #F1F5F9',
                                    borderRight: '1px solid #F1F5F9',
                                    borderBottom: '0.5px solid #F1F5F9',
                                }}
                                onClick={() => {
                                    handleOpenReportModal();
                                    handleClose();
                                }}
                                disabled={row?.progress === 'running'}
                            >
                                {t('pciscansTableAction.header2')}
                            </MenuItem>
                        </Menu>
                    </div>
                </>
            </Stack>
        </>
    );
};

export default connect()(PCIScansTableActions);
