import React, { useState } from 'react';
import { ReactComponent as WhiteLogo } from 'assets/img/brand/secureLogo.svg';
import { ReactComponent as BlueLogo } from 'assets/img/brand/secureLogo.svg';
import { ReactComponent as GridDot } from 'assets/img/grid-dot.svg';
import { ReactComponent as AdhereIcon } from 'assets/img/Adhere.svg';
import { Box, Menu, MenuItem } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import LoadingState from 'components/new_components/LoadingState';
import { SwitchToken } from 'store/actions/merchantActions';
import { toast } from 'react-toastify';

const Brand = (props) => {
    const { type } = props;
    const [loading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(false);
    const open = Boolean(anchorEl);

    // redux
    const dispatch = useDispatch();
    const user_details = useSelector((state) => state?.generalReducers?.user_info);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSubmit = async () => {
        const body = {
            user: user_details?.id,
        };
        setLoading(true);
        const res = await dispatch(SwitchToken(body));
        setLoading(false);
        if (res?.success) {
            window.location.href = res?.data?.redirect_url;
        } else {
            toast.error(res?.message);
        }
    };

    return (
        <Box sx={{ mx: 3, my: 3.5, display: 'flex', alignItems: 'center' }}>
            <div onClick={handleClick} className="cursor-pointer">
                <GridDot />
            </div>
            {type === 'blue' ? (
                <BlueLogo className="w-[120px] pl-[-1rem]" />
            ) : (
                <WhiteLogo className="w-[120px] pl-[-1rem]" />
            )}
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                sx={{ '& .MuiMenu-paper': { maxWidth: '270px', width: '100%', px: 0 } }}
            >
                {' '}
                <MenuItem onClick={handleSubmit}>
                    <p className="text-[#64748B] text-xs font-normal">Switch to:</p>
                </MenuItem>
                <div className="mt-2">
                    <MenuItem onClick={handleSubmit}>
                        <div className="flex justify-between items-center gap-10">
                            <div className="flex  items-center gap-2">
                                <AdhereIcon />
                                <p className="text-sm text-[#475569] font-semibold">Adhere</p>
                            </div>
                            {loading && <LoadingState size={18} />}
                        </div>
                    </MenuItem>
                </div>
            </Menu>
        </Box>
    );
};

export default Brand;
