export const steps = [
    {
        title: 'My Vendors',
        description: 'This displays all vendors that you’ve added',
    },
    {
        title: 'Add Vendor',
        description: 'Here you can add new vendors',
    },
];
