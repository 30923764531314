import React, { useState, useContext, useMemo, useEffect } from 'react';
import { useTheme } from '@mui/material/styles';
import { AppBar, Box, Toolbar, IconButton, Typography, Button, MenuItem, Menu, Stack } from '@mui/material';
import { ArrowDropDown } from '@mui/icons-material';

// core components
import UserDropdown from './UserDropdown';
import NotificationDropdown from './NotificationDropdown';
import { SelectedMerchantContext } from 'store/context/SelectedMerchantContextProvider';
// import TipsEnableButton from './TipsEnableButton';
import { useSelector } from 'react-redux';

const AuditorHeader = (props) => {
    const theme = useTheme();
    const { openDrawer, title, openNotificationModal, notificationModalOpen, auditor_merchants } = props;

    const { activeMerchant, setActiveMerchant, setMerchantStandards } = useContext(SelectedMerchantContext);

    const user_type = useSelector((state) => state.authReducers?.user_type);

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuItemClick = (event, selected) => {
        setActiveMerchant(selected?.id);
        setMerchantStandards(selected?.paid_standards);

        setAnchorEl(null);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const selectedMerchant = useMemo(() => {
        return auditor_merchants?.find((merchant) => merchant?.id === activeMerchant);
    }, [activeMerchant]);

    useEffect(() => {
        if (auditor_merchants?.length) {
            setActiveMerchant(auditor_merchants?.[0]?.id);
            setMerchantStandards(auditor_merchants?.[0]?.paid_standards);
        }
    }, [auditor_merchants?.length]);

    console.log(auditor_merchants);

    return (
        <AppBar
            position="fixed"
            sx={{
                width: { md: 'calc(100% - 275px)' },
                ml: { md: '275px' },
                backgroundColor: theme.palette.white.main,
                justifyContent: 'center',
                height: { xs: 70, md: 100 },
                boxShadow: 'none',
                zIndex: 800,
                borderBottom: '4px solid ' + theme.palette.primary[100],
            }}
        >
            <Toolbar>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={openDrawer}
                    sx={{ display: { md: 'none' } }}
                >
                    <Menu color="primary" sx={{ fontSize: '2rem' }} />
                </IconButton>
                <Box
                    sx={{
                        flexGrow: 1,
                    }}
                >
                    <Typography
                        variant="h1"
                        noWrap
                        component="h1"
                        sx={{
                            m: 0,
                            fontSize: 24,
                            fontWeight: 700,
                        }}
                    >
                        {title}
                    </Typography>
                    {user_type === 'auditor' ? (
                        <Stack direction="row" alignItems="center" gap={1}>
                            <Typography
                                noWrap
                                sx={{ fontSize: 16, fontWeight: 700, color: theme.palette.primary.main }}
                            >
                                Merchant:
                            </Typography>
                            <Button
                                id="basic-button"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={handleClick}
                                variant="text"
                                endIcon={<ArrowDropDown />}
                                sx={{
                                    fontSize: 14,
                                    fontWeight: 500,
                                    color: theme.palette.primary.main,
                                    textTransform: 'capitalize',
                                    minHeight: 'unset',
                                    '&:hover': {
                                        backgroundColor: 'transparent',
                                    },
                                }}
                            >
                                {selectedMerchant
                                    ? selectedMerchant?.company || `${selectedMerchant?.name}`
                                    : 'No merchant assigned to this Auditor'}
                            </Button>
                            <Menu
                                id="basic-menu"
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                {auditor_merchants?.map((option) => (
                                    <MenuItem
                                        key={option?.id}
                                        selected={option?.id === activeMerchant}
                                        onClick={(event) => handleMenuItemClick(event, option)}
                                        sx={{ fontSize: 14, fontWeight: 500 }}
                                    >
                                        {option?.company || `${option?.owner_detail?.name}`}
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Stack>
                    ) : null}
                </Box>
                <NotificationDropdown
                    openNotificationModal={openNotificationModal}
                    notificationModalOpen={notificationModalOpen}
                />
                {/* <TipsEnableButton /> */}
                <UserDropdown />
            </Toolbar>
        </AppBar>
    );
};

export default AuditorHeader;
