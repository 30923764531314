import React, { useState, useEffect, useMemo, useRef, useContext } from 'react';
// import IntroductionModal from './IntroductionModal';
import { Tooltip, Typography } from '@mui/material';
import { MoreHoriz, AddCircleOutline } from '@mui/icons-material';
import AppTable from 'components/new_components/app-table';
// import { ALL_DATA } from './util';
import useSearch from 'hooks/useSearch';
import AppButton from 'components/new_components/AppButton';
import StatusTag from './StatusTag';
import { formatDateObjectHandler } from 'utils';
import ComplianceTableActions from './CodeReviewActions';
import RunScanModal from './RunScanModal';
import ScanDeleteModal from './ScanDeleteModal';
import ScanReportModal from './ScanReportModal';

//redux
import { connect } from 'react-redux';
import { GetAllCodeReviewScans } from 'store/actions/apiComplianceActions';
import { toast } from 'react-toastify';
import PageHeader from 'components/new_components/PageHeader';
import OverviewModal from '../components/OverviewModal';
import { GetModuleGuides, CreateModuleGuide, UpdateModuleGuide } from 'store/actions/generalActions';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';
import { Tour } from 'antd';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import { useSelector } from 'react-redux';
import AppArticle from 'components/new_components/AppArticle';

const NO_PER_PAGE = 8;
const APICompliance = (props) => {
    const {
        GetAllCodeReviewScans,
        source_code,
        guideModal,
        GetModuleGuides,
        CreateModuleGuide,
        UpdateModuleGuide,
        all_article_header,
    } = props;
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const userguide_info = useSelector((state) => state?.guideReducers?.userguide_info?.[0]?.codebase_scanner);

    // const [introModalOpen, setIntroModalOpen] = useState(false);
    const [runModalOpen, setRunModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [reportModalOpen, setReportModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalPayload, setModalPayload] = useState(null);
    const [loading, setLoading] = useState({ fetching: false, rerunning: false });
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    const formattedTableData = useMemo(() => {
        if (source_code?.length) {
            return source_code?.map((scan) => {
                return {
                    id: scan?.id,
                    url: scan?.url,
                    frequency: scan?.frequency,
                    status: scan?.status,
                    token: scan?.token,
                    date_created: scan?.date_created,
                    date_updated: scan?.date_updated,
                    merchant_id: scan?.merchant_id,
                };
            });
        } else {
            return [];
        }
    }, [source_code]);

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Codebase Security Scanner'),
        [all_article_header]
    );

    // hooks
    const { data, handleSearch } = useSearch(formattedTableData, ['url', 'status']);
    const ref1 = useRef(null);

    const steps = [
        {
            title: <UserGuideHeader title="New Code Review" />,
            description: 'The codebase security scanner scans your repositories and searches for vulnerabilities.',
            placement: 'leftTop',
            target: () => ref1.current,
        },
    ];

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ codebase_scanner: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, codebase_scanner: false });
        setOpenArticle(false);
    };

    //functions
    const handlePageChange = (page) => {
        setPage(page);
    };
    //modals controls
    const openRunScanModal = () => {
        setRunModalOpen(true);
    };
    const closeRunScanModal = () => {
        setRunModalOpen(false);
        setModalPayload(null);
    };
    // const closeIntroModal = () => {
    //     setIntroModalOpen(false);
    // };
    const openDeleteModal = (data) => {
        setModalPayload(data);
        setDeleteModalOpen(true);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setModalPayload({});
    };
    const openReportModal = (data) => {
        setReportModalOpen(true);
        setModalPayload(data);
    };
    const closeReportModal = () => {
        setReportModalOpen(false);
        setTimeout(() => {
            setModalPayload({});
        }, [500]);
    };

    const columns = [
        {
            title: 'Repo Link',
            key: 'url',
            render: (row) => (
                <Tooltip placement="top" title={row?.url}>
                    <Typography>{row?.url?.substring(0, 40)}...</Typography>
                </Tooltip>
            ),
        },
        {
            title: 'Date scanned',
            key: 'server',
            render: (row) => <>{formatDateObjectHandler(row?.date_created, 'MMMM Do, YYYY')}</>,
        },
        {
            title: 'Frequency',
            key: 'frequency',
            render: (row) => <>{row?.frequency}</>,
        },
        {
            title: 'Status',
            key: 'status',
            render: (row) => (
                <StatusTag text={row?.status} type={row?.status === 'completed' ? 'success' : 'default'} />
            ),
        },
        {
            title: <MoreHoriz />,
            align: 'center',
            key: 'action',
            render: (row) => (
                <ComplianceTableActions
                    row={row}
                    openDeleteModal={openDeleteModal}
                    openReportModal={openReportModal}
                    rerunning={loading?.rerunning}
                    openRunScanModal={openRunScanModal}
                    setModalPayload={setModalPayload}
                />
            ),
        },
    ];

    //async functions
    const getAllAPIScans = async () => {
        setLoading({ ...loading, fetching: true });
        const res = GetAllCodeReviewScans();
        setLoading({ ...loading, fetching: false });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        toggleUserGuide.codebase_scanner ? OpenArticle() : null;
        !userguide_info ? OpenUserGuide() : null;
    }, [userguide_info, toggleUserGuide.codebase_scanner]);
    useEffect(() => {
        getAllAPIScans();
    }, []);
    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;

        setPageData(data?.length > 0 ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : []);
        return data;
    }, [page, data]);

    const getModuleGuides = async () => {
        const res = GetModuleGuides();
        if (!res?.success) {
            toast.error(res?.message);
        }
    };
    const updateModuleIntroModal = async () => {
        const res =
            guideModal && guideModal[0]
                ? await UpdateModuleGuide({ source_code: true }, guideModal[0]?.id)
                : await CreateModuleGuide({ source_code: true });
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        getModuleGuides();
    }, []);

    useEffect(() => {
        if (!guideModal?.[0]?.source_code) {
            setOpen(true);
        }
    }, [guideModal]);

    return (
        <>
            <PageHeader browserTitle="Source Code Review | Smartcomply" />
            <AppTable
                columns={columns}
                data={pageData}
                sorter={(a, b) => b?.id - a?.id}
                loading={loading.fetching}
                dataLength={data?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                actions={[
                    <AppButton
                        icon={<AddCircleOutline />}
                        name="New Code Review"
                        color="primary"
                        variant="contained"
                        disableElevation
                        onClick={openRunScanModal}
                        key={1}
                        ref={ref1}
                    />,
                ]}
                search={true}
                onSearch={handleSearch}
            />
            {/* <IntroductionModal
                open={introModalOpen}
                handleClose={closeIntroModal}
                updateModuleIntroModal={updateModuleIntroModal}
            /> */}
            <RunScanModal open={runModalOpen} handleClose={closeRunScanModal} payload={modalPayload} />
            <ScanDeleteModal open={deleteModalOpen} handleClose={closeDeleteModal} payload={modalPayload} />
            <ScanReportModal open={reportModalOpen} handleClose={closeReportModal} payload={modalPayload} />
            <OverviewModal
                open={open}
                handleClose={() => setOpen(false)}
                updateModuleIntroModal={updateModuleIntroModal}
            />
            <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} targets={steps.target} />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Codebase Scans"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        source_code: state?.apiComplianceReducers?.source_code,
        guideModal: state?.generalReducers?.module,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};
export default connect(mapStateToProps, {
    GetAllCodeReviewScans,
    GetModuleGuides,
    CreateModuleGuide,
    UpdateModuleGuide,
})(APICompliance);
