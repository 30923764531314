import React from 'react';
import { Container, Box, Stack, Typography } from '@mui/material';

const TabLayoutModified = (props) => {
    const { children, onTabChange, currentTab, tabs, tabAction, ref1, ref2 } = props;

    const handleTabChange = (_, tab) => {
        onTabChange(tab);
    };
    return (
        <Container
            maxWidth={false}
            elevation={0}
            sx={{
                p: '0 !important',
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                mb: 5,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#FFFFFF',
                    px: 3,
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 3.5,
                    }}
                >
                    {tabs.map((tab, index) => (
                        <Box
                            key={tab.id}
                            onClick={() => handleTabChange(index, tab.id)}
                            sx={{ cursor: 'pointer' }}
                            ref={index === 0 ? ref1 : index === 1 ? ref2 : null}
                        >
                            <Typography
                                sx={{
                                    color: currentTab === tab?.id ? '#202D66' : '#64748B',
                                    fontSize: '14px',
                                    fontWeight: 500,
                                    textAlign: 'right',
                                    pt: currentTab === tab?.id ? 1.5 : 0,
                                    pb: currentTab === tab?.id ? 1 : 0,
                                }}
                            >
                                {tab.name}
                            </Typography>
                            {currentTab === tab?.id && (
                                <Box
                                    sx={{
                                        backgroundColor: '#202D66',
                                        width: '100%',
                                        pt: 0.5,
                                    }}
                                ></Box>
                            )}
                        </Box>
                    ))}
                </Box>
                {tabAction && <Box>{tabAction}</Box>}
            </Box>
            <Stack sx={{ flex: 1 }}>{children}</Stack>
        </Container>
    );
};

export default TabLayoutModified;
