import React from 'react';
import { Container, Stack } from '@mui/material';

// core components
import AppTabsBarNew from 'components/new_components/tabs/AppTabsBarNew';

const TabLayout = (props) => {
    const { children, onTabChange, tabs, tab, ref1, ref2, ref3 } = props;
    return (
        <Container
            maxWidth={false}
            elevation={0}
            sx={{
                p: '0 !important',
                minHeight: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: 'transparent',
                borderTop: '1px solid #F1F5F9',
                mb: 5,
            }}
        >
            <AppTabsBarNew currentTab={tab} tabs={tabs} onChange={onTabChange} ref1={ref1} ref2={ref2} ref3={ref3} />
            <Stack px={{ flex: 1 }}>{children}</Stack>
        </Container>
    );
};

export default TabLayout;
