import React, { useState, useEffect, useContext, useRef, useMemo } from 'react';

// core components
import ComplianceLayout from '../common/ComplianceLayout';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import Criteria from './Criteria';
import Exceptions from './Exceptions';
import PageHeader from 'components/new_components/PageHeader';

//userguide
import { Tour } from 'antd';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import AppArticle from 'components/new_components/AppArticle';
import { useSelector } from 'react-redux';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';

const Blockchain = () => {
    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);

    //userguide
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);
    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const userguide_info = useSelector((state) => state?.guideReducers?.userguide_info?.[0]?.compliance);
    const all_article_header = useSelector((state) => state?.articlesReducers?.all_article_header);

    const STEPS_LENGTH = 2;

    const steps = [
        {
            title: <UserGuideHeader id={1} title="Criteria" length={STEPS_LENGTH} />,
            description: 'Here you can Upload Criteria doc, evidences and policies.',
            target: () => ref1.current,
            placement: 'rightTop',
        },

        {
            title: <UserGuideHeader id={2} title="Exceptions" length={STEPS_LENGTH} />,
            description: 'This tab displays questions and documents that are exempted',
            target: () => ref2.current,
            placement: 'rightTop',
        },
    ];

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article.name === 'Compliance'),
        [all_article_header]
    );

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ compliance: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, compliance: false });
        setOpenArticle(false);
    };

    // functions
    // logic functions
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
    };

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.compliance ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.compliance]);

    return (
        <>
            <PageHeader browserTitle="CII DIRECTIVE - Compliance | Smartcomply" />
            <ComplianceLayout onTabChange={handleTabChange} tab={currentTab} standard="cii" ref1={ref1} ref2={ref2}>
                <AppTabPanel value={currentTab} index={0}>
                    <Criteria />
                </AppTabPanel>
                <AppTabPanel value={currentTab} index={1}>
                    <Exceptions />
                </AppTabPanel>
            </ComplianceLayout>

            <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Compliance"
                headerId={oneArticle?.id}
                icon={true}
            />
        </>
    );
};
export default Blockchain;
