import React from 'react';
import { useTheme } from '@mui/material/styles';
import { Button, CardHeader, Stack, Typography } from '@mui/material';
import AppInput from '../AppInput';
import searchIcon from '../../../assets/img/search.svg';

//translations
import { useTranslation } from 'react-i18next';

const TableTopbar = (props) => {
    // const { title, actions, rowSpacing, search, onSearch, tabs, toggleTabs, tabState, ref1, ref2 } = props;
    const { title, actions, search, onSearch, toggleTabs, tabState, ref1, ref2 } = props;
    const theme = useTheme();

    //translation
    const { t } = useTranslation('common');

    return (
        <CardHeader
            title={
                <Stack
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '16px',
                        marginLeft: '-16px',
                        marginBottom: '-12px',
                    }}
                >
                    <div className="flex gap-[8px]">
                        <Button
                            sx={{
                                textTransform: 'capitalize',
                                width: '104px',
                                height: '32px',
                                display: 'flex',
                                fontSize: '14px',
                                fontWeight: 500,
                                border: '1px solid #E2E8F0',
                                alignItems: 'center',
                                justifyContent: 'center',
                                borderRadius: '16px',
                                color: tabState === 'requested' ? theme.palette.white.main : theme.palette.primary[20],
                                background: tabState === 'requested' ? '#395BA9' : theme.palette.white.main,
                                '&:hover': {
                                    background: tabState === 'requested' ? '#395BA9' : theme.palette.white.main,
                                    border: `0.3px solid ${theme.palette.primary[20]}`,
                                },
                            }}
                            variant="outlined"
                            onClick={() => toggleTabs('requested')}
                            ref={ref1}
                        >
                            {t('requested')}
                        </Button>
                        <Button
                            sx={{
                                textTransform: 'capitalize',
                                width: '104px',
                                height: '32px',
                                borderRadius: '16px',
                                border: '1px solid #E2E8F0',
                                fontSize: '14px',
                                fontWeight: 500,
                                color: tabState === 'uploaded' ? theme.palette.white.main : '#475569',
                                background: tabState === 'uploaded' ? '#395BA9' : theme.palette.white.main,
                                '&:hover': {
                                    background: tabState === 'uploaded' ? '#395BA9' : theme.palette.white.main,
                                    border: `0.3px solid ${theme.palette.primary[20]}`,
                                },
                            }}
                            variant="outlined"
                            onClick={() => toggleTabs('uploaded')}
                            ref={ref2}
                        >
                            {t('uploaded')}
                        </Button>
                    </div>

                    {/* {tabs && (
                        <>
                            <Button
                                sx={{
                                    textTransform: 'capitalize',
                                    py: 0.9,
                                    borderRadius: '8px',
                                    borderTopRightRadius: 0,
                                    borderBottomRightRadius: 0,
                                    color:
                                        tabState === 'requested' ? theme.palette.white.main : theme.palette.primary[20],
                                    background: tabState === 'requested' ? '#395BA9' : theme.palette.white.main,
                                    '&:hover': {
                                        background: tabState === 'requested' ? '#395BA9' : theme.palette.white.main,
                                        border: `0.3px solid ${theme.palette.primary[20]}`,
                                    },
                                }}
                                variant="outlined"
                                onClick={() => toggleTabs('requested')}
                                ref={ref1}
                            >
                                {t('requested')}
                            </Button>
                            <Button
                                sx={{
                                    textTransform: 'capitalize',
                                    py: 0.9,
                                    borderRadius: '8px',
                                    borderTopLeftRadius: 0,
                                    borderBottomLeftRadius: 0,
                                    mr: 3,
                                    color:
                                        tabState === 'uploaded' ? theme.palette.white.main : theme.palette.primary[20],
                                    background: tabState === 'uploaded' ? '#395BA9' : theme.palette.white.main,
                                    border: `1px solid ${theme.palette.primary[20]}`,
                                    '&:hover': {
                                        background: tabState === 'uploaded' ? '#395BA9' : theme.palette.white.main,
                                        border: `0.3px solid ${theme.palette.primary[20]}`,
                                    },
                                }}
                                variant="outlined"
                                onClick={() => toggleTabs('uploaded')}
                                ref={ref2}
                            >
                                {t('uploaded')}
                            </Button>
                        </>
                    )} */}
                    {search ? (
                        <div className="relative">
                            <img
                                src={searchIcon}
                                alt="search icon"
                                className="absolute top-[14px] left-[11px] right-[11px]"
                            />
                            <AppInput
                                placeholder={t('search')}
                                className="shadow-2xl"
                                sx={{
                                    maxWidth: '312px',
                                    height: '36px',
                                    fontSize: '14px',
                                    fontWeight: 400,
                                    paddingLeft: '22px',
                                    border: '1px solid #E2E8F0',
                                    color: '#94A3B8',
                                    '& input::placeholder': {
                                        color: '#94A3B8',
                                        fontWeight: 400,
                                    },
                                }}
                                controlStyle={{ mt: -'0.8px', width: 'fit-content' }}
                                onChange={onSearch}
                            />
                        </div>
                    ) : (
                        <Typography variant="h3" component="h3" sx={{ marginBottom: '0!important', color: 'primary' }}>
                            {title}
                        </Typography>
                    )}
                </Stack>
            }
            action={
                <Stack direction="row" spacing={1}>
                    {/* {search && (
                        <AppInput
                            placeholder="Search..."
                            sx={{
                                maxWidth: 200,
                                borderColor: theme.palette.gray[30],
                                '& input::placeholder': {
                                    color: theme.palette.gray[40],
                                    fontWeight: 600,
                                },
                            }}
                            controlStyle={{ mt: 0, width: 'fit-content' }}
                            onChange={onSearch}
                        />
                    )} */}
                    {actions}
                </Stack>
            }
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', pb: '16px' }}
        />
    );
};

export default TableTopbar;
