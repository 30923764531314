import React, { useState } from 'react';
import { Box, Button, Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

//redux
import { connect } from 'react-redux';
import { HiCheckCircle } from 'react-icons/hi';
import { AiFillExclamationCircle } from 'react-icons/ai';

//core components
import AppViewModal from 'components/new_components/AppViewModal';
import FileCheck from 'assets/img/FileCheck.svg';
import TextSnippet from 'assets/img/textSnippet.svg';
import Download from 'assets/img/download01.svg';
import ApproveDeclinePolicyModal from './ApproveDeclinePolicyModal';
import moment from 'moment';

const PolicyApprovalModal = (props) => {
    //props
    const { open, handleClose, all_merchant_documents, standard, user_id } = props;
    const [approveModal, setApproveModal] = useState(false);
    const [declineModal, setDeclineModal] = useState(false);
    const [policy_id, setPolicy_id] = useState();

    const openApproveModal = () => {
        setApproveModal(true);
    };

    const closeApproveModal = () => {
        setApproveModal(false);
    };

    const openDeclineModal = () => {
        setDeclineModal(true);
    };

    const closeDeclineModal = () => {
        setDeclineModal(false);
    };

    const theme = useTheme();

    const filter_documents_by_approved = all_merchant_documents?.filter(
        (doc) => doc?.tag === standard && doc?.approved_status === 'pending' && doc?.approved_by === user_id
    );

    return (
        <AppViewModal open={open} handleClose={handleClose} width={520}>
            <Box sx={{ my: 1.5 }}>
                <Box sx={{ display: 'flex', mx: 3, pb: 2, alignItems: 'center' }}>
                    <ArrowBackIosIcon
                        onClick={handleClose}
                        sx={{ width: 20, height: 20, color: '#000000', cursor: 'pointer' }}
                    />
                    <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#202D66', ml: 2 }}>
                        Policy Approval
                    </Typography>
                </Box>
                <Divider sx={{ color: theme.palette.neutral[95] }} />
                {filter_documents_by_approved?.length === 0 ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 10 }}>
                        <Typography sx={{ color: '#64748B', fontWeight: 600, fontSize: '16px' }}>
                            No policy pending approval
                        </Typography>
                    </Box>
                ) : (
                    <Box sx={{ my: 1.5 }}>
                        {filter_documents_by_approved?.map((policy, index) => {
                            const requestDate = new Date(policy?.date_created);

                            // Get today's date
                            const todaysDate = new Date();

                            const validity = requestDate.setHours(0, 0, 0, 0) == todaysDate.setHours(0, 0, 0, 0);
                            return (
                                <Box
                                    key={index}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'start',
                                        borderBottom: `1px solid ${theme.palette.neutral[95]}`,
                                        px: 2,
                                        py: 1.5,
                                        cursor: 'pointer',
                                        wordBreak: 'break-word !important',
                                        '&:hover': {
                                            backgroundColor: '#F8FAFC',
                                        },
                                    }}
                                >
                                    <img
                                        src={FileCheck}
                                        alt="FileCheck"
                                        className="mt-1 bg-[#F8FAFC] rounded-2xl p-1"
                                    />
                                    <Box sx={{ ml: 1.5 }}>
                                        <Typography
                                            sx={{
                                                color: '#64748B',
                                                fontWeight: 400,
                                                fontSize: '16px',
                                                wordBreak: 'break-word',
                                            }}
                                        >
                                            A policy needs approval -- Requested by{' '}
                                            <Typography
                                                component="span"
                                                sx={{ fontWeight: 700, wordBreak: 'break-word' }}
                                            >
                                                {policy?.requester_name}
                                            </Typography>
                                        </Typography>
                                        <Typography sx={{ color: '#94A3B8', fontWeight: 500, fontSize: '11px' }}>
                                            {validity && moment(policy?.date_created)?.minutes() < 58
                                                ? `${moment(policy?.date_created)?.minutes()} minutes ago`
                                                : validity && moment(policy?.date_created)?.hours() > 23
                                                ? `${moment(policy?.date_created)?.hours()} hours ago`
                                                : moment(policy?.date_created)?.format('Do MMM, YYYY')}
                                        </Typography>
                                        <Box
                                            sx={{
                                                mt: 1.5,
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <img
                                                    src={TextSnippet}
                                                    alt="TextSnippet"
                                                    className="mt-1 bg-[#F8FAFC] rounded p-1"
                                                />
                                                <Typography
                                                    sx={{
                                                        color: '#395BA9',
                                                        fontWeight: 500,
                                                        fontSize: '12px',
                                                        ml: 1,
                                                        wordBreak: 'break-word',
                                                    }}
                                                >
                                                    {policy?.document.split('/').pop()}
                                                </Typography>
                                            </Box>
                                            {policy?.approved_status === 'pending' && (
                                                <Box
                                                    component="a"
                                                    href={policy?.document}
                                                    rel="noreferrer"
                                                    target="_blank"
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        ml: 1,
                                                    }}
                                                >
                                                    {' '}
                                                    <img src={Download} alt="Download" />
                                                </Box>
                                            )}
                                        </Box>

                                        {policy?.approved_status === 'pending' && (
                                            <Box
                                                sx={{
                                                    mt: 1.5,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Button
                                                    variant="contained"
                                                    sx={{
                                                        textTransform: 'inherit',
                                                        borderRadius: 1,
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                        px: 1,
                                                        py: 0.5,
                                                    }}
                                                    onClick={() => {
                                                        setPolicy_id(policy?.id);
                                                        openApproveModal();
                                                    }}
                                                >
                                                    Approve
                                                </Button>
                                                <Button
                                                    variant="outlined"
                                                    sx={{
                                                        ml: 1.5,
                                                        textTransform: 'inherit',
                                                        color: '#334155',
                                                        border: '1px solid #E2E8F0',
                                                        borderRadius: 1,
                                                        fontSize: '14px',
                                                        fontWeight: 500,
                                                        px: 1,
                                                        py: 0.5,
                                                        '&:hover': {
                                                            border: '1px solid #E2E8F0',
                                                        },
                                                    }}
                                                    onClick={() => {
                                                        setPolicy_id(policy?.id);
                                                        openDeclineModal();
                                                    }}
                                                >
                                                    Decline
                                                </Button>
                                            </Box>
                                        )}
                                        {policy?.approved_status === 'approved' && (
                                            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', ml: 0.5 }}>
                                                <HiCheckCircle color="#37A372" size={16} />
                                                <Typography
                                                    sx={{ color: '#64748B', fontWeight: 500, fontSize: '14px', ml: 1 }}
                                                >
                                                    {policy.approved_status}
                                                </Typography>
                                            </Box>
                                        )}
                                        {policy?.approved_status === 'declined' && (
                                            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', ml: 0.5 }}>
                                                <AiFillExclamationCircle size={16} color="#EB4141" />
                                                <Typography
                                                    sx={{ color: '#64748B', fontWeight: 500, fontSize: '14px', ml: 1 }}
                                                >
                                                    {policy.approved_status}
                                                </Typography>
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            );
                        })}
                    </Box>
                )}
            </Box>
            <ApproveDeclinePolicyModal
                open={approveModal}
                handleClose={closeApproveModal}
                type="approve"
                policy_id={policy_id}
            />
            <ApproveDeclinePolicyModal
                open={declineModal}
                handleClose={closeDeclineModal}
                type="decline"
                policy_id={policy_id}
            />
        </AppViewModal>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_documents: state?.complianceReducers?.all_merchant_documents,
        user_id: state?.generalReducers?.user_info?.id,
    };
};
export default connect(mapStateToProps, {})(PolicyApprovalModal);
