import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

const AppCheckboxInput = ({ name, label, onChange, value, sx, checkboxProps, indeterminate, disabled }) => {
    return (
        <FormControlLabel
            name={name}
            sx={{
                mt: 2,
                color: '#395BA9',
                fontWeight: 500,
                fontSize: 13,
                ...sx,
                '& .MuiTypography-root': { fontSize: '13px' },
            }}
            control={
                <Checkbox
                    indeterminate={indeterminate || false}
                    onChange={onChange}
                    checked={value || false}
                    size="small"
                    sx={{ width: 12, height: 12, color: '#395BA9', mr: 1, ml: 1.5 }}
                    disabled={disabled || false}
                    {...checkboxProps}
                />
            }
            label={label}
        />
    );
};

export default AppCheckboxInput;
