import React from 'react';
import { styled } from '@mui/material/styles';
import { CalendarToday } from '@mui/icons-material';
import { DatePicker } from 'antd';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material';

const AppDatePicker = (props) => {
    const { label, ...otherProps } = props;

    const theme = useTheme();
    return (
        <>
            {label && <Typography sx={{ fontSize: '14px', mt: 2, color: theme.palette.gray[900] }}>{label}</Typography>}
            <StyledDatePicker
                {...otherProps}
                showToday
                format="DD/MM/YYYY"
                suffixIcon={<CalendarToday fontSize="small" />}
            />
        </>
    );
};

const StyledDatePicker = styled(DatePicker)(
    ({ theme }) => `
    z-index: 10000000000000 !important;
  box-shadow: none !important;
  border-radius: 4px;
  padding: 0.4rem 0.6rem;
  &, &:hover {
    border: 0.5px solid ${theme.palette.gray[500]}
  }
  &.ant-picker-focused {
    border-color: ${theme.palette.gray[900]}
  }
  & .ant-picker-input {
    & input {
      font-size: 14px;
      max-width: 120px;
      &::placeholder {
        color: ${theme.palette.gray[500]};
      }
    }
    & .ant-picker-clear, .ant-picker-suffix {
      width: 20px;
      height: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`
);

export default AppDatePicker;
