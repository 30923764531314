import React from 'react';
import { ArrowBackIosNew } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import AppViewModal2 from 'components/new_components/AppViewModal2';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import moment from 'moment';

const AboutFraudGuardian = (props) => {
    const theme = useTheme();
    const { open, handleClose, payload } = props;
    return (
        <AppViewModal2
            width={479}
            open={open}
            handleClose={handleClose}
            noClose
            lightBorder
            title={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ArrowBackIosNew
                        sx={{
                            width: 20,
                            height: 20,
                            color: theme.palette.gray[30],
                            mr: 1,
                            borderRadius: '50%',
                            cursor: 'pointer',
                        }}
                        onClick={handleClose}
                    />
                    <Typography
                        sx={{
                            color: '#202D66',
                            fontWeight: 700,
                            fontSize: '16px',
                        }}
                    >
                        Fraud Monitoring details
                    </Typography>
                </Box>
            }
            anchor="right"
        >
            <Box>
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                >
                    <Typography
                        sx={{
                            color: theme.palette.neutral[600],
                            fontWeight: 600,
                            fontSize: '13px',
                        }}
                    >
                        Date:
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.neutral[600],
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '60%',
                            ml: 1,
                        }}
                    >
                        {moment(payload?.date_created).format('DD MMM, YYYY')}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography
                        sx={{
                            color: theme.palette.neutral[600],
                            fontWeight: 600,
                            fontSize: '13px',
                            width: '50%',
                            py: 1,
                        }}
                    >
                        Name
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.neutral[600],
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '50%',
                            borderLeft: '2px solid #F1F5F9',
                            backgroundColor: '#F1F5F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            py: 1,
                            pl: 1,
                        }}
                    >
                        {payload?.name || 'N/A'}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography
                        sx={{
                            color: theme.palette.neutral[600],
                            fontWeight: 600,
                            fontSize: '13px',
                            width: '50%',
                            py: 1,
                        }}
                    >
                        Source Bank
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.neutral[600],
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '50%',
                            borderLeft: '2px solid #F1F5F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            py: 1,
                            pl: 1,
                        }}
                    >
                        {payload?.source_bank}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography
                        sx={{
                            color: theme.palette.neutral[600],
                            fontWeight: 600,
                            fontSize: '13px',
                            width: '40%',
                            py: 1,
                        }}
                    >
                        Website
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.neutral[600],
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '50%',
                            borderLeft: '2px solid #F1F5F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            py: 1,
                            pl: 1,
                        }}
                    >
                        {payload?.website || 'N/A'}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography
                        sx={{
                            color: theme.palette.neutral[600],
                            fontWeight: 600,
                            fontSize: '13px',
                            width: '50%',
                            py: 1,
                        }}
                    >
                        IP Address
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.neutral[600],
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '50%',
                            borderLeft: '2px solid #F1F5F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            py: 1,
                            pl: 1,
                        }}
                    >
                        {payload?.ip_address}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography
                        sx={{
                            color: theme.palette.neutral[600],
                            fontWeight: 600,
                            fontSize: '13px',
                            width: '50%',
                            py: 1,
                        }}
                    >
                        Transaction Status
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.neutral[600],
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '50%',
                            borderLeft: '2px solid #F1F5F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            py: 1,
                            pl: 1,
                        }}
                    >
                        {payload?.transaction_status}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography
                        sx={{
                            color: theme.palette.neutral[600],
                            fontWeight: 600,
                            fontSize: '13px',
                            width: '50%',
                            py: 1,
                        }}
                    >
                        Fraud Percentage
                    </Typography>
                    <Typography
                        sx={{
                            width: '50%',
                            borderLeft: '2px solid #F1F5F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            py: 1,
                            pl: 1,
                        }}
                    >
                        <Typography
                            sx={{
                                border: '2px solid #F1F5F9',
                                color: theme.palette.neutral[600],
                                fontWeight: 500,
                                fontSize: '12px',
                                p: 0.5,
                                borderRadius: 1,
                            }}
                        >
                            {payload?.fraud_percent}%
                        </Typography>
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        borderTop: '2px solid #F1F5F9',
                    }}
                >
                    <Typography
                        sx={{
                            color: theme.palette.neutral[600],
                            fontWeight: 600,
                            fontSize: '13px',
                            width: '50%',
                            py: 1,
                        }}
                    >
                        Status
                    </Typography>
                    <Typography
                        sx={{
                            color: theme.palette.neutral[600],
                            fontWeight: 500,
                            fontSize: '12px',
                            width: '50%',
                            borderLeft: '2px solid #F1F5F9',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            py: 1,
                            pl: 1,
                        }}
                    >
                        {payload?.status === 'null' ? (
                            <Typography
                                sx={{
                                    border: '2px solid #F1F5F9',
                                    color: theme.palette.neutral[600],
                                    fontWeight: 500,
                                    fontSize: '12px',
                                    p: 0.5,
                                    borderRadius: 1,
                                }}
                            >
                                N/A
                            </Typography>
                        ) : (
                            <Typography
                                sx={{
                                    border: '2px solid #F1F5F9',
                                    color: theme.palette.neutral[600],
                                    fontWeight: 500,
                                    fontSize: '12px',
                                    p: 0.5,
                                    borderRadius: 1,
                                }}
                            >
                                <FiberManualRecordIcon
                                    sx={{
                                        width: 12,
                                        height: 12,
                                        color: payload?.status === 'clean' ? '#136207' : '#FF5449',
                                        mr: 0.5,
                                    }}
                                />{' '}
                                {payload?.status}
                            </Typography>
                        )}
                    </Typography>
                </Box>
            </Box>
        </AppViewModal2>
    );
};

export default AboutFraudGuardian;
