import React, { useState } from 'react';
import { toast } from 'react-toastify';
//core
// import { List, ListItem, ListItemText, ListItemAvatar, Avatar, Typography, CircularProgress } from '@mui/material';
import { List, ListItem, ListItemText, Typography, CircularProgress, Box, Button } from '@mui/material';

//translations
import { useTranslation } from 'react-i18next';

import closeModal from '../../assets/img/closeModal.svg';
import fileUploadIcon from '../../assets/img/fileUpload.svg';
import AppCenteredModalNew from './AppCenteredModalNew';
import XslIcon from 'assets/img/xsl.svg';

const AppUploadCSVModal = ({ open, handleClose, title, templateLink, uploadCSVAction }) => {
    //states
    const [uploading, setUploading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    //theme

    //translation
    const { t } = useTranslation('common');

    //functions
    const uploadCSV = (e) => {
        const { files } = e.target;

        const fileExt = files?.[0]?.name?.split('.')?.splice(-1)?.[0];
        const acceptedExt = ['xlsx'];

        if (!acceptedExt?.includes(fileExt)) {
            if (!files?.[0]) {
                return toast.info('Select a file please');
            }
            return toast.info('File is not a valid type. Kindly upload an excel file.');
        }
        setFile(files[0]);
        setUploading(true);
        setUploading(false);
        e.target.value = '';
    };
    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('file_upload', file);

        if (!file) {
            return toast.info('Select a file please');
        } else {
            setLoading(true);
            const res = await uploadCSVAction(formData);
            setLoading(false);
            if (res?.success) {
                toast.success('You have successfully uploaded your pciscan.');
                handleClose();
            } else {
                toast.error(res?.message);
            }
        }
    };
    console.log({ file });
    return (
        <AppCenteredModalNew
            open={open}
            handleClose={handleClose}
            title={
                <div className="flex items-center justify-between w-full h-[52px]">
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, color: '#202D66' }}>{title}</Typography>
                    <div
                        onClick={handleClose}
                        className="w-[24px] h-[24px] cursor-pointer flex items-center justify-center border-[1px] border-solid border-[#E2E8F0] rounded-full"
                    >
                        <img src={closeModal} alt="close" />
                    </div>
                </div>
            }
            width={511}
            actions={
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                        alignItems: 'center',
                        mt: 3,
                        backgroundColor: '#F8FAFC',
                        height: '80px',
                        mx: '-2rem',
                        mb: '-2rem',
                        px: '2rem',
                        pt: '1rem',
                        pb: '4.5rem',
                    }}
                >
                    <Button
                        variant="outlined"
                        sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit', color: '#334155' }}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        sx={{ textTransform: 'inherit', fontWeight: 600 }}
                        onClick={handleSubmit}
                        disabled={loading}
                    >
                        RUN
                    </Button>
                </Box>
            }
        >
            {uploading ? (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: '#00000040',
                        zIndex: 100,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <div
                        style={{
                            width: '30px',
                            height: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress color="inherit" size={25} style={{ color: '#333' }} />
                    </div>
                </div>
            ) : null}

            <List sx={{ marginLeft: '-20px', overflow: 'hidden' }}>
                <ListItem sx={{ alignItems: 'flex-start' }}>
                    <ListItemText
                        flexDirection="row"
                        justifyContent="flex-start"
                        sx={{
                            py: '8px',
                            px: '16px',
                            fontSize: '13px',
                            fontWeight: 400,
                            color: '#5E5E62',
                            background: '#F0F0FA',
                            borderRadius: '4px',
                            gap: '10px',
                            alignItems: 'flex-start',
                            border: '1px solid #F8FAFC',
                            width: '100%',
                            display: 'inline',
                        }}
                    >
                        <Typography sx={{ fontSize: '13px' }}>
                            {t('Having multiple employees to add?.')}{' '}
                            <a
                                href={templateLink}
                                target="_blank"
                                rel="noreferrer"
                                style={{
                                    color: '#395BA9',
                                    textDecoration: 'underline',
                                    fontSize: '13px',
                                }}
                            >
                                {t('download')}
                            </a>{' '}
                            {t('and fill it with the required information.')}
                        </Typography>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <div
                        className="h-[144px] relative w-full  flex items-center justify-center flex-col gap-[8px]"
                        style={{ border: '1px solid #CBD5E1', borderStyle: 'dashed', borderRadius: '4px' }}
                    >
                        <input
                            id="upload-csv"
                            name="upload-csv"
                            type="file"
                            onChange={uploadCSV}
                            accept=".xlsx"
                            width="100%"
                            className="absolute z-10"
                            style={{ height: '144px', width: '100%', opacity: 0, cursor: 'pointer' }}
                        />
                        <img src={fileUploadIcon} alt="upload" />
                        <div className="flex flex-col items-center">
                            <span className="text-[12px] font-medium text-[#64748B]">
                                Drop your files or click to upload
                            </span>
                            <span className="text-[12px] text-[#94A3B8]">Supported file types: EXCEL</span>
                        </div>
                        <buttton className="bg-white flex items-center cursor-pointer justify-center text-[14px] w-[83px] rounded-[2px] h-[36px] font-medium text-[#334155] border-[1px] border-[#E2E8F0] border-solid">
                            Browse
                        </buttton>
                    </div>
                    {/* <ListItemAvatar>
                        <Avatar>
                            <Upload />
                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText sx={{ fontSize: '16px', color: theme.palette.gray[800] }}>
                        <Typography
                            color={theme.palette.gray[900]}
                            sx={{ fontWeight: 500, fontSize: '18px', marginTop: '-8px' }}
                        >
                            {t('uploadFilled')}
                        </Typography>
                        {t('finishedFilling')}
                        <label
                            htmlFor="upload-csv"
                            style={{
                                display: 'inline-flex',
                            }}
                        >
                            <input
                                id="upload-csv"
                                name="upload-csv"
                                type="file"
                                style={{ display: 'none' }}
                                onChange={uploadCSV}
                                accept=".xlsx"
                            />

                            <span
                                style={{
                                    color: theme.palette.primary[900],
                                    textDecoration: 'underline',
                                    paddingRight: '5px',
                                    cursor: 'pointer',
                                }}
                            >
                                {t('click')}
                            </span>
                        </label>
                        {t('uploadFilledFile')}
                    </ListItemText> */}
                </ListItem>
                {file && (
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mt: 2,
                            mx: 2,
                        }}
                    >
                        <img src={XslIcon} alt="icon" />
                        <Box sx={{ ml: 1 }}>
                            <Typography
                                sx={{
                                    color: '#475569',
                                    fontWeight: 400,
                                    fontSize: '14px',
                                    wordBreak: 'break-word',
                                }}
                            >
                                {file?.name}
                            </Typography>
                        </Box>
                    </Box>
                )}
            </List>
        </AppCenteredModalNew>
    );
};

export default AppUploadCSVModal;
