export const steps = [
    {
        title: 'Risk Overview',
        description: 'This gives you a summary of your risk assesment',
    },
    {
        title: 'Risk Assessment',
        description: 'Here you can run a risk assessment on your asset or any entity.',
    },
    {
        title: 'Risk register',
        description: 'This displays a tabular view of every entity for which a risk assessment has been completed.',
    },
    {
        title: 'Report',
        description: 'This displays history of all risk assessment report generated',
    },
];
