// import { useTheme } from '@mui/material/styles';
import { IconButton, Stack, Tooltip } from '@mui/material';

// core component
// import AppLinearProgress from 'components/new_components/AppLinearProgress';

//translation
// import { useTranslation } from 'react-i18next';
import { Progress } from 'antd';
import { MdAssignmentAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';

const pendingStandards = ['iso27001+iso27035', 'iso27001_2'];

const NewProgressBar = (props) => {
    const { percent, openTaskAssignModal, standard, isTaskAssignedToSelectSubRequirement } = props;
    // const theme = useTheme();
    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const organization = useSelector((state) => state?.generalReducers?.user_info?.organization);

    //translation
    // const { t } = useTranslation('compliance');
    return (
        <Stack direction="row" alignItems="center" gap={1}>
            <Progress
                type="circle"
                strokeLinecap="butt"
                width={33}
                percent={Math.round(percent)}
                strokeWidth={12}
                strokeColor={'#0E2C66'}
            />
            {/* <Progress
                type="circle"
                percent={Math.round(percent)}
                size="small"
                width={30}
                className="text-[7px] font-bold text-[#002C72]"
            /> */}
            {standard && !pendingStandards.includes(standard) && user_type !== 'auditor' ? (
                <Tooltip
                    title={
                        isTaskAssignedToSelectSubRequirement
                            ? 'Sorry, you cannot assign this to someone else because it has been assigned to you'
                            : ''
                    }
                >
                    <span>
                        {organization?.merchant_plan?.name !== 'free_version' &&
                            organization?.merchant_plan?.name !== '3_days_trial' && (
                                <IconButton
                                    onClick={openTaskAssignModal}
                                    disabled={isTaskAssignedToSelectSubRequirement}
                                >
                                    <MdAssignmentAdd size={26} />
                                </IconButton>
                            )}
                    </span>
                </Tooltip>
            ) : null}
            {/* <AppLinearProgress value={percent} sx={{ my: 1 }} /> */}
        </Stack>
    );
};

export default NewProgressBar;
