import React, { useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';

import { Drawer, Descriptions } from 'antd';
// import { formatAmount, CurrencySign } from 'utils';
// redux
import { connect } from 'react-redux';
import { GetMerchantsByID } from 'store/actions/adminActions';

const ViewMerchantDrawer = (props) => {
    const { visible, closeViewDrawer, record, merchant, GetMerchantsByID, all_plans } = props;
    // const [loading, setLoading] = useState(false);

    // memos
    const plan = useMemo(() => {
        return record?.tag === 'custom' ? record : all_plans?.find((plan) => plan?.id === record?.plan);
    }, [record]);

    // functions
    const getAllMerchants = async () => {
        // setLoading(true);
        const res = await GetMerchantsByID(record?.merchant);

        // setLoading(false);
        if (!res.success) {
            toast.error(res?.message);
        }
    };
    useEffect(() => {
        if (visible) {
            getAllMerchants();
        }
    }, [record?.merchant, visible]);
    return (
        <>
            <Drawer
                title={'Plan Request Details'}
                open={visible}
                width="900px"
                getContainer={false}
                onClose={closeViewDrawer}
                destroyOnClose={true}
            >
                <Descriptions title="Merchant Details">
                    <Descriptions.Item label="Company Name">{merchant?.name || 'Nil'}</Descriptions.Item>
                    <Descriptions.Item label="Owner Name">
                        {`${merchant?.owner_detail?.first_name} ${merchant?.owner_detail?.last_name}`}
                    </Descriptions.Item>
                    <Descriptions.Item label="Mailing Address" className="whitespace-nowrap">
                        {merchant?.owner_detail?.email}
                    </Descriptions.Item>
                    <Descriptions.Item label="Phone Number">{merchant?.phone_number}</Descriptions.Item>
                </Descriptions>
                <Descriptions title="Request Details">
                    <Descriptions.Item label="Plan Name">{plan?.name}</Descriptions.Item>
                    <Descriptions.Item label="Plan Type">
                        {`${merchant?.owner_detail?.first_name} ${merchant?.owner_detail?.last_name}`}
                    </Descriptions.Item>
                    <Descriptions.Item label="Mailing Address">{merchant?.owner_detail?.email}</Descriptions.Item>
                    <Descriptions.Item label="Phone Number">{merchant?.phone_number}</Descriptions.Item>
                </Descriptions>
            </Drawer>
        </>
    );
};
const mapStateToProps = (state) => {
    return {
        merchant: state?.adminReducers?.merchant_by_id,
        all_plans: state?.subscriptionReducers?.all_plans,
    };
};
export default connect(mapStateToProps, { GetMerchantsByID })(ViewMerchantDrawer);
