import { Box, Button, Menu, MenuItem, Typography } from '@mui/material';
import AppCenteredModal from 'components/new_components/AppCenteredModal';
import { AppForm, AppFormInput, AppSubmitButton } from 'components/new_components/forms';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { GetAllCompanyEmployees } from 'store/actions/generalActions';
import { AssignComplianceTask } from 'store/actions/taskActions';
import { assignTaskValidation } from '../validation';
import CloseIcon from '@mui/icons-material/Close';
import Bulb from 'assets/img/Light.svg';
import ArrowDownIcon from 'assets/img/ArrowDownIcon.svg';
import SmallCloseIcon from 'assets/img/x-close-small.svg';
import moment from 'moment';

const AssignTaskModal = (props) => {
    const {
        open,
        onClose,
        GetAllCompanyEmployees,
        AssignComplianceTask,
        all_employees,
        type,
        user_id,
        employee_id,
        taskInfo,
    } = props;

    // state
    const [loading, setLoading] = useState({});
    const [payload, setPayload] = useState({ employees: [], comments: '', date: '', time: '00:00' });
    const [localTime, setLocalTime] = useState(payload.time);
    const [localDate, setLocalDate] = useState(payload.date);
    const [categoryAnchorEl, setCategoryAnchorEl] = useState(null);

    // memos
    const employees = useMemo(() => {
        return all_employees
            ?.filter((emp) => emp?.id !== employee_id)
            ?.map((user) => ({
                name: user?.user_detail?.first_name
                    ? `${user?.user_detail?.first_name} ${user?.user_detail?.last_name}`
                    : user?.user_detail?.email,
                value: user?.id,
            }));
    }, [all_employees]);
    // functions
    const handleClose = () => {
        onClose();
        setTimeout(() => setPayload({}), 500);
    };
    const handleSubmit = async (values) => {
        const users = values.employees?.map((employee) => parseInt(employee?.value));
        setLoading({ ...loading, submit: true });
        const res = await AssignComplianceTask({
            assigned_to: users,
            comments: values?.comments,
            assigned_by: user_id,
            tag: 'compliance',
            completion_status: 'not_completed',
            mark_as_completed: false,
            ...taskInfo,
            due_date: `${moment(values?.date).format('YYYY-MM-DD')} ${values?.time}:00`,
        });
        setLoading({ ...loading, submit: false });
        if (res?.success) {
            toast.success('Task has been assigned to the employee(s)');
            handleClose();
        } else {
            toast.error(res?.message || "Something went wrong, couldn't assign task to employee(s)");
        }
    };
    //async action
    const getAllEmployees = async () => {
        setLoading({ ...loading, content: true });
        const res = await GetAllCompanyEmployees();
        setLoading({ ...loading, content: false });
        if (!res?.status) {
            toast.error(res?.status);
        }
    };
    // useEffect
    useEffect(() => {
        if (open) {
            setPayload({ employees: '', comments: '', date: '', time: '' });
        }
    }, [open]);

    useEffect(() => {
        getAllEmployees();
    }, []);
    return (
        <AppForm initialValues={payload} onSubmit={handleSubmit} validate={assignTaskValidation}>
            <AppCenteredModal
                open={open}
                handleClose={handleClose}
                title={
                    <Typography sx={{ color: '#202D66', fontWeight: 600, fontSize: '18px' }}>
                        Assign {type === 'doc' ? 'Policy Upload' : type === 'form' ? 'Question' : 'Control Objective'}
                    </Typography>
                }
                icon={<ArrowBackIosIcon sx={{ width: 20, height: 20, color: '#000000' }} />}
                noShadow
                noClose
                actions={
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'right',
                            alignItems: 'center',
                            backgroundColor: '#F8FAFC',
                            py: 2,
                            pr: 3,
                            mx: '-2rem',
                        }}
                    >
                        <Button
                            variant="outlined"
                            sx={{ mr: 2, border: '1px solid #E2E8F0', textTransform: 'inherit' }}
                            onClick={onClose}
                        >
                            Cancel
                        </Button>
                        <AppSubmitButton
                            text="Assign"
                            variant="contained"
                            color="primary"
                            loading={loading.submit}
                            loadingPosition="center"
                        />
                    </Box>
                }
                sx={{ borderRadius: 1 }}
                headerAction={
                    <Box
                        sx={{
                            border: '1px solid #E2E8F0',
                            borderRadius: 30,
                            width: 20,
                            height: 20,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            cursor: 'pointer',
                        }}
                        onClick={onClose}
                    >
                        <CloseIcon sx={{ width: 12, height: 12, color: '#334155' }} />
                    </Box>
                }
                width="550px"
            >
                <Box sx={{ py: 2 }}>
                    <Box>
                        <div className="flex flex-col gap-2">
                            <p className="font-medium text-sm text-[#64748B]">User</p>

                            <div>
                                <div
                                    aria-controls={categoryAnchorEl ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={categoryAnchorEl ? 'true' : undefined}
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setCategoryAnchorEl(event.currentTarget);
                                    }}
                                    className="!border !border-[#E2E8F0] flex items-center justify-between !w-full rounded-md !p-2 transition-colors h-10"
                                >
                                    {payload.employees?.length ? (
                                        <>
                                            {payload.employees?.map((item, index) => (
                                                <button
                                                    key={index}
                                                    className="flex items-center gap-2 !border !border-[#E2E8F0] bg-[#F1F5F9] !rounded !py-0 !px-2 hover:brightness-90 transition-all h-6"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setPayload((prev) => ({
                                                            ...prev,
                                                            employees: payload?.employees?.filter(
                                                                (employee) => employee?.value !== item?.value
                                                            ),
                                                        }));
                                                    }}
                                                >
                                                    <p className="font-normal capitalize text-[13px] text-[#64748B]">
                                                        {item.name}
                                                    </p>

                                                    <img
                                                        src={SmallCloseIcon}
                                                        alt="Delete Icon"
                                                        className="object-contain"
                                                    />
                                                </button>
                                            ))}
                                        </>
                                    ) : (
                                        <p
                                            className={`font-normal text-sm ${
                                                payload.employees
                                                    ? 'capitalize text-[hsl(215,20%,40%)]'
                                                    : 'text-[#94A3B8]'
                                            }`}
                                        >
                                            {'Select User'}
                                        </p>
                                    )}

                                    <img src={ArrowDownIcon} alt="Arrow Down Icon" className="object-contain" />
                                </div>

                                <Menu
                                    anchorEl={categoryAnchorEl}
                                    open={Boolean(categoryAnchorEl)}
                                    onClose={() => setCategoryAnchorEl(null)}
                                    sx={{
                                        '& .MuiPaper-root': {
                                            borderRadius: '2px',
                                            boxShadow: '0px 11.76px 22.06px 0px #00000014',
                                        },
                                        '& .MuiList-root': {
                                            padding: 0,
                                        },
                                    }}
                                    PaperProps={{
                                        style: {
                                            width: 490,
                                        },
                                    }}
                                >
                                    <div className="!p-2 flex flex-col min-w-[224px]">
                                        {employees?.map((employee) => (
                                            <MenuItem
                                                key={employee.value}
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    setPayload((prev) => ({
                                                        ...prev,
                                                        employees: [...prev.employees, employee]?.filter(Boolean),
                                                    }));
                                                    setCategoryAnchorEl(null);
                                                }}
                                                sx={{
                                                    color: '#64748B',
                                                    fontWeight: 500,
                                                    fontSize: '13px',
                                                    textTransform: 'capitalize',
                                                    padding: '6px 8px',
                                                }}
                                            >
                                                {employee?.name}
                                            </MenuItem>
                                        ))}
                                    </div>
                                </Menu>
                            </div>
                        </div>

                        <AppFormInput type="text" name="comments" multiline rows={3} placeholder="Comment" />
                        <div className="mt-3">
                            <p className="text-[#64748B] tetx-[13px] font-medium">Set deadline for this task</p>
                            <div className="flex items-center gap-3 mt-1">
                                <input
                                    type="date"
                                    inputMode="numeric"
                                    placeholder="Enter timeline"
                                    min={moment(new Date()).format('YYYY-MM-DD')}
                                    value={moment(new Date(localDate)).format('YYYY-MM-DD')}
                                    onChange={(event) => {
                                        setLocalDate(event.target.value);
                                    }}
                                    onBlur={(event) =>
                                        setPayload((prev) => ({
                                            ...prev,
                                            date: event.target.value,
                                        }))
                                    }
                                    className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-visible:ring-2 focus-visible:ring-[#395BA9] focus-visible:ring-opacity-75"
                                />{' '}
                                <input
                                    type="time"
                                    placeholder="Enter time"
                                    value={localTime}
                                    onChange={(event) => {
                                        setLocalTime(event.target.value);
                                        setPayload((prev) => ({
                                            ...prev,
                                            time: event.target.value,
                                        }));
                                    }}
                                    onBlur={(event) => {
                                        setPayload((prev) => ({
                                            ...prev,
                                            time: event.target.value,
                                        }));
                                    }}
                                    className="text-sm font-normal placeholder:text-[#94A3B8] text-[hsl(215,20%,40%)] !w-full focus-visible:outline-none bg-transparent !rounded-sm !p-2 h-9 !border !border-[#E2E8F0] placeholder:opacity-95 focus-within:ring-2 focus-within:ring-[#395BA9] focus-within:ring-opacity-75"
                                />
                            </div>
                        </div>
                    </Box>
                </Box>
                <Box
                    sx={{
                        backgroundColor: '#F8FAFC',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'start',
                        gap: 2,
                        my: 1.5,
                        py: 1,
                        px: 2,
                        borderRadius: 1,
                        border: '1px solid #F0F0FA',
                    }}
                >
                    <img src={Bulb} alt="Bulb" />
                    <Typography sx={{ color: '#64748B', fontWeight: 400, fontSize: '13px' }}>
                        You can view tasks you assigned to users on the activity page of the smartcomply application.
                    </Typography>
                </Box>
            </AppCenteredModal>
        </AppForm>
    );
};
const mapStateToProps = (state) => ({
    all_employees: state?.generalReducers?.all_employees,
    user_id: state?.authReducers?.user_id,
    employee_id: state?.authReducers?.user_info?.employee?.id,
    user_type: state?.authReducers?.user_type,
});
export default connect(mapStateToProps, { GetAllCompanyEmployees, AssignComplianceTask })(AssignTaskModal);
