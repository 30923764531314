import { Box, Typography } from '@mui/material';
import React from 'react';

const AppTabsBarNew = (props) => {
    const { onChange, tabs, currentTab, others, sx, ref1, ref2, ref3 } = props;
    return (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                backgroundColor: '#FFFFFF',
                px: 3,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 3.5,
                    ...sx,
                }}
            >
                {tabs.map((tab, index) => (
                    <Box
                        key={index}
                        onClick={() => onChange(tab.index)}
                        sx={{ cursor: 'pointer' }}
                        ref={index === 0 ? ref1 : index === 1 ? ref2 : index === 2 ? ref3 : null}
                    >
                        <Typography
                            sx={{
                                color: currentTab === tab?.index ? '#202D66' : '#64748B',
                                fontSize: '14px',
                                fontWeight: 500,
                                textAlign: 'right',
                                pt: currentTab === tab?.index ? 1.5 : 0,
                                pb: currentTab === tab?.index ? 1 : 0,
                            }}
                        >
                            {tab.label}
                        </Typography>
                        {currentTab === tab?.index && (
                            <Box
                                sx={{
                                    backgroundColor: '#202D66',
                                    width: '100%',
                                    pt: 0.5,
                                }}
                            ></Box>
                        )}
                    </Box>
                ))}
            </Box>
            <Box>{others}</Box>
        </Box>
    );
};

export default AppTabsBarNew;
