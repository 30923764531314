import React, { useEffect, useState } from 'react';
import { ArrowBackIosNew } from '@mui/icons-material';
import { useTheme } from '@mui/styles';
import { Box, Typography } from '@mui/material';
import ReadDocumentation from './ReadDocumentation';
import AppViewModal2 from 'components/new_components/AppViewModal2';
import { AppSubmitButton } from 'components/new_components/forms';
import { AppForm } from 'components/new_components/forms';
import { AppFormInput } from 'components/new_components/forms';
import AppLoadingButton from 'components/new_components/AppLoadingButton';
import CachedIcon from '@mui/icons-material/Cached';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { copierHelper } from 'utils';
import { RequestFraudApi } from 'store/actions/FraudGauardianActions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import ProgressBolt from '../../../../assets/img/icons/ProgressBolt.svg';
import { FraudSettings } from 'store/actions/FraudGauardianActions';
import { CreateFraudSettings } from 'store/actions/FraudGauardianActions';
import { settingsValidation } from '../utils/validation';
import { GenerateTokenRequest } from 'store/actions/FraudGauardianActions';
import { UpdateApprovedUser } from 'store/actions/FraudGauardianActions';

const AboutFraudGuardian = (props) => {
    const theme = useTheme();
    const { open, handleClose, all_approved_user, payload, setPayload } = props;
    const [loadingState, setloadingState] = useState(false);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState('');
    const dispatch = useDispatch();
    const [success, setSuccess] = useState(false);
    const fraud_settings = useSelector((state) => state?.fraudGuardianReducers?.fraud_settings);
    const merchant_info = useSelector((state) => state?.merchantReducers?.merchant_info);

    const find_merchant_fraud_Settings = fraud_settings?.find((setting) => setting?.merchant === merchant_info?.id);
    useEffect(() => {
        if (open && find_merchant_fraud_Settings) {
            setPayload({
                threshold: find_merchant_fraud_Settings?.threshold,
                limit: find_merchant_fraud_Settings?.limit,
            });
        }
    }, [open, find_merchant_fraud_Settings]);

    const handleSubmit = async (data) => {
        setloadingState(true);
        const res = await dispatch(CreateFraudSettings(data));
        setloadingState(false);
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    const onClose = () => {
        handleClose();
    };
    const handleRequest = async () => {
        setLoading(true);
        const res = await dispatch(RequestFraudApi());
        setLoading(false);
        if (res?.success) {
            toast.success(res?.message);
        } else {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        const getFraudSettings = async () => {
            const res = await dispatch(FraudSettings());
            if (!res?.success) {
                toast.error(res?.message);
            }
        };
        getFraudSettings();
    }, [open]);

    const handleCreated = async () => {
        const body = {
            created_status: true,
        };
        const res = await dispatch(UpdateApprovedUser(all_approved_user?.id, body));
        if (res?.success) {
            toast.success('Token Successfully generated!');
        } else {
            res.error(res.message);
        }
    };

    const generateTokenRequest = async () => {
        setLoading(true);
        const res = await dispatch(GenerateTokenRequest());
        setLoading(false);
        if (res?.success) {
            setSuccess(res?.success);
            handleCreated();
            setToken(res?.data);
        } else {
            toast.error(res?.message);
        }
    };
    return (
        <AppViewModal2
            width={500}
            open={open}
            handleClose={onClose}
            noClose
            lightBorder
            title={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <ArrowBackIosNew
                        sx={{
                            width: 20,
                            height: 20,
                            color: theme.palette.gray[30],
                            mr: 1,
                            borderRadius: '50%',
                            cursor: 'pointer',
                        }}
                        onClick={onClose}
                    />
                    <Typography
                        sx={{
                            color: '#202D66',
                            fontWeight: 700,
                            fontSize: '16px',
                        }}
                    >
                        Settings
                    </Typography>
                </Box>
            }
            actions={<ReadDocumentation />}
            anchor="right"
        >
            <Box>
                {all_approved_user?.merchant && all_approved_user?.enabled ? (
                    <>
                        <Box sx={{ border: '1px solid #E2E8F0', px: 3, py: 1.5, borderRadius: 1 }}>
                            <AppForm initialValues={payload} onSubmit={handleSubmit} validate={settingsValidation}>
                                <Typography
                                    sx={{ color: theme.palette.primary[40], fontWeight: 600, fontSize: '14px', mb: 1 }}
                                >
                                    Monitoring setting
                                </Typography>
                                <Box sx={{ mb: 2 }}>
                                    <AppFormInput
                                        type="number"
                                        label={'Threshold'}
                                        placeholder="1-10"
                                        name="threshold"
                                        min={1}
                                    />

                                    <AppFormInput
                                        type="number"
                                        label={'Limit'}
                                        placeholder="Limit...."
                                        name="limit"
                                        min={1000}
                                    />
                                </Box>
                                <AppSubmitButton
                                    text="Save"
                                    variant="contained"
                                    color="primary"
                                    loading={loadingState}
                                    loadingPosition="center"
                                    sx={{ px: 2.5, py: 1, borderRadius: 0.5 }}
                                />
                            </AppForm>
                        </Box>

                        <Box sx={{ border: '1px solid #E2E8F0', px: 3, py: 2, borderRadius: 1, mt: 3 }}>
                            <Typography
                                sx={{ color: theme.palette.primary[40], fontWeight: 600, fontSize: '14px', mb: 1 }}
                            >
                                API Token
                            </Typography>
                            {success ? (
                                <Box>
                                    <Box
                                        sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                                    >
                                        <Typography
                                            sx={{
                                                border: '1px solid #E2E8F0',
                                                p: 1,
                                                borderRadius: 1,
                                                width: '90%',
                                                color: '#64748B',
                                                fontSize: '13px',
                                                fontWeight: 400,
                                            }}
                                        >
                                            {token.substring(0, 40)}...
                                        </Typography>
                                        <Box
                                            sx={{
                                                border: '1px solid #E2E8F0',
                                                px: 1,
                                                py: 0.5,
                                                borderRadius: 1,
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => {
                                                copierHelper(token);
                                                setSuccess(false);
                                            }}
                                        >
                                            <ContentCopyIcon sx={{ width: 20, height: 20, color: '#64748B' }} />
                                        </Box>
                                    </Box>
                                    {success && (
                                        <Typography
                                            sx={{
                                                color: theme.palette.neutral[500],
                                                fontWeight: 400,
                                                fontSize: '12px',
                                                mt: 2,
                                            }}
                                        >
                                            Once copied, you won’t see the token again unless you regenerate
                                        </Typography>
                                    )}
                                </Box>
                            ) : (
                                <AppLoadingButton
                                    text={
                                        all_approved_user?.created_status
                                            ? 'Regenerate token'
                                            : 'Click to generate token'
                                    }
                                    loading={loading}
                                    variant="outlined"
                                    sx={{
                                        textTransform: 'inherit',
                                        color: theme.palette.neutral[500],
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        mt: 2,
                                    }}
                                    startIcon={<CachedIcon />}
                                    onClick={generateTokenRequest}
                                />
                            )}
                        </Box>
                    </>
                ) : (
                    <Box sx={{ border: '1px solid #E2E8F0', px: 3, py: 1.5, borderRadius: 1 }}>
                        <Typography sx={{ color: theme.palette.primary[40], fontWeight: 600, fontSize: '14px', mb: 3 }}>
                            API Token
                        </Typography>
                        <Typography
                            sx={{ color: theme.palette.neutral[500], fontWeight: 400, fontSize: '13px', mb: 3 }}
                        >
                            For those seeking a deeper understanding of the capabilities and possibilities of our API,
                            the API Reference is your treasure trove of information. Discover the full potential of
                            Fraud Guardian API and unlock new horizons for your KYC needs.
                        </Typography>
                        {!all_approved_user?.merchant && (
                            <AppLoadingButton
                                text="Request for API Token"
                                variant="contained"
                                loadingPosition="center"
                                sx={{
                                    // width: '200px',
                                    // height: '45px',
                                    mb: 2,
                                    backgroundColor: theme.palette.primary[40],
                                    textTransform: 'inherit',
                                    color: '#FFFFFF',
                                    px: 3,
                                    py: 1,
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    '&:hover': {
                                        backgroundColor: theme.palette.primary[40],
                                        color: '#FFFFFF',
                                    },
                                }}
                                loading={loading}
                                type="submit"
                                onClick={handleRequest}
                            />
                        )}
                        {all_approved_user?.merchant && !all_approved_user?.enabled && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                                <img src={ProgressBolt} alt="ProgressBolt" />
                                <Typography
                                    sx={{
                                        fontSize: '14px',
                                        fontWeight: 500,
                                        color: '#6E8EDF',
                                        fontStyle: 'italic',
                                        ml: 1,
                                    }}
                                >
                                    API request in progress...
                                    <Typography
                                        className="text-[#94A3B8]"
                                        sx={{ fontSize: '12px', fontStyle: 'normal' }}
                                    >
                                        Our team will reach out to you soon
                                    </Typography>
                                </Typography>
                            </Box>
                        )}
                    </Box>
                )}
            </Box>
        </AppViewModal2>
    );
};

export default AboutFraudGuardian;
