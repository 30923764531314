import React, { useEffect, useState, useRef, useContext } from 'react';
import { toast } from 'react-toastify';

//core components
import { Add, MoreHoriz, ViewCarousel } from '@mui/icons-material';
import { Button, ButtonGroup, Menu, MenuItem } from '@mui/material';
import AppTable from 'components/new_components/app-table2';
import AppTag from 'components/new_components/AppTags';
import AppButton from 'components/new_components/AppButton';
import InfrastructureTableActions from './InfrastructureTableActions';
import InfrastructuresModal from './components/InfrastructuresModal';
import InfrastructureViewModal from './InfrastructureViewModal';
import InfrastructureDeleteModal from './InfrastructureDeleteModal';
import PageHeader from 'components/new_components/PageHeader';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import useSearch from 'hooks/useSearch';
import NewGroupModal from './components/NewGroupModal';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

//redux
import { connect, useSelector } from 'react-redux';
import {
    GetAllInfrastructures,
    UploadMerchantInfrastructureFile,
    GetInfrastructureInfo,
} from 'store/actions/merchantActions';

//Utils
import { truncateText } from 'utils';

//userguide
import AppUserGuide from 'components/new_components/AppUserGuide';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import { steps } from './steps';
import { userGuidePentest } from 'utils';

//translations
import { useTranslation } from 'react-i18next';
import UploadCsvModal from './components/UploadCsvModal';
import AssetHeading from './components/AssetHeading';
import { mainAssetCategories, initialGroupValues, initialInfrastructureValues } from './utils/constants';
import { useTheme } from '@mui/styles';
import DeleteGroupModal from './components/DeleteGroupModal';

const NO_PER_PAGE = 8;

const Infrastructures = (props) => {
    //props
    const {
        GetAllInfrastructures,
        GetInfrastructureInfo,
        all_merchant_infrastructures,
        // merchant_infrastructure_info,
        // UploadMerchantInfrastructureFile,
        userguide_info,
    } = props;
    const theme = useTheme();
    //states
    const [loading, setloading] = useState({ content: false, delete: false });
    const [modalOpen, setModalOpen] = useState(false);
    const [modalMode, setModalMode] = useState();
    const [modalGroupMode, setModalGroupMode] = useState();
    const [modalPayloadId, setModalPayloadId] = useState(0);
    const [modalGroupId, setModalGroupId] = useState(0);
    const [modalPayload, setModalPayload] = useState(initialInfrastructureValues);
    const [groupPayload, setGroupPayload] = useState(initialGroupValues);
    const [viewModalOpen, setViewModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [page, setPage] = useState(1);
    const [pageData, setPageData] = useState([]);
    const [allData, setAllData] = useState([]);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [assetCategory, setAssetCategory] = useState('information_asset');
    const [anchorEl, setAnchorEl] = useState(null);
    const [modalUploadOpen, setModalUploadOpen] = useState(false);
    const [modalDeleteGroupOpen, setModalDeleteGroupOpen] = useState(false);
    const [groupOpen, setGroupOpen] = useState(false);
    const [details, setDetails] = useState({});
    const [owner, setOwner] = useState({
        name: '',
        value: '',
    });
    const [type, setType] = useState({
        name: 'All',
        value: 'all',
    });
    const [custodian, setCustodian] = useState({
        name: '',
        value: '',
    });
    const [openArticle, setOpenArticle] = useState(false);

    const openMenu = (e) => setAnchorEl(e.target);
    const closeMenu = () => setAnchorEl(null);

    const user_type = useSelector((state) => state?.authReducers?.user_type);
    const role = useSelector((state) => state?.generalReducers?.user_info?.role);

    //userguide
    const stepsTargetRef = useRef([]);
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    const { data, handleSearch } = useSearch(all_merchant_infrastructures, [
        'asset_number',
        'department',
        'location',
        'sensitivity',
        'date_updated',
        'ip_type',
    ]);

    //translation
    const { t } = useTranslation('inventory');

    // const handleSearch = (e) => {
    //     const value = e.target.value;
    //     console.log(value);
    //     const filteredMerchantInfrastructures = all_merchant_infrastructures?.filter((merchant) => {
    //         return (
    //             String(merchant?.asset_number).includes(value) ||
    //             truncateText(merchant?.department).includes(value) ||
    //             truncateText(merchant?.location).includes(value) ||
    //             merchant?.sensitivity.toLowerCase().includes(value) ||
    //             formatDateObjectHandler(merchant.date_updated, 'DD/MM/YYYY').includes(value) ||
    //             merchant?.ip_type.includes(value)
    //         );
    //     });
    //     setFilteredMerchantInfrastructures(filteredMerchantInfrastructures);
    // };

    const columns1 = [
        {
            title: 'Information Asset',
            key: 'name',
            render: (row) => <>{row?.name}</>,
        },
        {
            title: 'Type',
            key: 'asset_id',
            render: (row) => <>{row?.asset_type}</>,
        },
        {
            title: 'Risk Category',
            key: 'risk_category_name',
            render: (row) => row?.risk_category_name,
        },
        {
            title: 'Asset Owner',
            key: 'location',
            render: (row) => <>{truncateText(row?.owner_email)}</>,
        },
        {
            title: t('infrastructureTable.column3'),
            key: 'location',
            render: (row) => <>{truncateText(row?.location)}</>,
        },
        // {
        //     title: t('infrastructureTable.column6'),
        //     key: 'status',
        //     render: (row) => <>{row?.status}</>,
        // },
        {
            title: <MoreHoriz />,
            key: 'status',
            render: (row) => (
                <>
                    <InfrastructureTableActions
                        row={row}
                        openViewModal={openViewModal}
                        openDeleteModal={openDeleteModal}
                        openEditModal={openModal}
                    />
                </>
            ),
        },
    ];

    const columns2 = [
        {
            title: 'Application',
            key: 'name',
            render: (row) => <>{row?.name}</>,
        },
        {
            title: 'Asset Type',
            key: 'asset_type',
            render: (row) => <>{truncateText(row?.asset_type)}</>,
        },
        {
            title: 'IP Address',
            key: 'ip_address',
            render: (row) => <>{row?.ip_address}</>,
        },
        {
            title: 'Asset Owner',
            key: 'owner_email',
            render: (row) => <>{row?.owner_email}</>,
        },
        {
            title: 'Asset Category',
            key: 'asset_category',
            render: (row) => <>{row.asset_category_name}</>,
        },
        {
            title: 'Risk Category',
            key: 'risk_category_name',
            render: (row) => row?.risk_category_name,
        },
        {
            title: <MoreHoriz />,
            key: 'status',
            render: (row) => (
                <>
                    <InfrastructureTableActions
                        row={row}
                        openViewModal={openViewModal}
                        openDeleteModal={openDeleteModal}
                        openEditModal={openModal}
                    />
                </>
            ),
        },
    ];

    const columns3 = [
        {
            title: 'Vendor Name',
            key: 'vendor',
            render: (row) => <>{row?.vendor_details?.name}</>,
        },
        {
            title: 'products',
            key: 'products',
            render: (row) => <>{row?.vendor_details?.products || 'N/A'}</>,
        },
        {
            title: 'Asset Category',
            key: 'location',
            render: (row) => <>{row?.asset_category_name}</>,
        },
        {
            title: 'Risk Category',
            key: 'location',
            render: (row) => <>{row?.risk_category_name}</>,
        },
        // {
        //     title: t('infrastructureTable.column6'),
        //     key: 'status',
        //     render: (row) => <>{row?.status}</>,
        // },
        {
            title: <MoreHoriz />,
            key: 'status',
            render: (row) => (
                <>
                    <InfrastructureTableActions
                        row={row}
                        openViewModal={openViewModal}
                        openDeleteModal={openDeleteModal}
                        openEditModal={openModal}
                    />
                </>
            ),
        },
    ];
    console.log({ data });
    const columns4 = [
        {
            title: 'User Name',
            key: 'name',
            render: (row) => (
                <>
                    {`${row?.person_details?.first_name} ${row?.person_details?.last_name}` ||
                        `${row?.person_detail?.email}`}
                </>
            ),
        },
        {
            title: 'Role',
            key: 'position',
            render: (row) => <>{row?.person_details?.position || 'N/A'}</>,
        },
        {
            title: 'Asset Category',
            key: 'location',
            render: (row) => <>{row?.asset_category_name}</>,
        },
        {
            title: 'Risk Category',
            key: 'location',
            render: (row) => <>{row?.risk_category_name}</>,
        },
        {
            title: 'Department',
            key: 'department',
            render: (row) => <>{row?.person_details?.department || 'N/A'}</>,
        },
        {
            title: <MoreHoriz />,
            key: 'status',
            render: (row) => (
                <>
                    <InfrastructureTableActions
                        row={row}
                        openViewModal={openViewModal}
                        openDeleteModal={openDeleteModal}
                        openEditModal={openModal}
                    />
                </>
            ),
        },
    ];
    const columns5 = [
        {
            title: 'Computer',
            key: 'name',
            render: (row) => <>{row?.name}</>,
        },
        {
            title: 'IP Address',
            key: 'ip_address',
            render: (row) => <>{row?.ip_address || 'N/A'}</>,
        },
        {
            title: 'Risk Category',
            key: 'risk_category_name',
            render: (row) => row?.risk_category_name,
        },
        {
            title: 'Operating Sysytem',
            key: 'os',
            render: (row) => <>{row?.os}</>,
        },
        {
            title: 'Asset Owner',
            key: 'os',
            render: (row) => <>{row?.owner_email}</>,
        },
        {
            title: <MoreHoriz />,
            key: 'status',
            render: (row) => (
                <>
                    <InfrastructureTableActions
                        row={row}
                        openViewModal={openViewModal}
                        openDeleteModal={openDeleteModal}
                        openEditModal={openModal}
                    />
                </>
            ),
        },
    ];
    const columns6 = [
        {
            title: 'Server Name',
            key: 'name',
            render: (row) => <>{row?.name}</>,
        },
        {
            title: 'IP Address',
            key: 'ip_address',
            render: (row) => <>{row?.ip_address || 'N/A'}</>,
        },
        {
            title: 'Operating Sysytem',
            key: 'os',
            render: (row) => <>{row?.os}</>,
        },
        {
            title: 'Risk Category',
            key: 'risk_category_name',
            render: (row) => row?.risk_category_name,
        },
        {
            title: 'Asset Owner',
            key: 'os',
            render: (row) => <>{row?.owner_email}</>,
        },
        {
            title: 'Location',
            key: 'location',
            render: (row) => <>{row?.location}</>,
        },
        {
            title: <MoreHoriz />,
            key: 'status',
            render: (row) => (
                <>
                    <InfrastructureTableActions
                        row={row}
                        openViewModal={openViewModal}
                        openDeleteModal={openDeleteModal}
                        openEditModal={openModal}
                    />
                </>
            ),
        },
    ];
    const columns7 = [
        {
            title: t('infrastructureTable.column1'),
            key: 'name',
            render: (row) => <>{row?.name}</>,
        },
        {
            title: t('infrastructureTable.column2'),
            key: 'asset_id',
            render: (row) => <>{row?.asset_id || 'N/A'}</>,
        },
        {
            title: t('infrastructureTable.column3'),
            key: 'location',
            render: (row) => <>{truncateText(row?.location)}</>,
        },
        {
            title: t('infrastructureTable.column4'),
            key: 'sensitivity',
            render: (row) => (
                <>
                    {row?.sensitivity?.toLowerCase() === 'low' ? (
                        <AppTag
                            text="Low"
                            icon={
                                <FiberManualRecordIcon
                                    sx={{ width: 12, height: 12, color: theme.palette.success[500], mr: 0.5 }}
                                />
                            }
                            type="default"
                            noRounded
                            noItalic
                            sx={{
                                padding: '4px 8px',
                            }}
                        />
                    ) : row?.sensitivity?.toLowerCase() === 'medium' ? (
                        <AppTag
                            text="Medium"
                            icon={
                                <FiberManualRecordIcon
                                    sx={{ width: 12, height: 12, color: theme.palette.warning[900], mr: 0.5 }}
                                />
                            }
                            noRounded
                            noItalic
                            type="default"
                            sx={{
                                padding: '4px 8px',
                            }}
                        />
                    ) : (
                        <AppTag
                            text="High"
                            icon={
                                <FiberManualRecordIcon
                                    sx={{ width: 12, height: 12, color: theme.palette.error[500], mr: 0.5 }}
                                />
                            }
                            noRounded
                            noItalic
                            type="default"
                            sx={{
                                padding: '4px 8px',
                            }}
                        />
                    )}
                </>
            ),
        },
        // {
        //     title: t('infrastructureTable.column6'),
        //     key: 'status',
        //     render: (row) => <>{row?.status}</>,
        // },
        {
            title: <MoreHoriz />,
            key: 'status',
            render: (row) => (
                <>
                    <InfrastructureTableActions
                        row={row}
                        openViewModal={openViewModal}
                        openDeleteModal={openDeleteModal}
                        openEditModal={openModal}
                    />
                </>
            ),
        },
    ];
    const columns =
        assetCategory === 'information_asset'
            ? columns1
            : assetCategory === 'application_inventory'
            ? columns2
            : assetCategory === 'service_provider'
            ? columns3
            : assetCategory === 'people'
            ? columns4
            : assetCategory === 'laptops_desktops'
            ? columns5
            : assetCategory === 'servers_appliances'
            ? columns6
            : columns7;

    const openModalUpload = () => {
        setModalUploadOpen(true);
        closeMenu();
    };
    const closeModalUpload = () => {
        setModalUploadOpen(false);
    };

    const openGroupUpload = (mode, payload, id) => {
        setGroupPayload({
            name: payload?.name,
        });
        setOwner({
            name: payload?.owner_email,
            value: payload?.owner_email,
        });
        setModalGroupId(id);
        setModalGroupMode(mode);
        setGroupOpen(true);
    };
    const closeGroupUpload = () => {
        setModalGroupMode();
        setModalGroupId(0);
        setGroupPayload({});
        setGroupOpen(false);
    };
    const openModal = (mode, payload, id) => {
        setModalOpen(true);
        setModalPayload(payload);
        setModalMode(mode);
        setDetails(payload);
        setModalPayloadId(id);
    };
    const closeModal = () => {
        setModalOpen(false);
        setModalPayload({});
        setModalPayloadId(0);
        setModalMode('');
    };
    const openViewModal = (payload) => {
        setViewModalOpen(true);
        getInfrastructureInfo(payload);
        setDetails(payload);
    };
    const closeViewModal = () => {
        setViewModalOpen(false);
    };
    const openDeleteModal = (id) => {
        setDeleteModalOpen(true);
        setModalPayloadId(id);
    };
    const closeDeleteModal = () => {
        setDeleteModalOpen(false);
        setModalPayloadId(0);
    };
    const openDeleteGroupModal = (id) => {
        setModalDeleteGroupOpen(true);
        setModalGroupId(id);
    };

    const closeDeleteGroupModal = () => {
        setModalDeleteGroupOpen(false);
        setModalGroupId(0);
    };

    const handlePageChange = (page) => {
        setPage(page);
    };
    const closeGroup = () => {
        setGroupPayload({});
        setGroupOpen(false);
    };

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ infrastructures: true });
        setToggleUserGuide({ ...toggleUserGuide, infrastructures: false });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        // updateOneUserGuide({ employee: true });
        setToggleUserGuide({ ...toggleUserGuide, employee: false });
        setOpenArticle(false);
    };

    //Get Infrastructure info
    const getInfrastructureInfo = async (user) => {
        const res = await GetInfrastructureInfo(
            user?.ip_address
            // match?.params?.id
        );
        if (!res?.success) {
            toast.error(res?.message);
        }
    };

    useEffect(() => {
        const getAllInfrastructures = async () => {
            setloading({ ...loading, content: true });
            const res = await GetAllInfrastructures();
            setloading({ ...loading, content: false });
            if (!res.success) {
                toast.error(res?.message);
            }
        };
        getAllInfrastructures();
    }, []);
    useEffect(() => {
        const start = (page - 1) * NO_PER_PAGE;
        const stop = start + NO_PER_PAGE;
        let filteredData = data || [];

        if (assetCategory !== 'all') {
            filteredData = filteredData.filter((item) => item?.asset_category === assetCategory);
        }
        if (type?.value !== 'all') {
            filteredData = filteredData.filter((item) => item?.asset_type?.toLowerCase() === type?.value.toLowerCase());
        }

        const new_data = filteredData?.length > 0 ? data?.sort((a, b) => b?.id - a?.id)?.slice(start, stop) : [];
        setPageData(filteredData);
        setAllData(new_data);
        return data;
    }, [page, data, type, assetCategory]);

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.infrastructures ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.infrastructures]);
    return (
        <div className="mx-[1.5rem] bg-white p-3">
            <PageHeader browserTitle="Infrastructures | Smartcomply" />{' '}
            {/* <div className="flex items-center gap-2">
                <button
                    className={`rounded-3xl font-medium text-sm !border !border-[#E2E8F0] transition-all hover:brightness-90 w-fit h-8 px-2 grid place-items-center ${
                        asset === 'all' ? 'bg-[#395BA9] text-[#fff]' : 'text-[#475569] bg-[#fff]'
                    }`}
                    onClick={() => setAsset('all')}
                >
                    All
                </button>
                <button
                    className={`rounded-3xl font-medium text-sm !border !border-[#E2E8F0] transition-all hover:brightness-90 w-fit h-8 px-2  grid place-items-center ${
                        asset === 'group' ? 'bg-[#395BA9] text-[#fff]' : 'text-[#475569] bg-[#fff]'
                    }`}
                    onClick={() => setAsset('grouped')}
                >
                    Grouped
                </button>

                <button
                    className={`rounded-3xl font-medium text-sm !border !border-[#E2E8F0] transition-all hover:brightness-90 w-fit h-8 px-2 grid place-items-center ${
                        asset === 'un-grouped' ? 'bg-[#395BA9] text-[#fff]' : 'text-[#475569] bg-[#fff]'
                    }`}
                    onClick={() => setAsset('un-grouped')}
                >
                    Ungrouped
                </button>
            </div> */}
            <div className="flex items-center gap-2 w-full overflow-auto">
                {/* <button
                    className={`rounded-3xl font-medium text-sm !border !border-[#E2E8F0] transition-all hover:brightness-90 w-fit h-8 px-2 grid place-items-center ${
                        assetCategory === 'all' ? 'bg-[#395BA9] text-[#fff]' : 'text-[#475569] bg-[#fff]'
                    }`}
                    onClick={() => setAssetCategory('all')}
                >
                    All
                </button> */}
                {mainAssetCategories?.map((category, index) => (
                    <button
                        key={index}
                        className={`rounded-3xl font-medium text-sm !border !border-[#E2E8F0] transition-all hover:brightness-90 w-fit h-12 px-2  grid place-items-center ${
                            assetCategory === category ? 'bg-[#395BA9] text-[#fff]' : 'text-[#475569] bg-[#fff]'
                        }`}
                        onClick={() => setAssetCategory(category)}
                    >
                        {category.replace(/_/g, ' ')}
                    </button>
                ))}
            </div>
            <AppTable
                columns={columns}
                data={pageData}
                sorter={(a, b) => b?.id - a?.id}
                loading={loading?.content}
                dataLength={allData?.length}
                noPerPage={NO_PER_PAGE}
                page={page}
                onPageChange={handlePageChange}
                actions={[
                    <ButtonGroup disabled={user_type === 'auditor' || role === 'auditor'}>
                        <AppButton
                            icon={<Add />}
                            name={t('addInfrastructure')}
                            color="primary"
                            variant="contained"
                            disableElevation
                            onClick={() => openModal('add')}
                            key={1}
                            ref={(el) => (stepsTargetRef.current[0] = el)}
                        />
                        <Button
                            onClick={openMenu}
                            color="primary"
                            variant="contained"
                            sx={{ borderLeft: '1px solid #5474C4', py: 1.2 }}
                        >
                            <KeyboardArrowDownIcon />
                        </Button>
                    </ButtonGroup>,
                ]}
                title={<AssetHeading onSearch={handleSearch} type={type} setType={setType} />}
                // search={true}
                // onSearch={handleSearch}
            />
            <UploadCsvModal open={modalUploadOpen} handleClose={closeModalUpload} />
            <InfrastructuresModal
                open={modalOpen}
                handleClose={closeModal}
                isAddModal={modalMode === 'add'}
                id={modalPayloadId}
                payload={modalPayload}
                openGroupUpload={openGroupUpload}
                details={details}
                setOwner={setOwner}
                setGroupPayload={setGroupPayload}
            />
            <InfrastructureViewModal
                open={viewModalOpen}
                handleClose={closeViewModal}
                title={t('infrastructureViewModal.title')}
                width={500}
                icon={<ViewCarousel />}
                getInfrastructureInfo={details}
            />
            <InfrastructureDeleteModal open={deleteModalOpen} handleClose={closeDeleteModal} id={modalPayloadId} />
            <NewGroupModal
                isAddModal={modalGroupMode === 'add'}
                payload={groupPayload}
                id={modalGroupId}
                open={groupOpen}
                handleClose={closeGroupUpload}
                owner={owner}
                setOwner={setOwner}
                custodian={custodian}
                setCustodian={setCustodian}
                openDeleteGroupModal={openDeleteGroupModal}
            />
            <AppUserGuide
                open={openUserGuide}
                onClose={CloseUserGuide}
                steps={steps}
                targets={stepsTargetRef.current}
            />{' '}
            <DeleteGroupModal
                open={modalDeleteGroupOpen}
                handleClose={closeDeleteGroupModal}
                id={modalGroupId}
                closeGroup={closeGroup}
            />
            <Menu
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={closeMenu}
                sx={{ mt: 2 }}
                PaperProps={{
                    style: {
                        width: 220,
                    },
                }}
            >
                <MenuItem
                    onClick={openModalUpload}
                    sx={{ color: '#64748B', fontSize: '14px', fontWeight: 500, py: 0.2 }}
                >
                    {t('addAssetMenu.uploadCSV')}
                </MenuItem>
            </Menu>
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Employee"
                userguideDetails={userGuidePentest}
                icon={true}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        all_merchant_infrastructures: state?.merchantReducers?.all_merchant_infrastructures,
        merchant_infrastructure_info: state?.merchantReducers?.merchant_infrastructure_info,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.infrastructures,
    };
};

export default connect(mapStateToProps, {
    GetAllInfrastructures,
    UploadMerchantInfrastructureFile,
    GetInfrastructureInfo,
})(Infrastructures);
