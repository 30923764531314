import { Box, Divider, IconButton, Menu, MenuItem, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

//redux
import { connect } from 'react-redux';
import { UpdateAssignAutomatedScan, UserAssignedAutomatedScan } from 'store/actions/automatedScansActions';

//core components
import { MoreVert } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import CircularLoader from 'components/Loaders/CircularLoader';
import AppViewModal from 'components/new_components/AppViewModal';
import moment from 'moment';
import { toast } from 'react-toastify';

const AllTasksModal = (props) => {
    const { open, onClose, UserAssignedAutomatedScan, user_assigned_tasks, user_id, UpdateAssignAutomatedScan } = props;
    const [loading, setLoading] = useState(false);
    const [anchor, setAnchor] = useState(null);

    // function
    const openMenu = (e) => setAnchor(e.target);
    const closeMenu = () => setAnchor(null);

    const filteredUserAssignedTasks = user_assigned_tasks?.filter((item) => item.tag === 'automated_scan');

    // async functions
    const assignedAutomatedScan = async () => {
        setLoading(true);
        const res = await UserAssignedAutomatedScan(user_id);
        setLoading(false);
        if (!res.message) {
            toast.error(res.message);
        }
    };

    const updateAssignedAutomatedScan = async (item) => {
        const body = {
            mark_as_completed: true,
            user_completion_date: new Date(),
            completion_status: 'waiting_approval',
            assigned_to: [user_id],
            assigned_by: item?.assigned_by,
            category: item?.category ? item?.category : '',
            subcategory: item?.subcategory ? item?.subcategory : '',
            scans: item?.scans ? item?.scans : '',
        };
        const res = await UpdateAssignAutomatedScan(body, item?.id, user_id);
        closeMenu();
        if (res.success) {
            toast.success(res.message);
        } else {
            toast.error(res.message);
        }
    };

    // useEffect
    useEffect(() => {
        if (open) {
            assignedAutomatedScan();
        }
    }, [open]);

    return (
        <AppViewModal open={open} handleClose={onClose} width={479}>
            <Box>
                <Box sx={{ display: 'flex', mx: '8px', py: '16px' }}>
                    <img
                        src="/img/automated-scan/arrow-left-icon.svg"
                        alt="arrow-left-icon"
                        onClick={onClose}
                        className="cursor-pointer pl-2 object-contain"
                    />

                    <Typography sx={{ fontSize: '16px', fontWeight: 600, color: '#202D66', ml: 2 }}>
                        Tasks for you
                    </Typography>
                </Box>
                <Divider sx={{ color: '#F1F5F9', borderColor: '#F1F5F9', borderBottomWidth: '1px' }} />
                <Box
                    sx={{
                        my: '16px',
                    }}
                >
                    {loading ? (
                        <CircularLoader />
                    ) : (
                        <>
                            {filteredUserAssignedTasks?.length > 0 ? (
                                filteredUserAssignedTasks?.map((item) => {
                                    return (
                                        <Box key={item.id}>
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    alignItems: 'flex-start',
                                                    py: '12px',
                                                    px: '8px',
                                                    gap: '9px',
                                                }}
                                            >
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'flex-start',
                                                        gap: '9px',
                                                    }}
                                                >
                                                    {item?.completion_status === 'not_completed' ? (
                                                        <ErrorIcon
                                                            color="error"
                                                            sx={{ width: '16px', height: '16px', marginTop: '2px' }}
                                                        />
                                                    ) : item?.completion_status === 'waiting_approval' ? (
                                                        <CheckCircleIcon
                                                            color="primary"
                                                            sx={{ width: '16px', height: '16px', marginTop: '2px' }}
                                                        />
                                                    ) : (
                                                        <CheckCircleIcon
                                                            color="success"
                                                            sx={{ width: '16px', height: '16px', marginTop: '2px' }}
                                                        />
                                                    )}
                                                    <Box sx={{ display: 'flex', gap: '8px', flexDirection: 'column' }}>
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 500,
                                                                fontSize: '14px',
                                                                color: '#475569',
                                                            }}
                                                        >
                                                            <strong className="text=[#395BA9]">
                                                                {item?.assigned_by_name}
                                                            </strong>{' '}
                                                            assigned a task to you
                                                        </Typography>
                                                        {item?.comments && (
                                                            <div className="flex items-center gap-1">
                                                                <img
                                                                    src="/img/automated-scan/chat-icon.svg"
                                                                    alt="chat icon"
                                                                    className="object-contain"
                                                                />

                                                                <Typography
                                                                    sx={{
                                                                        color: '#64748B',
                                                                        fontWeight: 400,
                                                                        fontSize: '12px',
                                                                    }}
                                                                >
                                                                    {item?.comments}
                                                                </Typography>
                                                            </div>
                                                        )}
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 400,
                                                                fontSize: '12px',
                                                                color: '#64748B',
                                                                borderLeft: '2px solid #5E5E62',
                                                                backgroundColor: '#F1F5F9',
                                                                padding: '4px',
                                                                textTransform: 'capitalize',
                                                            }}
                                                        >
                                                            Infrastructure scan
                                                            {item?.automated_scans_tasks?.category && (
                                                                <> &gt; {item?.automated_scans_tasks?.category}</>
                                                            )}
                                                            {item?.automated_scans_tasks?.subcategory && (
                                                                <> &gt; {item?.automated_scans_tasks?.subcategory}</>
                                                            )}
                                                            {item?.automated_scans_tasks?.scans && (
                                                                <> &gt; {item?.automated_scans_tasks?.scans}</>
                                                            )}
                                                        </Typography>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                                                            <Typography
                                                                sx={{
                                                                    fontWeight: 500,
                                                                    fontSize: '11px',
                                                                    color: '#64748B',
                                                                }}
                                                            >
                                                                {moment(item?.date_created).fromNow()}
                                                            </Typography>
                                                            <Typography
                                                                component={'span'}
                                                                sx={{
                                                                    fontSize: '11px',
                                                                    fontWeight: 400,
                                                                    padding: '2px',
                                                                    borderRadius: '2px',
                                                                    textTransform: 'capitalize',
                                                                    color:
                                                                        item?.completion_status === 'not_completed'
                                                                            ? '#DE3730'
                                                                            : item?.completion_status === 'completed'
                                                                            ? '#005234'
                                                                            : '#002C72',
                                                                    backgroundColor:
                                                                        item?.completion_status === 'not_completed'
                                                                            ? '#DE37301A'
                                                                            : item?.completion_status === 'completed'
                                                                            ? '#0052341A'
                                                                            : '#002C721A',
                                                                }}
                                                            >
                                                                {item?.completion_status === 'waiting_approval'
                                                                    ? 'Awaiting Approval'
                                                                    : item?.completion_status?.replace(/_/g, ' ')}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                {item?.completion_status !== 'waiting_approval' && (
                                                    <Box>
                                                        <IconButton
                                                            onClick={openMenu}
                                                            sx={{
                                                                width: '24px',
                                                                height: '24px',
                                                                display: 'grid',
                                                                placeItems: 'center',
                                                                backgroundColor: 'white',
                                                                border: '1px solid #E2E8F0',
                                                                padding: '4px',
                                                                borderRadius: '4px',
                                                                boxShadow: '0px 8px 20px 0px #2563EB1A',
                                                            }}
                                                        >
                                                            <MoreVert sx={{ width: 16, height: 16 }} />
                                                        </IconButton>
                                                        <Menu
                                                            open={Boolean(anchor)}
                                                            anchorEl={anchor}
                                                            onClose={closeMenu}
                                                            PaperProps={{
                                                                sx: { backgroundColor: '#FFFFFF', boxShadow: 0 },
                                                            }}
                                                        >
                                                            <MenuItem
                                                                onClick={() => {
                                                                    updateAssignedAutomatedScan(item);
                                                                }}
                                                                sx={{ fontWeight: 500, fontSize: '13px' }}
                                                            >
                                                                Mark as completed
                                                            </MenuItem>
                                                        </Menu>
                                                    </Box>
                                                )}
                                            </Box>
                                            <Divider
                                                sx={{
                                                    color: '#F1F5F9',
                                                    borderColor: '#F1F5F9',
                                                    borderBottomWidth: '1px',
                                                }}
                                            />
                                        </Box>
                                    );
                                })
                            ) : (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        mt: 20,
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: '12px',
                                            color: '#303034',
                                            pb: 1,
                                        }}
                                    >
                                        No Task assigned
                                    </Typography>
                                </Box>
                            )}
                        </>
                    )}
                </Box>
            </Box>
        </AppViewModal>
    );
};

const mapStateToProps = (state) => {
    return {
        user_assigned_tasks: state?.automatedScansReducers?.user_assigned_tasks,
        user_id: state?.generalReducers?.user_info?.employee?.id,
    };
};
export default connect(mapStateToProps, { UserAssignedAutomatedScan, UpdateAssignAutomatedScan })(AllTasksModal);
