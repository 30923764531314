import React, { createContext, useState } from 'react';

import { useDispatch } from 'react-redux';
import { UpdateOneUserGuide } from 'store/actions/guideActions';
import { GetAllMerchantArticles } from 'store/actions/articlesActions';

export const UserGuideContext = createContext();

export const UserGuideContextProvider = ({ children }) => {
    const [toggleUserGuide, setToggleUserGuide] = useState({
        dashboard: false,
        users: false,
        pciscans: false,
        pentest: false,
        automatedscans: false,
        infrastructures: false,
        pcidss: false,
        vendors: false,
        vulnerabilityscans: false,
        riskassessment: false,
        audits: false,
        dataroom: false,
        compliance_health: false,
        codebase_scanner: false,
        fraud_guardian: false,
        codebase: false,
    });

    const dispatch = useDispatch();

    const updateOneUserGuide = async (payload) => {
        await dispatch(UpdateOneUserGuide(payload));
    };

    const getAllMerchantArticles = async () => {
        await dispatch(GetAllMerchantArticles());
    };

    const value = { updateOneUserGuide, toggleUserGuide, setToggleUserGuide, getAllMerchantArticles };
    return <UserGuideContext.Provider value={value}>{children}</UserGuideContext.Provider>;
};

export default UserGuideContextProvider;
