import React, { useState, useRef, useContext, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

//core component
import PageHeader from 'components/new_components/PageHeader';
import AppTabPanel from 'components/new_components/tabs/AppTabPanel';
import Scans from './Scans';
import Exceptions from './Exceptions';
import { Badge, Box, Button } from '@mui/material';
import AllTasks from './Components/Modals/AllTasks';

//userguide
import { Tour } from 'antd';
import AppArticle from 'components/new_components/AppArticle';
import { UserGuideContext } from 'store/context/UserGuideContextProvider';
import { UserGuideHeader } from 'components/new_components/AppUserGuide';
import TabLayoutModified from 'components/new_components/tabs/TabLayoutModified';
// import { steps } from './steps';

const AutomatedScans = (props) => {
    const location = useLocation();
    const { tab_ } = location?.state ?? {};
    const { userguide_info, user_info, all_article_header } = props;
    // state
    const [currentTab, setCurrentTab] = useState(0);
    const [openUserGuide, setOpenUserGuide] = useState(false);
    const [openArticle, setOpenArticle] = useState(false);
    const [view, changeView] = useState(tab_ !== undefined ? tab_ : 'groups');
    const [category, setCategory] = useState(tab_ !== undefined ? tab_ : {});
    const [viewTasks, setViewTasks] = useState(false);

    //userguide
    const ref1 = useRef(null);
    const ref2 = useRef(null);

    const STEPS_LENGTH = 2;

    const steps = [
        {
            title: <UserGuideHeader title="Scans" id={1} length={STEPS_LENGTH} />,
            description: 'This displays the constraints of automated scans carried out on your infrastructure.',
            target: () => ref1.current,
            placement: 'rightTop',
        },
        {
            title: <UserGuideHeader title="Exceptions" id={2} length={STEPS_LENGTH} />,
            description: 'Scan that are not related to your infrastructures(exempted scans) are shown here.',
            target: () => ref2.current,
            placement: 'rightTop',
        },
    ];
    const { updateOneUserGuide, toggleUserGuide, setToggleUserGuide } = useContext(UserGuideContext);

    //useMemo
    const oneArticle = useMemo(
        () => all_article_header?.find((article) => article?.name === 'Automated Scans'),
        [all_article_header]
    );

    //UserGuide
    const OpenUserGuide = () => {
        setOpenUserGuide(true);
    };
    const CloseUserGuide = () => {
        updateOneUserGuide({ automated_scans: true });
        setOpenUserGuide(false);
    };
    const OpenArticle = () => {
        setOpenArticle(true);
    };
    const CloseArticle = () => {
        setToggleUserGuide({ ...toggleUserGuide, automatedscans: false });
        setOpenArticle(false);
    };

    // logic function
    const handleTabChange = (tab) => {
        setCurrentTab(tab);
        changeView('groups');
    };

    useEffect(() => {
        !userguide_info ? OpenUserGuide() : toggleUserGuide.automatedscans ? OpenArticle() : null;
    }, [userguide_info, toggleUserGuide.automatedscans]);

    // constants
    const TABS = [
        { name: 'Scans', id: 0 },
        { name: 'Exemptions', id: 1 },
    ];
    const openViewTasks = () => {
        setViewTasks(true);
    };
    return (
        <Box
            sx={{
                margin: view === 'scans' ? '-1.6rem' : '',
                position: 'relative',
            }}
        >
            <PageHeader browserTitle="Automated Scans | Smartcomply" />
            {view === 'groups' && (
                <Box sx={{ position: 'relative' }}>
                    <TabLayoutModified
                        onTabChange={handleTabChange}
                        currentTab={currentTab}
                        tabs={TABS}
                        ref1={ref1}
                        ref2={ref2}
                        view={view}
                        tabAction={
                            user_info?.user_type !== 'auditor' && user_info?.user_type !== 'merchant' ? (
                                <Badge variant="dot" invisible={false} color="error">
                                    <Button
                                        sx={{
                                            border: 'none',
                                            backgroundColor: '#E1E2EC',
                                            color: '#002C72',
                                            textTransform: 'inherit',
                                            fontSize: '13px',
                                            '&:hover': {
                                                backgroundColor: '#E1E2EC',
                                            },
                                        }}
                                        onClick={openViewTasks}
                                    >
                                        Tasks for you
                                    </Button>
                                </Badge>
                            ) : null
                        }
                    >
                        <AppTabPanel value={currentTab} index={0}>
                            <Scans
                                view={view}
                                changeView={changeView}
                                category={category}
                                setCategory={setCategory}
                                type="scans"
                            />
                        </AppTabPanel>
                        <AppTabPanel value={currentTab} index={1}>
                            <Exceptions
                                view={view}
                                changeView={changeView}
                                type="exceptions"
                                category={category}
                                setCategory={setCategory}
                            />
                        </AppTabPanel>
                    </TabLayoutModified>
                </Box>
            )}
            {view === 'scans' && (
                <Scans view={view} changeView={changeView} category={category} setCategory={setCategory} />
            )}

            {view === 'exceptions' && (
                <Exceptions view={view} changeView={changeView} category={category} setCategory={setCategory} />
            )}

            <Tour open={openUserGuide} onClose={CloseUserGuide} steps={steps} targets={[ref1, ref2]} />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Automated Scans"
                headerId={oneArticle?.id}
                icon={true}
            />
            <AllTasks open={viewTasks} onClose={() => setViewTasks(false)} />
            <AppArticle
                open={openArticle}
                handleClose={CloseArticle}
                title="Automated Scans"
                headerId={oneArticle?.id}
                icon={true}
            />
        </Box>
    );
};

const mapStateToProps = (state) => {
    return {
        ...state,
        userguide_info: state?.guideReducers?.userguide_info?.[0]?.automated_scans,
        user_info: state?.generalReducers?.user_info,
        all_article_header: state?.articlesReducers?.all_article_header,
    };
};
export default connect(mapStateToProps)(AutomatedScans);
